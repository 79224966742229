export const TARGET_MODEL_TYPES = Object.freeze({
  CDISC_SDTM: 'CDISC-SDTM',
  RDS_SDTM: 'RDS SDTM',
  RULESET: 'Ruleset',
  IQVIA_CTP: 'IQVIA-CTP',
  NON_ECRF: 'NonEcrf'
});

/**
 * Checks if the given target model type is an SDTM type
 * @param {string} targetModelType
 * @returns {boolean}
 */
export const isSDTMType = (targetModelType) => {
  return [TARGET_MODEL_TYPES.CDISC_SDTM, TARGET_MODEL_TYPES.RDS_SDTM].includes(targetModelType);
};
/**
 * Checks if the given target model type is an Ruleset type
 * @param {string} targetModelType
 * @returns {boolean}
 */
export const isRulesetType = (targetModelType) => {
  return [TARGET_MODEL_TYPES.RULESET, TARGET_MODEL_TYPES.NON_ECRF].includes(targetModelType);
};
