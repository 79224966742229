/*eslint-disable*/
import { useMemo, useState } from 'react';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import Footer from 'Components/Footer';
import Loader from 'apollo-react/components/Loader';
import { makeStyles } from '@mui/styles';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import Button from 'apollo-react/components/Button';
import Variables, { _variableData } from './Variables';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CodelistEditor from '../Components/VariableRules/CodelistEditor';
import { useEffect } from 'react';
import { GetAssignedRulesetsData } from 'Redux/Service/RulesetsService';
import { useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  GetLineageDataColumns,
  GetRuleMetadataFormItems,
  GetVariableMappingRules
} from 'Redux/Service/MasteringRuleService';
import { setNextEnabled, setErrorDomains } from 'Redux/Slice/RuleEditorSlice';
import { validateDecodeMethodMDS } from 'Pages/Validator/utils';
import { getAllFormsAndItems } from 'Utils';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
import { detailsObj } from './detailsObject';
const CDR_TABULAR = 'CDR Tabular';
const PSEUDO_FORMS = 'PSeudo Forms';

const useStyles = makeStyles({
  container: {
    fontFamily: 'Proxima Nova'
  },
  select: {
    width: '400px'
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  },
  ...detailsObj
});

const NotApplicable = <span style={{ color: '#999999' }}>N/A</span>;

const DomainRules = () => {
  const [isLoading, setLoading] = useState(false);
  const [domainsList, setDomainList] = useState([]);
  const [selectedDomainData, setSelectedDomainData] = useState({});

  const [variablesList, setVariablesList] = useState([]);
  const [mappingCodelists, setMappingCodelists] = useState({});
  const [triggerCodelistAPI, setTriggerCodelistAPI] = useState(false);
  const [rowSeqMapping, setRowSeqMapping] = useState({});
  const [lineageLookUpObj, setLineageLookUpObj] = useState({});

  const { id: mappingRuleVersionID } = useParams();
  const location = useLocation();
  const domainCode = location?.state?.domainCode;

  const [displayCodelistEditor, setCodelistEditorDisplay] = useState({
    display: false,
    formItems: [],
    triggerCodelist: false
  });

  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setErrorDomains([]));
    dispatch(setNextEnabled(false));
    getCodelistsData();
  }, []);

  useEffect(() => {
    if (displayCodelistEditor.triggerCodelist) {
      getCodelistsData();
    }
  }, [displayCodelistEditor.triggerCodelist]);

  const getCodelistsData = async () => {
    const payload = {
      mappingRuleVersionID
    };
    setLoading(true);
    const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
    if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
      const mapping = {};
      const codelists = codeListsGetAPIData.data.ruleCodeLists || [];

      for (let { odmForm, odmItem, sdmCodelistLabel } of codelists) {
        const key = `${odmForm.toLowerCase()}#-#${odmItem.toLowerCase()}`;
        if (!(key in mapping)) {
          mapping[key] = [];
        }
        if (sdmCodelistLabel.trim()) mapping[key].push(sdmCodelistLabel.toLowerCase());
      }
      getAssignedRulesetsData(mapping);
      return setMappingCodelists(mapping);
    }
  };

  const getCodeListDataMapping = async (formItemInfo, rulesetVariable, mapping) => {
    let result = validateDecodeMethodMDS(
      rulesetVariable.expression,
      formItemInfo?.formName || '',
      mapping,
      formItemInfo?.itemName || ''
    );
    return result;
  };

  const getCodeListDecodeInfo = async (variablesList, mapping) => {
    if (variablesList.length) {
      let nonRulesetVariableList = variablesList.filter((variable) => !variable.isRulesetVariable);
      let rulesetErrorList = [];
      let errorDomains = [];
      let rulesetVariables = variablesList.filter((variable) => variable.isRulesetVariable);
      if (rulesetVariables.length > 0) {
        for (const rulesetVariable of rulesetVariables) {
          const result = getAllFormsAndItems(rulesetVariable.expression);
          /** For Multiple form and items in an expression :
           * Capturing all the info related to decode method (with or without sdm) and concatinating the message.
           * Storing the message info in to finalCodelistMessage
           */
          let finalCodelistMessage = '';
          const codeListInfo = await getCodeListDataMapping(result[0], rulesetVariable, mapping);
          Object.keys(codeListInfo).length &&
            result.forEach((formItemObj) => {
              if (Object.keys(codeListInfo).includes(formItemObj.itemName.toLowerCase())) {
                let codelistMessage = !codeListInfo[formItemObj.itemName.toLowerCase()].isValid
                  ? `${formItemObj.itemName} : ${
                      codeListInfo[formItemObj.itemName.toLowerCase()].message
                    }`
                  : '';
                finalCodelistMessage = finalCodelistMessage.trim().length
                  ? finalCodelistMessage + '; ' + codelistMessage
                  : codelistMessage;
              }
            });
          rulesetErrorList.push(
            finalCodelistMessage.trim().length
              ? { ...rulesetVariable, error: true, errorMessage: finalCodelistMessage }
              : rulesetVariable
          );
        }
        rulesetErrorList.length &&
        rulesetErrorList.some((ruleset) => ruleset.hasOwnProperty('error'))
          ? dispatch(setNextEnabled(false))
          : dispatch(setNextEnabled(true));
        rulesetErrorList.forEach((ruleset) => {
          ruleset.error && errorDomains.push(ruleset.domain);
        });
        dispatch(setErrorDomains([...new Set(errorDomains)]));
        setVariablesList(
          [...new Map(rulesetErrorList.map((ruleset) => [ruleset['id'], ruleset])).values()].concat(
            nonRulesetVariableList
          )
        );
      } else {
        dispatch(setNextEnabled(true));
        setVariablesList(nonRulesetVariableList);
      }
    }
  };

  const getLineageDataColumns = async () => {
    const resp = await dispatch(GetLineageDataColumns(mappingRuleVersionID)).then(unwrapResult);
    if(resp?.data?.success){
      const _lineageLookUpObj = {};
      if(resp.data.lineageDataColumns){
        Object.keys(resp.data.lineageDataColumns).forEach(domain=>{
          let variables = resp.data.lineageDataColumns[domain];
          let _groupedVariables = {}
          variables.forEach(item =>{
            if (_groupedVariables[item.domainVaraiable]) {
              _groupedVariables[item.domainVaraiable] = {
                ..._groupedVariables[item.domainVaraiable],
                [item.expression]: item.lineageColumns
              };
            } else {
              _groupedVariables[item.domainVaraiable] = {
                [item.expression]: item.lineageColumns
              };
            }
          });
          _lineageLookUpObj[domain] = _groupedVariables;
        })
        setLineageLookUpObj(_lineageLookUpObj);
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
    }
  };
  const getAssignedRulesetsData = async (mapping) => {
    setLoading(true);
    const resp = await dispatch(GetAssignedRulesetsData(mappingRuleVersionID)).then(unwrapResult);
    if (resp?.data?.success) {
      const _domainsList = resp.data.cdrLibrariesDomainData?.map((domainData) => {
        const {
          mappingRuleStudyLibXrefId,
          source,
          libraryType,
          clinicalDataType,
          clinicalDataFlow,
          ruleset: { libraryName: rulesetName, libraryVersion: rulesetVersion }
        } = domainData;
        return {
          domain: source,
          libraryType,
          clinicalDataType,
          clinicalDataFlow,
          ruleset: rulesetName,
          rulesetVersion,
          rowId: mappingRuleStudyLibXrefId
        };
      });

      setDomainList(_domainsList);
      await getLineageDataColumns();
      const success = await getVariableMappingRules(
        _domainsList.map((domains) => domains.domain),
        mapping
      );
      if (success) {
        let _selectedDomainData;
        if (domainCode) {
          _selectedDomainData = _domainsList.find(
            (domainData) => domainData.domain === selectedDomainData?.domain
          );
        }
        setSelectedDomainData(_selectedDomainData || _domainsList?.[0] || {});
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
    }
    setLoading(false);
  };

  const getVariableMappingRules = async (domain, mapping) => {
    setLoading(true);
    let payload = {
      mappingRuleVersionID,
      mappedDomains: typeof domain === 'string' ? domain.split() : domain
    };

    const mappingRulesResponse = await dispatch(GetVariableMappingRules(payload)).then(
      unwrapResult
    );

    if (mappingRulesResponse.data.success) {
      const _rowSeqMapping = {};
      let mappingRules = mappingRulesResponse.data.itemMappingRules;
      let _variablesList = mappingRules.map((mappingRule = {}) => ({
        id: mappingRule.variableRuleId,
        variable: mappingRule.variableName,
        expression: mappingRule.expression,
        isRulesetVariable: mappingRule.isRulesetVariable,
        domain: mappingRule.domainCode,
        isSuppqual: mappingRule.isSuppqual
      }));

      mappingRules.forEach(
        (mappingRule) => (_rowSeqMapping[mappingRule.domainCode] = mappingRule.rowSeq)
      );

      setRowSeqMapping(_rowSeqMapping);

      getCodeListDecodeInfo(_variablesList, mapping);
      setLoading(false);
      return true;
    } else {
      dispatch(showBanner({ variant: 'error', message: mappingRulesResponse?.data?.message }));
      setLoading(false);
      return false;
    }
  };

  const handleDomainChange = async (e) => {
    setSelectedDomainData(e.target.value);
    navigate('.', {
      state: {
        ...location.state,
        domainCode: e.target.value?.domain
      }
    });
  };

  const handleDomainPreviewToggle = () => {
    const domainPreviewPath =
      currentPath === 'mds-domain-rules'
        ? `/product-designer/rule-editor/${mappingRuleVersionID}/mds-domain-preview`
        : `/product-designer/rule-editor/${mappingRuleVersionID}/non-ecrf-domain-preview`;
    navigate(domainPreviewPath, {
      state: {
        domainCode: selectedDomainData?.domain
      }
    });
  };

  const onExpressionInputHandler = (exp, value) => {
    const modifiedExps = [...variablesList];
    modifiedExps.forEach((expression) => {
      if (expression.id === exp.id) {
        expression.expression = value;
      }
    });
    setVariablesList(modifiedExps);
  };

  const addExpressionHandler = (newExpression) => {
    const newVariablesList = [...variablesList, { ...newExpression }];
    setVariablesList(newVariablesList);
  };

  const deleteExpressionHandler = (exp) => {
    const modifiedExps = [...variablesList];
    const expIndex = modifiedExps.findIndex((expression) => expression.id === exp.id);
    modifiedExps.splice(expIndex, 1);
    setVariablesList(modifiedExps);
  };

  return (
    <>
      {isLoading ? <Loader isInner overlayClassName={classes.loader}></Loader> : null}
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        minHeight={'calc(100vh - 171px)'}
        width={'100%'}
        className={classes.container}>
        <Box px={3}>
          <Typography variant="h3">{'Domain Rules'}</Typography>
          <Select
            className={classes.select}
            onChange={handleDomainChange}
            label={'Select a Domain'}
            placeholder={'Select Domain'}
            style={{ marginRight: '10px' }}
            value={selectedDomainData}
            canDeselect={false}>
            {domainsList.map((domainData) => (
              <MenuItem value={domainData} key={domainData?.rowId}>
                {domainData?.domain}
              </MenuItem>
            ))}
          </Select>
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'row'}>
              <Box className={classes.details}>
                <Typography variant="body2" className={classes.detailsLabel}>
                  Ruleset
                </Typography>
                <Typography variant="title" className={classes.detailsContent}>
                  {selectedDomainData?.ruleset || NotApplicable}
                </Typography>
              </Box>
              <Box className={classes.details}>
                <Typography variant="body2" className={classes.detailsLabel}>
                  Ruleset Version
                </Typography>
                <Typography variant="title" className={classes.detailsContent}>
                  {selectedDomainData?.rulesetVersion || NotApplicable}
                </Typography>
              </Box>
              <Box className={classes.details}>
                <Typography variant="body2" className={classes.detailsLabel}>
                  Clinical Data Type
                </Typography>
                <Typography variant="title" className={classes.detailsContent}>
                  {selectedDomainData?.clinicalDataType}
                </Typography>
              </Box>
              <Box className={classes.details}>
                <Typography variant="body2" className={classes.detailsLabel}>
                  Clinical Data Flow
                </Typography>
                <Typography variant="title" className={classes.detailsContent}>
                  {selectedDomainData?.clinicalDataFlow}
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Button
                variant={'secondary'}
                size={'small'}
                onClick={handleDomainPreviewToggle}
                data-testid="preview">
                Preview
              </Button>
            </Box>
          </Box>
          <Variables
            title={selectedDomainData?.domain}
            variablesList={variablesList}
            setVariablesList={setVariablesList}
            lineageLookUpObj={lineageLookUpObj}
            setCodelistEditorDisplay={setCodelistEditorDisplay}
            addExpressionHandler={addExpressionHandler}
            deleteExpressionHandler={deleteExpressionHandler}
            onExpressionInputHandler={onExpressionInputHandler}
            mappingCodelists={mappingCodelists}
            rowSeqMapping={rowSeqMapping}
            mappingRuleVersionID={mappingRuleVersionID}
            {...displayCodelistEditor}
          />
        </Box>
        <Footer width={'100%'} />
      </Box>
      {displayCodelistEditor?.display ? (
        <CodelistEditor
          setCodelistEditorDisplay={setCodelistEditorDisplay}
          mappingRuleVersionID={mappingRuleVersionID}
          {...displayCodelistEditor}
          setTriggerCodelistAPI={setTriggerCodelistAPI}
        />
      ) : null}
    </>
  );
};

export default DomainRules;
