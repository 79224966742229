import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Switch from 'apollo-react/components/Switch';
import { nonEcrfStyle } from '../NonECRSFStyle';

function DetailsStrip({ dpConfig, configureDPToggleHandler, dataProductName }) {
  const useStyles = makeStyles(nonEcrfStyle.nonEcrfForm);
  const classes = useStyles();

  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const { SDTMDataProduct: { productMnemonic = '' } = {}, sdhDataProductConfig = {} } =
    sdhDataProduct || {};

  return (
    <Box mt={2}>
      <Grid container spacing={0} mt={1}>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.label}>
            Configure Data Product
          </Typography>
          <Typography variant="title" className={classes.labelDesc}>
            <Switch
              disabled={sdhDataProductConfig?.[dataProductName]}
              checked={dpConfig}
              onChange={configureDPToggleHandler}
            />
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" className={classes.label}>
            Product Mnemonic
          </Typography>
          <Typography variant="title" className={classes.labelDesc}>
            {productMnemonic}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DetailsStrip;
