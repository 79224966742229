/*eslint-disable */
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import Pencil from 'apollo-react-icons/Pencil';
import { Trash } from 'apollo-react-icons';
import Box from '@mui/material/Box';
import Switch from 'apollo-react/components/Switch';
import TextField from 'apollo-react/components/TextField';
import Divider from 'apollo-react/components/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { Accordion, AccordionDetails } from 'apollo-react';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import CustomModal from 'Components/Modal/Modal';
import PreSQLEditor from './PreSQLNewDataset/PreSQLEditor';
import { useDispatch, useSelector } from 'react-redux';
import { getStudyFormObject } from 'Redux/Slice/AddStudyLibrarySlice';
import { useLocation, useNavigate } from 'react-router-dom';
import useDisplayName from 'Utils/useDisplayName';
import EyeShow from 'apollo-react-icons/EyeShow';
// import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  switch: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px'
  },
  accordion: {
    width: '100%',
    borderRadius: '8px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    color: '#444444'
  },
  queryContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
    marginRight: '-24px',
    color: '#444444'
  },
  accordianSummary: {
    height: '77px',
    display: 'flex',
    border: '1px solid #D9D9D9',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '0px 0px 0px 32px',
    '&> .MuiAccordionSummary-expandIconWrapper': {
      top: '30px',
      width: '20px'
    }
  },
  accordionDetails: {
    border: '1px solid #D9D9D9',
    padding: '0px',
    borderRadius: '0px 0px 8px 8px'
  },
  filterTypeEntryFields: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '97%'
  },
  filterTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modal: {
    width: 520
  },
  textFieldContainer: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400'
  },
  textFieldContainer1: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600'
  },
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  }
});

const SqlLibraryDatasets = (props) => {
  const {
    datasetName,
    source,
    datasetDescription,
    queryID,
    isQueryActive,
    trialName,
    queryString,
    studyID,
    dataBaseName,
    isAzureEnv,
    isSavedInDB,
    isReadonly,
    qCodeSourceJsonData,
    queryErrors,
    queryColumns,
    dataSetLineage
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const editPage = location.pathname === '/data-standard-library/edit-study-library';
  const { studyObj } = useSelector((state) => state.AddStudyLibrary);
  const { name, type, dataSet, editInProgress, description } = studyObj;
  const classes = useStyles();
  const [isQueryDelete, setIsQueryDelete] = useState(false);
  const [isActive, setIsActive] = useState(isQueryActive);
  const getDisplayName = useDisplayName();

  const handleDeleteModal = () => {
    setIsQueryDelete(true);
  };

  const onDelete = (queryID) => {
    const filteredData = dataSet.filter((item) => {
      return item.queryID !== queryID;
    });
    dispatch(
      getStudyFormObject({
        name: name,
        type: type,
        dataSet: filteredData,
        description: description,
        editInProgress: editInProgress
      })
    );
    setIsQueryDelete(false);
  };

  const handleToggleChange = () => {
    let modifiedData = [];
    dataSet.forEach((item) => {
      if (item.queryID === queryID) {
        modifiedData.push({ ...item, isQueryActive: !isActive });
      } else modifiedData.push(item);
    });
    dispatch(
      getStudyFormObject({
        name: name,
        type: type,
        dataSet: modifiedData,
        description: description,
        editInProgress: editInProgress
      })
    );
  };

  const navigateToDatasetEditor = (queryID) => {
    navigate(
      editPage
        ? '/data-standard-library/edit-study-library/add-new-dataset'
        : '/data-standard-library/add-study-library/add-new-dataset',
      {
        state: {
          queryID,
          datasetName,
          datasetDescription,
          source,
          isQueryActive,
          trialName,
          queryString,
          studyID,
          dataBaseName,
          isAzureEnv,
          isSavedInDB,
          isReadonly,
          qCodeSourceJsonData,
          queryErrors,
          queryColumns,
          dataSetLineage
        }
      }
    );
  };

  return (
    <>
      <Accordion className={classes.accordion}>
        <AccordionSummary className={classes.accordianSummary} expandIcon={<ExpandMoreIcon />}>
          <div className={classes.filterTypeHeader}>
            <div className={classes.filterTypeEntryFields}>
              <div className={classes.queryContainer} onClick={(e) => e.stopPropagation()}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'240px'}
                  style={{ marginRight: '16px', position: 'relative' }}>
                  <span>
                    <Typography className={classes.textFieldContainer}>Dataset Name</Typography>
                    <Typography className={classes.textFieldContainer1}>{datasetName}</Typography>
                  </span>
                </Box>
                <Box style={{ marginRight: '16px', position: 'relative' }}>
                  <span>
                    <Typography className={classes.textFieldContainer}>Data Source</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className={classes.textFieldContainer1}>
                        {getDisplayName(source)}
                      </Typography>
                    </div>
                  </span>
                </Box>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <div className={`tooltip`} style={{ margin: '6px 15px 0 8px', cursor: 'pointer' }}>
                {isReadonly ? (
                  <EyeShow
                    data-testid="preview-btn"
                    onClick={() => navigateToDatasetEditor(queryID)}
                  />
                ) : (
                  <Pencil data-testid="edit-btn" onClick={() => navigateToDatasetEditor(queryID)} />
                )}
                <span className="tooltiptext4 tooltip-bottom4">
                  {isReadonly ? 'Preveiw' : 'Edit'}
                </span>
              </div>
              {!isReadonly && (
                <>
                  <div
                    className={`tooltip`}
                    style={{ margin: '6px 22px 0 8px', cursor: 'pointer' }}>
                    <Trash data-testid="delete-btn" onClick={handleDeleteModal} />
                    <span className="tooltiptext4 tooltip-bottom4">{'Delete'}</span>
                  </div>
                  <span>Active</span>
                  <Switch
                    size="small"
                    data-testid="switch-btn"
                    className={classes.switch}
                    checked={isActive}
                    onChange={() => {
                      setIsActive(!isActive);
                      handleToggleChange();
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <PreSQLEditor sqlQuery={queryString} readOnly />
          {datasetDescription?.length > 0 ? (
            <>
              <Divider />
              <TextField
                label="Description"
                name="datasetDescription"
                multiline
                minRows={1}
                InputProps={{
                  readOnly: true,
                  inputProps: {
                    style: {
                      padding: '0px',
                      border: 'none',
                      fontSize: '14px'
                    }
                  }
                }}
                value={datasetDescription}
                style={{
                  padding: '0px 15px',
                  margin: '8px 0px',
                  width: '100%'
                }}
              />
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
      <Box
        flexDirection={'column'}
        style={{
          width: '50%'
        }}>
        <Box
          style={{
            borderRight: '1px solid lightgrey',
            height: '32px',
            marginLeft: '10px'
          }}></Box>
      </Box>

      {isQueryDelete && (
        <CustomModal
          width={classes.modal}
          variant="error"
          display={isQueryDelete}
          onClose={() => {
            setIsQueryDelete(false);
          }}
          //   data={selectedData}
          title={
            <>
              <Typography style={{ color: '#E20000', fontSize: '20px', fontWeight: '600' }}>
                {'Delete Dataset'}
              </Typography>
              {
                <Typography
                  style={{
                    fontSize: '13px',
                    fontWeight: '600'
                  }}>{`Dataset Name : ${datasetName}`}</Typography>
              }
              {
                <Typography
                  style={{
                    fontSize: '13px',
                    fontWeight: '600'
                  }}>{`Data Source : ${getDisplayName(source)}`}</Typography>
              }
            </>
          }
          message={
            <>
              <Typography>You are about to delete the dataset.</Typography>
            </>
          }
          buttonProps={[
            {
              label: 'Cancel',
              onClick: () => {
                setIsQueryDelete(false);
              }
            },
            {
              label: 'Delete Query',
              onClick: () => onDelete(queryID)
            }
          ]}
        />
      )}
    </>
  );
};

export default SqlLibraryDatasets;
