import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

export const GetAssignedStudyLibraries = createAsyncThunk(
  '/dataProduct/GetAssignedStudyLibraries',
  async () => {
    return await axios.get(`${API_URL}/api/DataProduct/GetAssignedStudyLibraries?userId=${Cookies.get('user.id')}`);
  }
);
export const SavePinnedStudies = createAsyncThunk(
  '/dataProduct/SavePinnedStudies',
  async (payload) => {
    return await axios.post(`${API_URL}/api/DataProduct/SavePinnedStudies`, payload);
  }
);

const mockResponse = {
    functionData: [
        {
            functionId: "e8eb4258-0638-4f58-a0a4-84d17c0a2355",
            moduleName: "non-ecrf-data-product",
            displayName: "Non-eCRF Data Product",
            order: 1,
            isRequired: true,
            leaf: false
        },
        {
            functionId: "6428caaf-8bae-4db6-90c8-ba41287fc718",
            moduleName: "preview-and-create-sdh-dpd",
            displayName: "Preview and Create",
            order: 2,
            isRequired: true,
            leaf: true
        },
    ],
    libraryType: "CDISC-NonSDTM",
    success: true,
    message: "Success",
    errorCode: 0
}

export const GetDataProductWorkflowModel = createAsyncThunk('DataProduct/GetDataProductWorkflowModel', async () => {
  return new Promise((resolve) => { 
    setTimeout(() => { 
      resolve({data: mockResponse});
      }, 1000); 
  });
});

export const GetNonECRFDatasets = createAsyncThunk('DataProduct/GetNonECRFDatasets', async (params) => {
  return await axios.get(`${API_URL}/api/DataProduct/GetNonECRFDatasets`, {
    params
  });
});

export const RunNonEcrfDatasets = createAsyncThunk('DataProduct/RunNonEcrfDatasets', async (payload) => {
  return await axios.post(`${API_URL}/api/DataProduct/RunNonEcrfDatasets`, payload);
});

export const GetReservedLibraryInfo = createAsyncThunk('DataProduct/GetReservedLibraryInfo', async () => {
  return await axios.get(`${API_URL}/api/DataProduct/GetReservedLibraryInfo`);
});

export const SaveNonEcrfDataproduct = createAsyncThunk('DataProduct/SaveNonEcrfDataproduct', async (payload) => {
  return await axios.post(`${API_URL}/api/DataProduct/SaveNonEcrfDataproduct`, payload);
});

export const CreateSDTMDataProduct = createAsyncThunk('DataProduct/CreateSDTMDataProduct', async (payload) => {
  return await axios.post(`${API_URL}/api/DataProduct/CreateSDTMDataProduct`, payload);
});


