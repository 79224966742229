import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useStylesPreviewCreate } from '../SDHPreviewCreate';

function PreviewSDTM() {
  const classes = useStylesPreviewCreate();
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const { SDTMDataProduct = {} } = sdhDataProduct || {};
  const { productMnemonic = '', targetList = [], description = '' } = SDTMDataProduct;

  return (
    <Accordion defaultExpanded className={classes.libraryType}>
      <AccordionSummary>
        <Box className={classes.libraryTypeHeader}>
          <Typography className={`${classes.libraryTypeTitle}`}>SDTM Data Product</Typography>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>{}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.expressionContainer}>
        <Grid container spacing={0} mt={1} gap={2}>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              Product Mnemonic
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {productMnemonic}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              Target library
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {targetList?.[0]?.libraryName}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              Description
            </Typography>
            <Typography variant="title" className={classes.labelDesc}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PreviewSDTM;
