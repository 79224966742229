/*eslint-disable*/
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import ApolloCard from 'apollo-react/components/Card';
import IconButton from 'apollo-react/components/IconButton';
import Tooltip from 'apollo-react/components/Tooltip';
import Button from 'apollo-react/components/Button';
import SplitButton from 'apollo-react/components/SplitButton';
import React from 'react';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { isRulesetType } from 'Constants/TargetModelTypes';

const useStyles = makeStyles({
  splitButton: {
    boxShadow: 'none',
    '& .css-rk5m0a-MuiButtonBase-root-MuiButton-root': {
      border: '#ffffff'
    }
  },
  wrapper: {
    maxWidth: '325px',
    height: '310px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative'
  },
  title: {
    fontWeight: 500,
    fontSize: '13px',
    marginLeft: '12px',
    marginTop: '25px',
    color: '#0768FD',
    textDecoration: 'underline',
    cursor: 'pointer',
    textDecorationStyle: 'dotted',
    textUnderlinePosition: 'under'
  },
  titleDisabled: {
    fontWeight: 500,
    fontSize: '13px',
    marginLeft: '12px',
    marginTop: '25px',
    color: '#0768FD',
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textUnderlinePosition: 'under',
    cursor: 'default',
    pointerEvents: 'none',
    opacity: 0.5
  },
  footer: {
    color: '#0768FD',
    marginLeft: '12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    color: '#bad4fd'
  }
});

const CARDTITLE = {
  TRIAL_VISITS: 'Trial Visits',
  TIME_POINTS: 'Time Points',
  CODE_LIST: 'Code Lists',
  CTP_SVT: 'CTP SVT'
};

const Card = ({
  children,
  handleDownload,
  isEditDisable,
  handleEditPreview,
  setErrorFlag,
  refMsg,
  cardName,
  cardTitle,
  isCtmsConfigured = true,
  isCtmsmetadataSuccess,
  required,
  handleImportStudyLibrary,
  isEdcInfoReady,
  deleteCardInParent,
  disableImportText,
  targetDataModelType,
  handleTimePointsSheetDownload,
  handleImportData,
  isImportRunning = false,
  isImportRequired = false,
  previewDataLabel = 'View/Edit Data Table',
  isDownloadRequired = true
}) => {
  const classes = useStyles();
  const { getMessage } = useGlobalMessage();
  const deleteCard = async () => {
    deleteCardInParent();
  };
  const { selectedRowsForSave } = useSelector((state) => state.RuleEditorData);
  const options = [
    {
      label: 'Download Excel Template',
      onClick: handleDownload
    },
    {
      label: 'Download Source Events Sheet',
      onClick: handleTimePointsSheetDownload
    }
  ];

  return (
    <ApolloCard className={classes.wrapper}>
      {refMsg && (
        <Tooltip title={'Issues detected'} subtitle={refMsg}>
          <StatusExclamation
            style={{
              color: 'red',
              position: 'absolute',
              top: '15px',
              right: '16px'
            }}
          />
        </Tooltip>
      )}
      {!required && (
        <IconButton
          onClick={deleteCard}
          style={{ position: 'absolute', top: '10px', right: '0px' }}
          data-testid="deletecard-btn">
          <Trash />
        </IconButton>
      )}
      {(cardTitle === CARDTITLE.CTP_SVT || cardTitle === CARDTITLE.TIME_POINTS) &&
      !selectedRowsForSave.every((item) => item.libraryType === LIBRARY_TYPES.CDR_TABULAR) ? (
        <div style={{ marginLeft: '4px', marginTop: '15px' }}>
          <SplitButton
            className={classes.splitButton}
            label="Download Excel Template"
            onClick={handleDownload}
            variant="secondary"
            size="small"
            menuItems={options}
          />
        </div>
      ) : (
        <span
          className={isDownloadRequired ? classes.title : classes.titleDisabled}
          onClick={handleDownload}>
          Download Excel Template
        </span>
      )}
      {children}

      <a
        id="anchor-preview"
        className={`${classes.footer} ${isEditDisable ? classes.disabled : ''}`}
        onClick={handleEditPreview}>
        {!isEditDisable && setErrorFlag ? (
          <Tooltip title={'Issues detected'} subtitle={'Fix data table issues to continue'}>
            <span className={classes.title}>
              <StatusExclamation
                style={{
                  float: 'left',
                  height: '18px',
                  marginTop: '2px',
                  color: 'red'
                }}
              />
              Fix data tables issues
            </span>
          </Tooltip>
        ) : !isEditDisable &&
          !setErrorFlag &&
          (isEdcInfoReady !== undefined ? isEdcInfoReady : true) &&
          !isCtmsmetadataSuccess &&
          !isImportRunning ? (
          `${previewDataLabel}`
        ) : (
          ''
        )}
      </a>
      {isImportRequired && isEditDisable && (
        <Button
          style={{ position: 'absolute', bottom: '4px', left: '0px' }}
          variant="text"
          id="anchor-preview"
          className={`${classes.footer}`}
          onClick={() => handleImportData(false)}
          disabled={isImportRunning}>
          <span style={{ justifyContent: 'flex-start', color: '#0768FD' }}>
            {getMessage(MessageConstants.REFERENCE_DATA_STUDY_LIBRARY_IMPORT_LABEL)}
          </span>
        </Button>
      )}

      {!isRulesetType(targetDataModelType) && isEditDisable && cardName === CARDTITLE.CODE_LIST && (
        <Button
          style={{ position: 'absolute', bottom: '4px', left: '0px' }}
          variant="text"
          id="anchor-preview"
          className={`${classes.footer}`}
          onClick={() => handleImportStudyLibrary('import', true)}
          disabled={disableImportText}>
          <span style={{ justifyContent: 'flex-start', color: '#0768FD' }}>
            Import from Study Library
          </span>
        </Button>
      )}
    </ApolloCard>
  );
};

export default Card;
