import { isRulesetType } from 'Constants/TargetModelTypes';

export const handlePayload = (
  selectedDomainSourceData,
  selectedSubjects,
  domainCode,
  productMnemonic,
  userLoginID,
  selectedSourceData,
  targetDataModelType,
  domainFlag
) => {
  const filteredSourceData = selectedDomainSourceData.masterDataSources
    .filter((item) => item.connection === true && item.selected)
    .map((row) => {
      if (row.parentSourceName === 'CDR') {
        const CDR = selectedDomainSourceData.masterDataSources.filter(
          (item) => item.sourceName === 'CDR'
        )[0];

        return {
          dataSourceID: CDR.dataSourceID,
          databaseName: CDR.databaseName,
          sourceID: CDR.sourceID,
          sourceName: CDR.sourceName,
          userID: CDR.userID,
          password: CDR.passwordValue.slice(1)
        };
      } else {
        return {
          dataSourceID: row.dataSourceID,
          databaseName: row.databaseName,
          sourceID: row.sourceID,
          sourceName: row.sourceName,
          userID: row.userID,
          password: row.passwordValue.slice(1)
        };
      }
    });
  const set = new Set();
  const previewSelectedDatasources = filteredSourceData.filter((el) => {
    const duplicate = set.has(el.sourceName);
    set.add(el.sourceName);
    return !duplicate;
  });

  const selectedCDRDataSets = selectedDomainSourceData.masterDataSources
    ?.filter((item) =>
      domainFlag
        ? item.parentSourceName === 'CDR' && item.connection === true
        : item.parentSourceName === 'CDR' && item.connection === true && item.selected
    )
    .map((row) => {
      return row.sourceName;
    });
  let selectedSubjectIds = {};
  selectedSubjects?.forEach((subject) => {
    subject?.source?.split(',').forEach((_source) => {
      if (_source in selectedSubjectIds) {
        selectedSubjectIds[_source].push(subject?.subjectid);
      } else {
        selectedSubjectIds[_source] = [subject?.subjectid];
      }
    });
  });
  let trialNames = {};
  let ecrfTrialName = '';

  selectedSourceData?.map((item) => {
    trialNames[item.sourceName] = item.trialName;
    ecrfTrialName = ecrfTrialName || item.ecrfTrialName;
  });

  return {
    previewSelectedDatasources: previewSelectedDatasources,
    selectedCDRDataSets: isRulesetType(targetDataModelType)
      ? domainCode.split()
      : selectedCDRDataSets.length
      ? selectedCDRDataSets
      : [''],
    mappingRuleVersionID: selectedDomainSourceData.mappingRuleVersionID,
    selectedSubjectIds: selectedSubjectIds,
    domainToProcess: domainCode,
    protocolNumber: selectedDomainSourceData.studyDataSources[0].protocolNumber,
    productName: productMnemonic ? productMnemonic : '',
    userId: userLoginID ? userLoginID : '',
    trialNames: isRulesetType(targetDataModelType) ? {} : trialNames ? trialNames : null,
    ecrfTrialName,
    isDataSourceDummy: selectedDomainSourceData.isDataSourceDummy
  };
};
