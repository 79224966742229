import { useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from 'apollo-react/components/Typography';
import Search from 'apollo-react/components/Search';
import { neutral1 } from 'apollo-react/colors';
import VariableRow from './VariableRow';
import { useEffect } from 'react';
import { EmptyTablePlaceholder } from 'Components/Common/EmptyTablePlaceholder';
import Button from 'apollo-react/components/Button';
import PreviewVariableSelection from './PreviewVariableSelection';
import Modal from 'apollo-react/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from 'apollo-react/components/CircularProgress';
import { LoaderOverlay } from '../Components/LoaderOverlay';
import ERROR from 'Pages/Validator/messages';
import validate from 'Pages/Validator/validate';
import { getSourceName } from 'Utils';
import {
  GetRuleMetadataFormItems,
  GetVariableMappingRules,
  SaveVariableMappingRules
} from 'Redux/Service/MasteringRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import { CDR_TABULAR } from 'Pages/DataStandardLibrary/DataStandardLibraryMain/constants';
import { GetProductDataSourcesByMappingRuleVersionID } from 'Redux/Service/RuleEditorService';
import { referenceDataService } from 'Services/ReferenceData.Service';
import Cookies from 'js-cookie';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { TARGET_MODEL_TYPES } from 'Constants/TargetModelTypes';
const useStyles = makeStyles({
  container: {
    boxShadow: '0px 0px 3px #0002',
    marginTop: '1rem'
  },
  rowsContainer: {},
  columnsHeader: {
    '&>p': {
      padding: '0 1rem',
      verticalAlign: 'middle',
      color: '#595959',
      fontSize: '14px',
      backgroundColor: neutral1
    }
  },
  stickyHeader: {
    position: 'sticky',
    top: '-1px',
    backgroundColor: neutral1,
    zIndex: '2'
  },
  header: {
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '4px 4px 0 0'
  },
  headerTitle: {
    fontWeight: '600'
  },
  headerSubTitle: {
    fontSize: '14px',
    color: '#444'
  }
});

const Variables = (props) => {
  const {
    title: selectedDomain,
    variablesList,
    setVariablesList,
    lineageLookUpObj,
    setCodelistEditorDisplay,
    addExpressionHandler,
    deleteExpressionHandler,
    onExpressionInputHandler,
    mappingCodelists,
    rowSeqMapping,
    mappingRuleVersionID
  } = props;

  const [searchText, setSearchText] = useState('');
  const [filteredVariablesList, setFilteredVariablesList] = useState(variablesList);
  const [domainVariablesCount, setDomainVariablesCount] = useState([]);
  const [isEditEnable, setIsEditEnable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [expressionValidationErrors, setExpressionValidationErrors] = useState({});
  const [formMetaData, setFormMetaData] = useState({});
  const [serviceResult, setServiceResult] = useState({});
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);

  const [caretPosition, setCaretPosition] = useState({
    position: 0,
    id: '',
    rulesetIndex: -1,
    inputIndex: -1
  });

  const userId = Cookies.get('user.id');
  const studyLibraryData = useSelector((state) => state.StudyLibraryData);
  const { protocol } = studyLibraryData;
  const dispatch = useDispatch();

  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const isDomainRulesEditable = workFlowModel?.libraryType === TARGET_MODEL_TYPES.NON_ECRF;

  const classes = useStyles();

  const handleSearch = (e) => {
    const _searchValue = e.target.value.toLowerCase();
    setSearchText(e.target.value);
    setFilteredVariablesList([
      ...variablesList
        .filter((variable) => variable.domain === selectedDomain)
        .filter((row) =>
          _searchValue ? row?.variable?.toLowerCase().includes(_searchValue) : true
        )
    ]);
  };

  useEffect(() => {
    setSearchText('');
    setFilteredVariablesList(
      selectedDomain
        ? variablesList.filter((variable) => variable.domain === selectedDomain)
        : variablesList
    );

    const groupedSelectedDomainObj = selectedDomain
      ? variablesList.reduce((acc, item) => {
          if (item.domain === selectedDomain) {
            acc[item.variable] = 1;
          }
          return acc;
        }, {})
      : {};

    setDomainVariablesCount(Object.keys(groupedSelectedDomainObj)?.length);
  }, [selectedDomain, variablesList]);

  const getRuleMetadataFormItems = async () => {
    try {
      const payload = {
        mappingRuleVersionID,
        forms: [2]
      };
      const { data: { formItemsDict } = { formItemsDict: {} } } = await dispatch(
        GetRuleMetadataFormItems(payload)
      ).then(unwrapResult);

      const ruleMetaDataFormItems = {};

      if (formItemsDict[CDR_TABULAR]) {
        ruleMetaDataFormItems[CDR_TABULAR] = [];
        formItemsDict[CDR_TABULAR].forEach((_formItem) =>
          ruleMetaDataFormItems[CDR_TABULAR].push(_formItem)
        );
      }
      setFormMetaData(ruleMetaDataFormItems);
    } catch (error) {
      console.log('Failed to retrive FormItems MetaData :: ', error);
    }
  };

  useEffect(() => {
    getRuleMetadataFormItems();
  }, [mappingRuleVersionID]);

  const [subTitle, groupedVariableExpressions] = useMemo(() => {
    let _groupedVariableExpressions = {};
    filteredVariablesList?.forEach((vairableExp) => {
      if (_groupedVariableExpressions[vairableExp.variable]) {
        _groupedVariableExpressions[vairableExp.variable].expressions.push(vairableExp);
      } else {
        _groupedVariableExpressions[vairableExp.variable] = {
          variable: vairableExp.variable,
          expressions: [vairableExp],
          isRulesetVariable: vairableExp.isRulesetVariable
        };
      }
    });

    let _subTitle;
    if (domainVariablesCount > filteredVariablesList?.length) {
      _subTitle = `${filteredVariablesList?.length} out of ${domainVariablesCount} Variables`;
    } else {
      _subTitle = `${domainVariablesCount} Variables`;
    }

    return [_subTitle, Object.values(_groupedVariableExpressions)];
  }, [domainVariablesCount, filteredVariablesList]);

  const [sourceFormItems, formItemMapping] = useMemo(() => {
    const formatedFormItemData = [];
    const sourceFormItems = {};
    for (let libraryType in formMetaData) {
      for (let formData of formMetaData[libraryType]) {
        formatedFormItemData.push({
          formName: formData.formName,
          itemName: formData.itemName,
          libraryType,
          formatedString: `[${formData.formName}].[${formData.itemName}]`
        });
        if (sourceFormItems[formData.formName])
          sourceFormItems[formData.formName].items.push(formData.itemName);
        else
          sourceFormItems[formData.formName] = {
            items: [formData.itemName],
            sourceName: getSourceName(libraryType)
          };
      }
    }

    let arr = [];
    let mapping = {};
    const keys = Object.keys(sourceFormItems);
    keys.forEach((key) => {
      mapping[key] = sourceFormItems[key].items || [];
      arr.push(...sourceFormItems[key].items);
    });

    return [sourceFormItems, mapping];
  }, [formMetaData]);

  const getVariableStructure = ({ variableName, expression, isSuppQual }) => {
    const date = new Date().toISOString();
    const filterName = selectedDomain;
    const defaultForm = selectedDomain;
    const defaultSourceName = 'TABULAR';
    const rowSeq = rowSeqMapping[selectedDomain];

    return {
      iqCreateDate: date,
      iqUpdateDate: date,
      iqAuditDate: date,
      iqCreatedBy: userId,
      iqUpdatedBy: userId,
      iqAuditType: 'INSERT',
      iqActiveFlag: true,
      protocolNumber: protocol.protocolNumber,
      mappingRuleVersionId: mappingRuleVersionID,
      variableName,
      rowName: filterName,
      domainCode: selectedDomain,
      ruleSeq: 0,
      expression,
      defaultForm: defaultForm,
      sourceName: defaultSourceName,
      sourceSYSID: 0,
      concatenate: 'N',
      rowSeq,
      isSuppqual: isSuppQual ?? 'N',
      isMapRowActive: true
    };
  };

  const getProductDataSources = async () => {
    const selectedDataSourceData = await dispatch(
      GetProductDataSourcesByMappingRuleVersionID(mappingRuleVersionID)
    ).then(unwrapResult);
    if (selectedDataSourceData?.data?.success) {
      let result = await referenceDataService(selectedDataSourceData.data.ruleStudyLibrary);
      setServiceResult(result);
    }
  };

  useEffect(() => {
    getProductDataSources();
  }, []);

  const handleLeaveModal = () => {
    const unsavedExpression = variablesList.find((item) => item.isSaved === false);
    if (unsavedExpression) {
      setShowLeavePageModal((prev) => !prev);
    } else {
      setShowLeavePageModal(false);
      setIsEditEnable(true);
    }
  };

  const handleReturnButton = () => {
    setShowLeavePageModal(false);
    const removeUnSavedVariables = variablesList.filter((item) => item.isSaved !== false);
    setVariablesList(removeUnSavedVariables);
    setIsEditEnable(true);
  };

  const validateRuleExpression = (inputExp) => {
    let result = {
      isValid: true,
      message: '',
      cordinates: { start: 0, end: inputExp.length }
    };

    result = validate(inputExp, selectedDomain, mappingCodelists, formItemMapping, serviceResult);
    return result;
  };

  const saveHandler = async () => {
    setExpressionValidationErrors({});
    setIsLoading(true);
    const errors = {};
    try {
      let payload = { itemMappingRules: [] };
      groupedVariableExpressions.forEach((vairableExp, groupVariableIndex) => {
        vairableExp?.expressions.forEach((exp, vairableIndex) => {
          let result = {
            isValid: true,
            message: '',
            cordinates: { start: 0, end: exp ? exp.length - 1 : 0 }
          };

          if (!exp.expression) {
            result.isValid = false;
            result.message = ERROR.MISSING_EXPRESSION;
          }

          if (result.isValid) {
            result = validateRuleExpression(exp.expression);
          }

          if (!result.isValid) {
            errors[`${groupVariableIndex}-${vairableIndex}`] = result;
          } else {
            payload.itemMappingRules.push(
              getVariableStructure({
                variableName: vairableExp.variable,
                expression: exp.expression.trim(),
                isSuppQual: exp?.isSuppqual
              })
            );
          }
        });
      });

      if (Object.keys(errors).length) {
        setExpressionValidationErrors(errors);
        setIsLoading(false);
        return;
      }

      const response = await dispatch(SaveVariableMappingRules(payload))
        .then(unwrapResult)
        .catch((error) => {
          dispatch(showBanner({ variant: 'error', message: 'Something went wrong' }));
          console.log('Error while SaveVariableMappingRules ::', error);
          setIsLoading(false);
          return error;
        });

      if (response && response?.data?.success) {
        dispatch(showBanner({ variant: 'success', message: 'Expression Saved Successfully' }));
        GetVariableMappingRules(selectedDomain);
        setIsEditEnable(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Error while saving', error);
    }
  };

  const handleCaretPositionChange = (pos, id, rulesetIndex, inputIndex) => {
    setCaretPosition((prev) => ({
      ...prev,
      position: pos,
      id,
      rulesetIndex,
      inputIndex
    }));
  };

  const renderEditableActions = () => {
    return isEditEnable ? (
      <Box display="inline-flex" marginRight="1rem">
        <Button size={'small'} variant="primary" onClick={() => setIsEditEnable(false)}>
          Edit
        </Button>
      </Box>
    ) : (
      <>
        <Box display="inline-flex" marginRight="1rem">
          <Button size={'small'} variant="secondary" onClick={() => handleLeaveModal()}>
            Cancel
          </Button>
        </Box>
        <Box display="inline-flex" marginRight="1rem">
          <Button
            display="inline-flex"
            size={'small'}
            variant="primary"
            onClick={() => saveHandler()}>
            Save
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      {isLoading && (
        <LoaderOverlay>
          <CircularProgress variant="indeterminate" size={51} />
        </LoaderOverlay>
      )}
      <Box className={classes.container}>
        <Box className={classes.stickyHeader}>
          <Box display={'flex'} flexDirection={'column'} className={classes.header}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              alignItems={'center'}>
              <Box>
                <Typography className={classes.headerTitle}>{selectedDomain}</Typography>
                <Typography className={classes.headerSubTitle}>{subTitle}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <Search
                  size={'small'}
                  placeholder="Search Domain Variable"
                  fullWidth
                  onChange={handleSearch}
                  value={searchText}
                  style={{ marginTop: '0', minWidth: '240px', marginRight: '1rem' }}
                />

                {isDomainRulesEditable && renderEditableActions()}
              </Box>
            </Box>

            {isDomainRulesEditable && !isEditEnable && (
              <PreviewVariableSelection
                groupedVariableExpressions={groupedVariableExpressions}
                variablesList={variablesList}
                caretPosition={caretPosition}
                setCaretPosition={setCaretPosition}
                setVariablesList={setVariablesList}
                selectedDomain={selectedDomain}
                sourceFormItems={sourceFormItems}
              />
            )}
          </Box>

          <Box width={'100%'} display={'flex'}>
            <Grid container className={classes.columnsHeader}>
              <Grid component={'p'} xs={3}>
                Domain Variable
              </Grid>
              <Grid xs={9}>
                <Grid container className={classes.columnsHeader}>
                  <Grid component={'p'} xs={!isDomainRulesEditable ? 6 : 12}>
                    Expression
                  </Grid>
                  {!isDomainRulesEditable && (
                    <Grid component={'p'} xs={6} style={{ paddingLeft: '20px' }}>
                      Lineage
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box width={'100%'} display={'flex'} className={classes.rowsContainer}>
          <Grid container style={{ overflow: 'auto', height: 'auto' }}>
            {groupedVariableExpressions.map((row, index) => {
              return (
                <VariableRow
                  key={row.variable}
                  {...row}
                  index={index}
                  isEditEnable={isEditEnable}
                  setCodelistEditorDisplay={setCodelistEditorDisplay}
                  addExpressionHandler={addExpressionHandler}
                  deleteExpressionHandler={deleteExpressionHandler}
                  onExpressionInputHandler={onExpressionInputHandler}
                  handleCaretPositionChange={handleCaretPositionChange}
                  expressionValidationErrors={expressionValidationErrors}
                  isDomainRulesEditable={isDomainRulesEditable}
                  lineageColumns={lineageLookUpObj[selectedDomain]?.[row.variable] || {}}
                />
              );
            })}
            {!groupedVariableExpressions?.length ? (
              <Grid
                xs={12}
                style={{
                  backgroundColor: 'white',
                  minHeight: '200px',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <EmptyTablePlaceholder />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Box>
      {/* Modal For Back and cancel */}
      <Modal
        open={showLeavePageModal}
        variant="warning"
        onClose={handleLeaveModal}
        title="Are you sure, you want to leave?"
        message="Your progress will not be saved, Would you still like to leave?"
        buttonProps={[
          { label: 'Cancel', onClick: handleLeaveModal },
          { label: 'Leave', onClick: handleReturnButton }
        ]}
        id="warning"
      />
    </>
  );
};

export default Variables;
