import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetDataExportLocations } from 'Redux/Service/PublishMappingSpecService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import ExportSettings from './ExportSettings/ExportSettings';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import ExportConfigurationTable from './ExportConfigurationTable';
import SavePreference from './Components/SavePreference';
import { isRulesetType } from 'Constants/TargetModelTypes';

const handleExportData = (exportData, targetDataModelType) => {
  const destinations = exportData !== undefined && [
    ...new Set(
      exportData.dataExportLocations
        .filter((item) => item.isActive === 'Y')
        .map((item) => item.destination)
    )
  ];

  const getDataByElement = (destination, type) => {
    return exportData.dataExportLocations
      .filter((item) => item.destination === destination && item.isActive === 'Y')
      .map((item) => {
        return item[type];
      });
  };

  const getExportLocationID = (destination, type) => {
    return exportData.dataExportLocations
      .filter((item) => item.destination === destination)
      .map((item) => {
        if (item.isActive === 'Y') {
          return item[type];
        }
      });
  };

  const formatedData = destinations?.map((destination) => {
    return {
      destination: destination,
      exportLocationID:
        destination === 'R&DS CDR' && isRulesetType(targetDataModelType)
          ? getExportLocationID(destination, 'exportLocationID').filter((n) => n)[0]
          : '',
      exportLocationIdOptions: getDataByElement(destination, 'exportLocationID'),
      location: '',
      locationOptions: getDataByElement(destination, 'location'),
      password: '',
      passwordOptions: getDataByElement(destination, 'password'),
      userName: '',
      userNameOptions: getDataByElement(destination, 'userName'),
      connection: '',
      isActive: getDataByElement(destination, 'isActive').filter((item) => item === 'Y')?.length
        ? true
        : false,
      selected: false
    };
  });
  return formatedData;
};

const PulishDataExport = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedExportSetting, setSelectedExportSetting] = useState(undefined);
  const [dataExport, setDataExport] = useState([]);
  const { dataExportDetails, mappingSpecDetails } = useSelector(
    (state) => state.PublishMappingSpecData
  );
  const dispatch = useDispatch();
  const { activeStep, setActiveStep, toggleSidebar, handleReturn } = props;
  const [isApplyPreferenceConfig, setIsApplyPreferenceConfig] = useState(false);
  const [appliedExportPreference, setAppliedExportPreference] = useState({});

  useEffect(() => {
    (async () => {
      const ExportData = await dispatch(GetDataExportLocations()).then(unwrapResult);
      if (ExportData.data.success) {
        setDataExport(
          dataExportDetails?.length
            ? dataExportDetails
            : handleExportData(ExportData.data, mappingSpecDetails.targetDataModelType)
        );
      } else {
        dispatch(showBanner({ variant: 'error', message: ExportData.data.message }));
      }
      setLoading(false);
    })();
  }, []);

  const handleExportSetting = (row) => {
    toggleSidebar(false);
    setSelectedExportSetting({ ...row });
  };

  const handleExportSettingBack = () => {
    toggleSidebar(true);
    setSelectedExportSetting(undefined);
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title:
            location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
              ? 'Manage Data Pipelines'
              : 'Data Products',
          path:
            location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
              ? '/manage-data-pipeline'
              : '/product-designer'
        },
        {
          title: 'Publish'
        }
      ])
    );
  };

  return (
    <React.Fragment>
      {selectedExportSetting ? (
        <ExportSettings
          handleBack={handleExportSettingBack}
          handleReturn={handleReturn}
          exportDetails={selectedExportSetting}
          setActiveStep={setActiveStep}
          setDataExport={setDataExport}
          setIsApplyPreferenceConfig={setIsApplyPreferenceConfig}
          setAppliedExportPreference={setAppliedExportPreference}
        />
      ) : (
        <ExportConfigurationTable
          handleReturn={handleReturn}
          dataExport={dataExport}
          setDataExport={setDataExport}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          loading={loading}
          handleExportSetting={handleExportSetting}
        />
      )}
      {isApplyPreferenceConfig && (
        <SavePreference
          setIsApplyPreferenceConfig={setIsApplyPreferenceConfig}
          setAppliedExportPreference={setAppliedExportPreference}
          appliedExportPreference={appliedExportPreference}
        />
      )}
    </React.Fragment>
  );
};

export default PulishDataExport;
