import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Tooltip
} from 'apollo-react';
import { Info } from 'apollo-react-icons';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import { neutral2, neutral8 } from 'apollo-react/colors';
import Container from 'apollo-react/components/Container';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import { Notification } from 'Components/Common/Notification';
import CustomModal from 'Components/Modal/Modal';
import ErrorIcon from 'Images/error.svg';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import { IS_SUPPQUAL } from 'Utils/Constants';
import { KeyConstants } from 'Utils/KeyConstants';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalKeys from 'Utils/useGlobalKeys';
import useGlobalMessage from 'Utils/useGlobalMessage';
import ColumnVariable from '../Components/ColumnVariable';
import ConfigSection from '../Components/ConfigSection';
import { isSDTMType } from 'Constants/TargetModelTypes';

const useStyles = makeStyles({
  checkBoxClass: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left'
  },
  columnsHeader: {
    backgroundColor: '#F6F7FB',
    height: '44px',
    display: 'flex'
  },
  domainColumnCard: {
    height: '450px',
    borderRadius: '8px',
    padding: '0'
  },
  additionalDomainColumnCard: {
    height: '450px',
    borderRadius: '8px',
    padding: '0',
    backgroundColor: neutral2
  },
  validationErrorsContainer: {
    maxWidth: 'none',
    maxHeight: '184px',
    padding: '14px',
    borderRadius: '5px',
    borderColor: '#E3E3E3',
    backgroundColor: '#FCEBEB',
    overflow: 'auto'
  },
  errorTitle: {
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#E20000',
    textAlign: 'left'
  },
  errorContent: {
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#444444'
  }
});

const AdditionalDomainsMessage = () => {
  return (
    <Grid style={{ padding: '10px' }}>
      <Notification
        variant={'info'}
        message={
          'Column details are not available for Additional Domains, as these are the new domains created using a SQL query during mapping and actual domains are not created at this point to pull the column details.'
        }
      />
    </Grid>
  );
};

const DOMAIN_TAB = {
  'User Defined': 0,
  'System Defined': 1,
  'Additional Domains': 2
};

const isInputValid = (value) => {
  //Regex to match text with Alphanumeric,Underscore and Up to 64 chars
  const regex = new RegExp('^[a-zA-Z0-9_]{0,64}$');
  return regex.test(value);
};

const ColumnRow = ({
  style,
  index,
  data: {
    localColumns,
    userDefinedVarCount,
    rowData,
    readOnly,
    columnNameErrorList,
    onMoveVariableRow,
    handleSelectVariableCheckbox,
    handleColumnNameChange,
    moveToIndexState,
    setMoveToIndexState,
    draggedRowIndex,
    setDraggedRowIndex
  }
}) => (
  <ColumnVariable
    {...localColumns[index]}
    key={`${localColumns.map((column) => column.originalColumnName)}`}
    style={style}
    index={index}
    userDefinedVarCount={userDefinedVarCount}
    rowData={rowData}
    readOnly={readOnly}
    columnNameErrorList={columnNameErrorList}
    onMoveVariableRow={onMoveVariableRow}
    handleSelectVariableCheckbox={handleSelectVariableCheckbox}
    handleColumnNameChange={handleColumnNameChange}
    moveToIndexState={moveToIndexState}
    setMoveToIndexState={setMoveToIndexState}
    draggedRowIndex={draggedRowIndex}
    setDraggedRowIndex={setDraggedRowIndex}
  />
);

const getErrorComponent = (error, index, classes) => {
  if (typeof error === 'string') {
    return (
      <li key={`${index}-${error}`} className={classes.errorContent}>
        <Typography className={classes.errorContent}>{error}</Typography>
      </li>
    );
  } else if (typeof error === 'object') {
    return (
      <div key={`${index}-${error.errorCount}`}>
        <li className={classes.errorContent}>
          {`Domain ${error.domainNameError} have ${error?.errorCount} errors, please resolve
                  to proceed.`}
        </li>
        {error.dataFileNameError && (
          <ul>
            <li className={classes.errorContent}>
              {`Data Filename ${error.dataFileNameError} has already been used, provide a
                      different name.`}
            </li>
          </ul>
        )}
        {error.columnNameError?.length > 0 && (
          <ul>
            {error.columnNameError.map((columnName, i) => (
              <li key={`${i}-${columnName}`} className={classes.errorContent}>
                {`Export Column Name ${columnName} has already been used, provide a different
                        name.`}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
const ErrorMessages = ({ errorMessages, classes }) => {
  if (errorMessages?.length) {
    return (
      <Container className={classes.validationErrorsContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.errorTitle}>
            Resolve following errors to continue
          </Typography>
        </div>
        <ol style={{ paddingLeft: '13px', marginBottom: '0px', marginTop: '8px' }}>
          {errorMessages.map((error, index) => getErrorComponent(error, index, classes))}
        </ol>
      </Container>
    );
  } else return <></>;
};

const DomainNameCell = ({ domain }) => {
  return (
    <Tooltip variant="dark" title={domain.domainCode}>
      <Typography
        sx={{
          fontSize: '14px',
          color: neutral8,
          fontWeight: '500',
          textAlign: 'left',
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
        {domain.domainCode}
      </Typography>
    </Tooltip>
  );
};

const DataFileNameCell = ({ readOnly, domain, validationResult, handleDataFileNameChange }) => {
  let isError =
    domain.dataFileName &&
    domain.dataFileName.toUpperCase() ===
      validationResult?.errorFields?.[domain.domainCode]?.dataFileNameError;
  return (
    <TextField
      value={readOnly ? '' : domain.dataFileName}
      label=""
      InputProps={{
        inputProps: {
          'data-testid': `${domain.domainCode}-filename`,
          maxLength: 100
        }
      }}
      placeholder=""
      style={{ width: '145px', marginTop: '0px' }}
      disabled={!domain.checked}
      onChange={handleDataFileNameChange}
      error={isError}
    />
  );
};

const DomainErrorIcon = ({ domain, validationResult }) => {
  const { getMessage } = useGlobalMessage();
  let errorList = [];
  if (validationResult?.errorFields?.[domain.domainCode]?.dataFileNameError) {
    errorList.push(getMessage(MessageConstants.EXPORT_ERROR_DATA_FILENAME_EXIST));
  }
  if (
    validationResult?.errorFields?.[domain.domainCode]?.columnNameError &&
    Object.keys(validationResult.errorFields[domain.domainCode].columnNameError).length > 0
  ) {
    errorList.push(getMessage(MessageConstants.EXPORT_ERROR_COLUMN_ERROR_EXIST));
  }
  return errorList.length > 0 ? (
    <Tooltip
      title={
        <ul style={{ margin: '0px 0px', padding: '0px 15px' }}>
          {errorList.map((error, index) => (
            <li key={`${index}-${error}`}>{error}</li>
          ))}
        </ul>
      }
      placement="right">
      <img alt="error-icon" style={{ paddingLeft: '5px' }} src={ErrorIcon} />
    </Tooltip>
  ) : null;
};

const DomainColumns = (props) => {
  const {
    domainAndColumns,
    setDomainAndColumns,
    includeSystemFields,
    setIncludeSystemFields,
    overwriteDataFileName,
    setOverwriteDataFileName,
    selectedSuppQualPreference,
    setSelectedSuppQualPreference,
    readOnly,
    validationResult,
    exportSettingsType
  } = props;
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [columnNameErrorList, setColumnNameErrorList] = useState([]);

  const [domains, setDomains] = useState([]);
  const [localColumns, setLocalColumns] = useState([]);
  const [moveToIndexState, setMoveToIndexState] = useState(0);
  const [draggedRowIndex, setDraggedRowIndex] = useState(0);
  const [errorMessages, setErrorMessages] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const mouseRef = useRef();
  const { selectedPreferences, mappingSpecDetails } = useSelector(
    (state) => state.PublishMappingSpecData
  );
  const { getMessage } = useGlobalMessage();
  const getGlobalKeys = useGlobalKeys();

  useEffect(() => {
    if (!selectedDomain) {
      const _domains = Object.keys(domainAndColumns || {});
      if (_domains.length) {
        setSelectedDomain(_domains[0]);
      }
    }
  }, [domainAndColumns]);

  useEffect(() => {
    let _domains = [];

    for (let domainCode in domainAndColumns) {
      let domain = domainAndColumns[domainCode];
      if (DOMAIN_TAB[domain.domainType] === tab) {
        _domains.push(domain);
      }
    }

    if (DOMAIN_TAB[domainAndColumns[selectedDomain]?.domainType] !== tab) {
      setSelectedDomain(_domains?.[0]?.domainCode || '');
    }
    setDomains(_domains);
  }, [selectedDomain, tab, readOnly, selectedPreferences]);

  useEffect(() => {
    const message = [];

    if (validationResult?.atleastOneDomainSelected === false)
      message.push('At least one domain should be selected to proceed.');

    for (let errorField in validationResult?.errorFields) {
      let error = {
        domainNameError: errorField,
        dataFileNameError: '',
        columnNameError: [],
        errorCount: 0
      };

      let errorColumnNames =
        validationResult.errorFields[errorField]?.columnNameError &&
        Object.keys(validationResult.errorFields[errorField].columnNameError);
      if (errorColumnNames?.length) {
        error.columnNameError = errorColumnNames;
        error.errorCount = error.errorCount + errorColumnNames.length;

        if (selectedDomain === errorField) setColumnNameErrorList(errorColumnNames);
      }

      if (validationResult.errorFields[errorField]?.dataFileNameError) {
        error.dataFileNameError = validationResult.errorFields[errorField].dataFileNameError;
        error.errorCount = error.errorCount + 1;
      }
      message.push(error);
    }

    setErrorMessages(message);
  }, [validationResult, selectedDomain]);

  const { allColumnsChecked, someColumnsChecked } = useMemo(() => {
    let _allColumnsChecked = true;
    let _someColumnsChecked = false;

    for (let column of localColumns) {
      if (!column.isSystemColumn && column.isSuppQual !== IS_SUPPQUAL.Y) {
        _allColumnsChecked = _allColumnsChecked && column.checked;
        _someColumnsChecked = _someColumnsChecked || column.checked;
      }
    }

    return {
      allColumnsChecked: _allColumnsChecked,
      someColumnsChecked: !_allColumnsChecked && _someColumnsChecked
    };
  }, [localColumns, includeSystemFields]);

  const { checkedDomainsCount, allDomainsChecked, someDomainsChecked } = useMemo(() => {
    let _checkedDomainsCount = 0;
    let _allDomainsChecked = true;
    let _someDomainsChecked = false;

    for (let domain of domains) {
      if (DOMAIN_TAB[domain.domainType] === tab) {
        _allDomainsChecked = _allDomainsChecked && domain.checked;
        _someDomainsChecked = _someDomainsChecked || domain.checked;
        domain.checked && _checkedDomainsCount++;
      }
    }
    return {
      checkedDomainsCount: _checkedDomainsCount,
      allDomainsChecked: _allDomainsChecked,
      someDomainsChecked: !_allDomainsChecked && _someDomainsChecked
    };
  }, [domains]);

  const userDefinedVarCount = useMemo(() => {
    let _userDefinedVarCount = 0;
    if (selectedDomain) {
      const variables = domainAndColumns[selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSystemColumn !== true && variable.isSuppQual !== IS_SUPPQUAL.Y
      );
      _userDefinedVarCount = variables.length;
    }

    return _userDefinedVarCount;
  }, [domains, domainAndColumns, selectedDomain, tab]);

  useEffect(() => {
    let _columns = [];
    const _selectedDomain =
      domainAndColumns[selectedDomain]?.domainType &&
      DOMAIN_TAB[domainAndColumns[selectedDomain]?.domainType] !== tab
        ? Object.keys(domainAndColumns || {})[0]
        : selectedDomain;
    if (_selectedDomain) {
      const systemVariables = domainAndColumns[_selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSystemColumn === true
      );
      const suppQualVariables = domainAndColumns[_selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSuppQual === IS_SUPPQUAL.Y
      );
      const variables = domainAndColumns[_selectedDomain]?.exportVariables.filter(
        (variable) => variable.isSystemColumn !== true && variable.isSuppQual !== IS_SUPPQUAL.Y
      );
      _columns = variables.concat(suppQualVariables).concat(systemVariables);
    }
    setLocalColumns(_columns);
  }, [selectedDomain, tab, readOnly, selectedPreferences]);

  useEffect(() => {
    if (selectedDomain) {
      const modifiedDomainsAndColumns = domainAndColumns;
      if (modifiedDomainsAndColumns[selectedDomain]?.exportVariables) {
        modifiedDomainsAndColumns[selectedDomain].exportVariables = localColumns;
      }
      setDomainAndColumns(modifiedDomainsAndColumns);
      if (localColumns?.length && localColumns?.every((column) => !column.checked)) {
        const modifiedDomains = [...domains];
        const domainIndex = modifiedDomains.findIndex(
          (_domain) => _domain.domainCode === selectedDomain
        );
        if (domainIndex !== -1) modifiedDomains[domainIndex].checked = false;
        setDomains(modifiedDomains);
      }
    }
  }, [localColumns]);

  useEffect(() => {
    const modifiedDomainsAndColumns = domainAndColumns;
    for (let domain of domains) {
      modifiedDomainsAndColumns[domain.domainCode].checked = domain.checked;
    }
    setDomainAndColumns(modifiedDomainsAndColumns);
  }, [domains]);

  const handleSelectAllDomain = async (e) => {
    const modifiedDomains = [...domains];
    for (let domain of modifiedDomains) {
      domain.checked = e.target.checked;
      domain.exportVariables = domain.exportVariables?.map((column) => ({
        ...column,
        checked:
          column.isSuppQual === IS_SUPPQUAL.Y &&
          selectedSuppQualPreference === KeyConstants.EXPORT_SUPPQUAL_TYPES.VALUES.DO_NOT_EXPORT.KEY
            ? false
            : column.isSuppQual === IS_SUPPQUAL.Y
            ? true
            : column.isSystemColumn
            ? column.checked
            : e.target.checked,
        updatedColumnName: ''
      }));
      if (domain?.domainCode === selectedDomain) setLocalColumns(domain.exportVariables);
    }
    setDomains(modifiedDomains);
  };

  const handleSelectAllVariable = async (e) => {
    const modifiedLocalColumns = [...localColumns];
    for (let column of modifiedLocalColumns) {
      if (!column.isSystemColumn && column.isSuppQual !== IS_SUPPQUAL.Y) {
        column.checked = e.target.checked;
        column.updatedColumnName = '';
      }
    }
    setLocalColumns(modifiedLocalColumns);
  };

  const handleSelectDomainCheckbox = async (index) => {
    const modifiedDomains = [...domains];
    modifiedDomains[index].checked = !modifiedDomains[index].checked;
    modifiedDomains[index].dataFileName = '';
    modifiedDomains[index].exportVariables = modifiedDomains[index].exportVariables?.map(
      (column) => ({
        ...column,
        checked:
          column.isSuppQual === IS_SUPPQUAL.Y &&
          selectedSuppQualPreference === KeyConstants.EXPORT_SUPPQUAL_TYPES.VALUES.DO_NOT_EXPORT.KEY
            ? false
            : column.isSuppQual === IS_SUPPQUAL.Y
            ? true
            : column.isSystemColumn
            ? column.checked
            : modifiedDomains[index].checked,
        updatedColumnName: ''
      })
    );
    if (modifiedDomains[index]?.domainCode === selectedDomain)
      setLocalColumns(modifiedDomains[index]?.exportVariables);

    setDomains(modifiedDomains);
  };

  const handleDataFileNameChange = (index, event) => {
    let value = event.target.value;
    if (isInputValid(value)) {
      const modifiedDomains = [...domains];
      modifiedDomains[index].dataFileName = value;
      setDomains(modifiedDomains);
    }
  };

  const handleSelectVariableCheckbox = async (index) => {
    const modifiedLocalColumns = [...localColumns];
    modifiedLocalColumns[index].checked = !modifiedLocalColumns[index].checked;
    modifiedLocalColumns[index].updatedColumnName = '';
    setLocalColumns(modifiedLocalColumns);
  };

  const handleColumnNameChange = async (index, e) => {
    const modifiedLocalColumns = [...localColumns];
    modifiedLocalColumns[index].updatedColumnName = e.target.value;
    setLocalColumns(modifiedLocalColumns);
  };

  const handleChangeTabs = async (event, value) => {
    setTab(value);
    setMoveToIndexState(0);
    setDraggedRowIndex(0);
  };

  const handleToggleChange = (e, checked) => {
    const modifiedLocalColumns = [...localColumns];
    for (let column of modifiedLocalColumns) {
      column.checked = column.isSystemColumn ? checked : column.checked;
    }
    setLocalColumns(modifiedLocalColumns);

    const modifiedDomainsAndColumns = { ...domainAndColumns };
    for (let domainCode in modifiedDomainsAndColumns) {
      modifiedDomainsAndColumns[domainCode].exportVariables = modifiedDomainsAndColumns[
        domainCode
      ].exportVariables?.map((column) => ({
        ...column,
        checked: column.isSystemColumn ? checked : column.checked
      }));
      if (
        modifiedDomainsAndColumns[domainCode].exportVariables?.every((column) => !column.checked)
      ) {
        modifiedDomainsAndColumns[domainCode].checked = false;
      }
    }
    setDomainAndColumns(modifiedDomainsAndColumns);

    setIncludeSystemFields(checked);
  };

  const resetDataFileNames = (checked) => {
    const modifiedDomainsAndColumns = { ...domainAndColumns };
    for (let domainCode in modifiedDomainsAndColumns) {
      modifiedDomainsAndColumns[domainCode].dataFileName = '';
    }
    setDomainAndColumns(modifiedDomainsAndColumns);
    setOverwriteDataFileName(checked);
  };

  const handleOverwriteFileNameToggle = (e) => {
    if (e.target.checked) {
      resetDataFileNames(true);
    } else {
      setConfirmAlert({
        enabled: true,
        title: getMessage(MessageConstants.EXPORT_OVERWRITE_DATA_FILENAME_WARNING_TITLE),
        message: getMessage(MessageConstants.EXPORT_OVERWRITE_DATA_FILENAME_WARNING_DESC),
        variant: 'warning',
        onConfirm: () => {
          resetDataFileNames(false);
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
    }
  };

  const handleSelectDomain = (domain) => {
    setSelectedDomain(domain.domainCode);
  };

  const handleMoveVariableRow = (moveFrom, moveTo) => {
    console.log('moveFrom, moveTo', moveFrom, moveTo);
    const modifiedLocalColumns = [...localColumns];
    let [rowToMove] = modifiedLocalColumns.splice(moveFrom, 1);
    modifiedLocalColumns.splice(moveTo, 0, rowToMove);
    setLocalColumns(modifiedLocalColumns);
    setMoveToIndexState();
    setDraggedRowIndex();
  };

  const handleCustomDragEnd = (e) => {
    if (mouseRef.current?.buttons === 1 && e.buttons === 0) {
      if (draggedRowIndex !== undefined) {
        if (moveToIndexState < userDefinedVarCount) {
          console.log('moveToIndex', draggedRowIndex, moveToIndexState);
          if (draggedRowIndex !== moveToIndexState)
            handleMoveVariableRow(draggedRowIndex, moveToIndexState);
        }
      }
    }
    mouseRef.current = e;
  };

  const handleSuppQualChange = (e) => {
    setSelectedSuppQualPreference(e.target.value);
    const modifiedDomainsAndColumns = domainAndColumns;
    for (let domain of domains) {
      modifiedDomainsAndColumns[domain.domainCode].exportVariables?.forEach((variable) => {
        if (variable.isSuppQual === IS_SUPPQUAL.Y) {
          variable.checked =
            e.target.value !== KeyConstants.EXPORT_SUPPQUAL_TYPES.VALUES.DO_NOT_EXPORT.KEY;
        }
      });
    }
    setDomainAndColumns(modifiedDomainsAndColumns);
  };

  return (
    <ConfigSection
      title="Domains & Columns"
      subtitle="Select, rename and reorder the columns according to your preference for the export."
      loading={props.domainAndColumnsLoading}
      hasError={!!errorMessages.length}>
      <div>
        <Grid item xs={12}>
          <ErrorMessages errorMessages={errorMessages} classes={classes} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container item xs={3}>
            <Grid
              container
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
              <span>Include System Fields</span>
              <Tooltip
                variant="dark"
                title={
                  "Enabling this to Include all 'System Fields' from all domains in the export"
                }
                placement="right"
                style={{ height: '1rem', width: '1rem', marginLeft: '4px' }}>
                <IconButton color="neutral7">
                  <Info style={{ width: '16px', maxHeight: '16px' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container item xs={4}>
              <Tooltip
                title="Enabling this option will include all 'System Fields' columns from all domains in the export."
                placement="right">
                <Switch
                  disabled={readOnly}
                  size="small"
                  data-testid="switch-btn"
                  style={{ marginBottom: '20px' }}
                  checked={includeSystemFields}
                  onChange={handleToggleChange}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {isSDTMType(mappingSpecDetails.targetDataModelType) &&
          exportSettingsType === 'User Defined' ? (
            <>
              <Grid item xs={1}>
                <Box style={{ borderLeft: '1px solid #C7C7C7', height: '5rem' }}></Box>
              </Grid>
              <Grid xs={7}>
                <Select
                  id="SupplementalQualifiers"
                  name="Supplemental Qualifiers"
                  label="Supplemental Qualifiers"
                  fullWidth
                  disabled={readOnly}
                  SelectProps={{
                    renderValue: (selected) =>
                      getMessage(KeyConstants.EXPORT_SUPPQUAL_TYPES.VALUES[selected]?.LABEL)
                  }}
                  value={selectedSuppQualPreference}
                  onChange={handleSuppQualChange}
                  style={{ margin: '6px 0 15px 0' }}>
                  {getGlobalKeys(KeyConstants.EXPORT_SUPPQUAL_TYPES.TOPIC_NAME)?.map(
                    (suppQualPreferenceKey) => (
                      <MenuItem value={suppQualPreferenceKey} key={suppQualPreferenceKey}>
                        <div>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#444444',
                              fontWeight: '700'
                            }}>
                            {getMessage(
                              KeyConstants.EXPORT_SUPPQUAL_TYPES.VALUES[suppQualPreferenceKey]
                                ?.LABEL
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              color: '#595959',
                              fontWeight: '400'
                            }}>
                            {getMessage(
                              KeyConstants.EXPORT_SUPPQUAL_TYPES.VALUES[suppQualPreferenceKey]?.DESC
                            )}
                          </Typography>
                        </div>
                      </MenuItem>
                    )
                  )}
                </Select>
              </Grid>
            </>
          ) : null}
        </Grid>
        <Divider />
        <Box
          draggable={false}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1rem 1rem 1rem 0',
            borderRadius: '5px'
          }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Card style={{ height: '450px', borderRadius: '8px' }}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    paddingRight: '10px'
                  }}>
                  <Grid container item xs={4}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: neutral8,
                        fontWeight: '600',
                        padding: '1rem 0rem 1rem 1.5rem'
                      }}>
                      {`Select Domain - ${checkedDomainsCount}`}
                    </Typography>
                  </Grid>
                  <Grid container item xs={8}>
                    <Grid container>
                      <Grid
                        item
                        xs={10}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          paddingRight: '10px'
                        }}>
                        <span
                          style={{
                            fontSize: '16px',
                            color: neutral8,
                            fontWeight: '600'
                          }}>
                          {getMessage(MessageConstants.EXPORT_OVERWRITE_DATA_FILENAME_LABEL)}
                        </span>
                        <Tooltip
                          variant="dark"
                          title={getMessage(
                            MessageConstants.EXPORT_OVERWRITE_DATA_FILENAME_TOOLTIP
                          )}
                          placement="right"
                          style={{ height: '1rem', width: '1rem', marginLeft: '4px' }}>
                          <IconButton color="neutral7">
                            <Info style={{ width: '16px', maxHeight: '16px' }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={2}>
                        <Switch
                          disabled={readOnly}
                          size="small"
                          data-testid="overwrite-switch-btn"
                          style={{ paddingTop: '12px' }}
                          checked={overwriteDataFileName}
                          onChange={handleOverwriteFileNameToggle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={tab}
                    className="sm-tabs"
                    onChange={handleChangeTabs}
                    truncate
                    data-testid="change-tab">
                    <Tab label="Domains" className="sm-tab" />
                    <Tab label="System Domains" className="sm-tab" />
                    <Tab
                      label="Additional Domains"
                      className="sm-tab"
                      data-testid="Additional-Domains"
                    />
                  </Tabs>
                </Grid>
                <div>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      backgroundColor: '#F6F7FB',
                      height: '44px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <Grid item xs={2} className={classes.checkBoxClass}>
                      <Checkbox
                        checked={allDomainsChecked}
                        indeterminate={someDomainsChecked}
                        onChange={handleSelectAllDomain}
                        disabled={readOnly}
                        data-testid="allDomainsChecked-checkbox"
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.checkBoxClass}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: neutral8,
                          fontWeight: '400',
                          textAlign: 'left',
                          width: '100%'
                        }}>
                        Domains
                      </Typography>
                    </Grid>
                    {overwriteDataFileName && (
                      <Grid item xs={4} className={classes.checkBoxClass}>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            color: neutral8,
                            fontWeight: '400',
                            textAlign: 'left',
                            width: '100%'
                          }}>
                          {getMessage(MessageConstants.EXPORT_DOMAIN_DATA_FILENAME_COLUMN_LABEL)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Card
                    style={{
                      maxHeight: '300px',
                      position: 'relative',
                      overflow: 'auto',
                      paddingBottom: '0px',
                      boxShadow: 'none'
                    }}>
                    {domains.map((domain, index) => {
                      return (
                        <React.Fragment key={domain.domainCode}>
                          <Divider />
                          <Grid
                            container
                            item
                            xs={12}
                            onClick={() => handleSelectDomain(domain)}
                            sx={{
                              height: '49px',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: selectedDomain === domain.domainCode && '#ECF3FF',
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: selectedDomain !== domain.domainCode && '#ECF3FF95'
                              }
                            }}>
                            <Grid item xs={2} className={classes.checkBoxClass}>
                              <Checkbox
                                data-testid="selectDomain-checkbox"
                                disabled={readOnly}
                                checked={domain.checked}
                                onChange={() => handleSelectDomainCheckbox(index)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={overwriteDataFileName ? 4 : 8}
                              className={classes.checkBoxClass}>
                              <DomainNameCell domain={domain} />
                            </Grid>
                            {overwriteDataFileName && (
                              <Grid xs={4} style={{ alignItems: 'center', display: 'flex' }}>
                                <DataFileNameCell
                                  readOnly={readOnly}
                                  domain={domain}
                                  validationResult={validationResult}
                                  handleDataFileNameChange={(e) =>
                                    handleDataFileNameChange(index, e)
                                  }
                                />
                              </Grid>
                            )}
                            <Grid item xs={1} className={classes.checkBoxClass}>
                              <DomainErrorIcon
                                domain={domain}
                                validationResult={validationResult}
                              />
                            </Grid>
                            <Grid item xs={1} className={classes.checkBoxClass}>
                              {selectedDomain === domain.domainCode && <ChevronRight />}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Card>
                </div>
              </Card>
            </Grid>
            <Grid item xs={7}>
              <Card
                className={
                  tab === 2 ? classes.additionalDomainColumnCard : classes.domainColumnCard
                }
                style={{ height: '450px', borderRadius: '8px', padding: '0' }}>
                <CardContent style={{ padding: '0px' }}>
                  {tab === 2 ? (
                    <AdditionalDomainsMessage />
                  ) : (
                    <>
                      <Grid container style={{ padding: '20px', BorderBottom: '1px' }}>
                        <Grid item xs={12}>
                          <Typography
                            variant="title2"
                            gutterBottom
                            style={{
                              fontSize: '16px',
                              color: '#000000',
                              fontWeight: '600',
                              lineHeight: '24px'
                            }}>
                            {`Configure Columns from ${selectedDomain}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{
                              fontSize: '14px',
                              color: '#595959',
                              letterSpacing: 0,
                              lineHeight: '24px'
                            }}>
                            Select, rename and reorder the columns according to your preference for
                            the export.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid
                        container
                        style={{ alignItems: 'center' }}
                        className={classes.columnsHeader}>
                        <Grid xs={1}> </Grid>
                        <Grid xs={1}>
                          <Checkbox
                            data-testid="allColumnsChecked-checkbox"
                            checked={allColumnsChecked}
                            indeterminate={someColumnsChecked}
                            onChange={handleSelectAllVariable}
                            disabled={readOnly || !domainAndColumns[selectedDomain]?.checked}
                          />
                        </Grid>
                        <Grid xs={3} style={{ fontSize: '14px' }}>
                          Column Name
                        </Grid>
                        <Grid xs={3} style={{ fontSize: '14px' }}>
                          Export Column Name
                        </Grid>
                        <Grid xs={4}></Grid>
                      </Grid>

                      <Card
                        style={{
                          maxHeight: '305px',
                          position: 'relative',
                          overflow: 'auto',
                          borderBottomWidth: '0px',
                          boxShadow: 'none',
                          paddingBottom: 0
                        }}
                        width={'100%'}
                        display={'flex'}>
                        <Grid
                          data-testid="grid"
                          item
                          container
                          sx={{ width: '100%', height: '100%' }}
                          onMouseMove={handleCustomDragEnd}>
                          {localColumns.length ? (
                            <List
                              data-testid="list"
                              useIsScrolling
                              height={300}
                              itemCount={localColumns.length}
                              itemSize={50}
                              width={1018}
                              itemData={{
                                localColumns: localColumns,
                                userDefinedVarCount: userDefinedVarCount,
                                rowData: localColumns,
                                readOnly: readOnly || !domainAndColumns[selectedDomain]?.checked,
                                columnNameErrorList: columnNameErrorList,
                                onMoveVariableRow: handleMoveVariableRow,
                                handleSelectVariableCheckbox: handleSelectVariableCheckbox,
                                handleColumnNameChange: handleColumnNameChange,
                                moveToIndexState,
                                setMoveToIndexState,
                                draggedRowIndex,
                                setDraggedRowIndex
                              }}>
                              {ColumnRow}
                            </List>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Card>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={getMessage(MessageConstants.EXPORT_OVERWRITE_DATA_FILENAME_WARNING_OK)}
        handlePrimaryAction={() => confirmAlert?.onConfirm()}
        buttonSecondardyLabel={getMessage(
          MessageConstants.EXPORT_OVERWRITE_DATA_FILENAME_WARNING_CANCEL
        )}
        handleClose={() => confirmAlert?.onCancel()}
      />
    </ConfigSection>
  );
};

export default DomainColumns;
