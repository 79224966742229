import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Typography from 'apollo-react/components/Typography';
import { QCTMS_DATA_PRODUCT } from 'Utils/Constants';
import Grid from 'apollo-react/components/Grid';
import Box from 'apollo-react/components/Box';
import TextField from 'apollo-react/components/TextField';
import DetailsStrip from './components/common/DetailsStrip';
import TargetDataTable from './components/common/TargetDataTable';
import { setNextEnabled, setQCTMSDataProduct } from 'Redux/Slice/SDHDataProductSlice';
import { GetViewGlobalLibraries } from 'Redux/Service/GlobalLibraryService';
import { unwrapResult } from '@reduxjs/toolkit';
import { dateFormatByType } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';

const QCTMS = () => {
  const sdhDataProduct = useSelector((state) => state.SDHDataProduct);
  const {
    workFlowModel = [],
    sdhDataProductConfig = {}
    //  QCTMSDataProduct =[]
  } = sdhDataProduct || {};
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [targetDataSet, setTargetDataSet] = useState([]);
  const [qctmsDPConfig, setQctmsDPConfig] = useState(sdhDataProductConfig[QCTMS_DATA_PRODUCT]);

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-designer'
        },
        {
          title: 'QCTMS Data Product',
          path: `/product-designer/${workFlowModel[1]?.moduleName}`
        }
      ])
    );
    dispatch(
      setQCTMSDataProduct({
        QCTMSConfig: sdhDataProductConfig[QCTMS_DATA_PRODUCT]
      })
    );
  }, []);

  const loadTargetDataSet = async () => {
    try {
      const targetSelectData = await dispatch(
        GetViewGlobalLibraries({ IsActive: true, exceptRuleset: false, GlobalLibType: 'NonEcrf' })
      ).then(unwrapResult);

      const { data } = targetSelectData;
      if (data.success) {
        const { libraries } = data;

        const updatedLibraries =
          libraries?.length > 0
            ? libraries.map((lib) => {
                return {
                  ...lib,
                  createdDate: dateFormatByType(lib.createdDate, 'Table')
                };
              })
            : [];

        setTargetDataSet(updatedLibraries);
        dispatch(setQCTMSDataProduct({ targetList: updatedLibraries }));
      } else {
        dispatch(showBanner({ variant: 'error', message: data.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({ variant: 'error', message: 'Something went wrong in GetLibraries Method' })
      );
    }
  };

  useEffect(() => {
    loadTargetDataSet();
  }, []);

  const configureDPToggleHandler = (e) => {
    if (e.target.checked) {
      setQCTMSDataProduct({ QCTMSConfig: true });
      dispatch(setNextEnabled(false));
    } else {
      dispatch(setNextEnabled(true));
    }
    setQctmsDPConfig((prev) => !prev);
  };

  return (
    <>
      <Grid px={3}>
        <Typography variant="h3">Add QCTMS Data Product</Typography>
        <DetailsStrip
          qctmsDPConfig={qctmsDPConfig}
          configureDPToggleHandler={configureDPToggleHandler}
        />
        {qctmsDPConfig && (
          <>
            <Box mt={2} mb={2}>
              <Grid item xs={5}>
                <TextField
                  id="description"
                  label="Description (Optional)"
                  name="description"
                  multiline
                  placeholder="Some Description"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Box>
            <TargetDataTable targetLibraryDataTable={targetDataSet} />
          </>
        )}
      </Grid>
    </>
  );
};

const QCTMSDataProducts = forwardRef(QCTMS);
export default QCTMSDataProducts;
