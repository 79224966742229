import Switch from 'apollo-react/components/Switch';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeLoader, showLoader } from 'Redux/Slice/LoaderSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { SaveDashboardPipeline } from 'Redux/Service/DashboardService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import CustomModal from 'Components/Modal/Modal';
import { setDashboardApis } from 'Redux/Slice/DashboardSlice';
import Tooltip from 'apollo-react/components/Tooltip';
import { hasDashboardPipelineAccess } from 'service/AppPermissions';
import Cookies from 'js-cookie';

const ActivateDataPipeline = ({ row }) => {
  const dispatch = useDispatch();
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const changeDataPipelineStatus = async (row) => {
    dispatch(showLoader);
    let payload = {
      pipelineStatus: {
        mappingRuleDeployID: row.mappingRuleDeployID,
        status: row.isActivePipeline === 'Active' ? false : true,
        queueID: row.queueId,
        mappingRuleVersionId: row.mappingRuleVersionID,
        targetDataModelType: row.targetDataModelType,
        userID: Cookies.get('user.id'),
        protocolNumber: row.protocolNumber
      }
    };
    const studySpecificData = await dispatch(SaveDashboardPipeline(payload)).then(unwrapResult);
    if (studySpecificData.data.success) {
      dispatch(showBanner({ variant: 'success', message: studySpecificData.data.message }));
      setOpenWarningModal(false);
    } else {
      dispatch(showBanner({ variant: 'error', message: studySpecificData.data.message }));
    }
    dispatch(setDashboardApis(true));
    dispatch(closeLoader());
  };

  return (
    <>
      <Tooltip
        title={
          hasDashboardPipelineAccess(row[row.protocolNumber], 'activateDataPipeline')
            ? `You don't have permission to activate/deactivate 
                data pipelines. Contact an administrator for help.`
            : row.queueStatusType === 'queueinprogress' || row.queueStatusType === 'queued'
            ? `Data pipelines with 'In progress' or 'In Queue' status cannot be turned off`
            : ''
        }>
        <span>
          <Switch
            checked={row.isActivePipeline === 'Active' ? true : false}
            size="small"
            disabled={
              hasDashboardPipelineAccess(row[row.protocolNumber], 'activateDataPipeline') ||
              row.readOnlyPipeline
            }
            onChange={
              row.isActivePipeline !== 'Active'
                ? () => changeDataPipelineStatus(row)
                : () => {
                    setOpenWarningModal(true);
                  }
            }
          />
        </span>
      </Tooltip>

      <CustomModal
        display={openWarningModal}
        handleClose={() => setOpenWarningModal(false)}
        title={'Deactivate data pipeline?'}
        message={
          'This will prevent any future jobs from running in this data pipeline. Are you sure you want to continue?'
        }
        buttonPrimaryLabel={'Deactivate Data Pipeline'}
        handlePrimaryAction={() => changeDataPipelineStatus(row)}
        buttonSecondardyLabel={'Cancel'}
        handleSecondaryAction={() => setOpenWarningModal(false)}
        variant={'warning'}
      />
    </>
  );
};

export default ActivateDataPipeline;
