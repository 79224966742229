/* eslint-disable */
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import Refresh from 'apollo-react-icons/Refresh';
import Pencil from 'apollo-react-icons/Pencil';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import { neutral7, neutral8 } from 'apollo-react/colors';
import Button from 'apollo-react/components/Button';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import Switch from 'apollo-react/components/Switch';
import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import Table from 'apollo-react/components/Table/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DownloadMappingSpecData } from 'Redux/Service/PublishMappingSpecService';
import { GetProductDataSources, GetWorkflowModel } from 'Redux/Service/RuleEditorService';
import { getStudyLibraryGrid, getProtocolAuth } from 'Redux/Service/StudyLibraryGridService';
import { closeBanner, showBanner } from 'Redux/Slice/BannerSlice';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import { getCheckboxFlag } from 'Redux/Slice/StudyLibrarySlice';
import { getRowDataForRuleEditor, openDataProductPanel } from 'Redux/Slice/DataProductLibrarySlice';
import { dateFilter, dateFormatByType, compareDates } from 'Utils';
import Loader from '../../Components/Loader/index';
import CustomModal from '../../Components/Modal/Modal';
import { setEditDataProduct } from '../../Redux/Slice/DataProductLibrarySlice';
import { GetDataProductAuditHistory } from './../../Redux/Service/ModalService';
import { downloadFile } from '../ProductDesigner/Components/PublishMappingSpec/publishUtils';
import MappingSpecDetails from './Components/PublishMappingSpec/MappingSpecDetails';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import Filter from 'apollo-react-icons/Filter';
import BookLibrary from 'Images/book-library.svg';
import { hasNoAccess } from 'service/AppPermissions';
import { setProtocolPermissions } from 'service/AppPermissions';
import { showLoader, closeLoader } from 'Redux/Slice/LoaderSlice';
import { useRef } from 'react';
import Plus from 'apollo-react-icons/Plus';
import Files from 'apollo-react-icons/Files';
import { useCloneWorkflow } from './Components/CloneDataProduct/Common';
import { DateFilter } from 'Components/Common/DateFilter';
import Box from 'apollo-react/components/Box';
import TableWrapper from 'Components/Common/TableWrapper';
import DataProductAuditHistoryTable from './Components/DataProductAuditHistoryTable';
import useDisplayName from 'Utils/useDisplayName';
import useGlobalMessage from 'Utils/useGlobalMessage';
import MenuButton from 'apollo-react/components/MenuButton';
import { resetNonEcrfState, resetSDTMState } from 'Redux/Slice/SDHDataProductSlice';
import { IntegerFilter, TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { TARGET_MODEL_TYPES, isSDTMType } from 'Constants/TargetModelTypes';

const styles = {
  page: {
    padding: 24
  },
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  },
  modalPreview: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  },
  modalAudit: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  },
  menuButtonOptions: {
    color: '#0768FD',
    fontWeight: '500',
    backgroundColor: 'white'
  },
  menuBtn: {
    marginRight: '8px'
  }
};

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959',
  height: 'auto'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '5px',
  height: 'auto'
};

const EmptyTableTypographyStyleTitle3 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '20px',
  height: 'auto'
};

const DetailRow = ({ row }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      style={{ backgroundColor: '#f8f9fb', width: '100%', padding: '0.5rem 2rem' }}>
      <Box
        style={{
          minWidth: '300px',
          marginRight: '2rem'
        }}>
        <Typography style={{ fontWeight: 500, color: neutral8 }}>{'Product ID'}</Typography>
        <Typography style={{ color: neutral7 }} variant="body2">
          {row.productID}
        </Typography>
      </Box>
      <Box
        style={{
          minWidth: '300px',
          marginRight: '1rem'
        }}>
        <Typography style={{ fontWeight: 500, color: neutral8 }}>
          {'Target Data Model ID'}
        </Typography>
        <Typography style={{ color: neutral7 }} variant="body2">
          {row.targetDataModelID}
        </Typography>
      </Box>
    </Box>
  );
};

const ProtocolNotSelected = () => {
  return (
    <Grid
      container
      style={{
        display: 'grid',
        placeItems: 'center',
        position: 'relative',
        padding: '13rem'
      }}>
      <img height="23" src={BookLibrary} style={{ marginBottom: '1rem' }} />
      <Grid xs={6}>
        <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
          Nothing to See Here
        </Typography>
      </Grid>
      <Grid xs={6}>
        <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
          Select a study to begin.
        </Typography>
      </Grid>
    </Grid>
  );
};

const StudyLibNotConfigured = () => {
  const navigate = useNavigate();
  const navigateToStudyLibraryPage = () => {
    navigate('/data-standard-library/study-library');
  };
  return (
    <DataVizCard>
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        <StatusExclamation />
      </Typography>
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        No Study Library Added
      </Typography>
      <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
        You must add a library to the study in order to add a data product.
      </Typography>
      <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
        <Button variant="primary" size="small" onClick={navigateToStudyLibraryPage} margin="28px">
          Go to Study Library Page
        </Button>
      </Typography>
    </DataVizCard>
  );
};

const StudyInactive = () => {
  return (
    <DataVizCard title="Data Products" subtitle="0 items">
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        <StatusExclamation />
      </Typography>
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        Study is Inactive
      </Typography>
    </DataVizCard>
  );
};

export const DataProductsEmpty = ({ disabled, classes, menuItems }) => {
  return (
    <DataVizCard title="Data Products" subtitle="0 items">
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        No data products found
      </Typography>
      <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
        Create data product to get started.
      </Typography>
      <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
        <MenuButton
          disabled={disabled}
          className={classes.menuBtn}
          buttonText="Data Product"
          menuItems={menuItems}
          size="small"
        />
      </Typography>
    </DataVizCard>
  );
};

const DataProducts = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [studyDataProducts, setStudyDataProducts] = useState([]);
  const [emptyTableGrid, setEmptyTableGrid] = useState(false);
  const [dataTable, setDataTable] = useState(false);
  const [configTable, setDataConfigTable] = useState(false);
  const [statusFlag, setStatusFlag] = useState(true);
  const [studyInactive, setStudyInactive] = useState(false);
  const [inactiveDataTable, setInactiveDataTable] = useState(false);
  const [addStudyButton, setAddStudyButton] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [dataProductButtonDisable, setDataProductButtonDisable] = useState(false);
  const [displayMappingSpecOverlay, toggleMappingSpecOverlay] = useState(false);
  const [selectedDraftMappingSpec, setSelectedDraftMappingSpec] = useState({});
  const { loading } = useSelector((state) => state.LoaderData);
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const CHANGE_HISTORY = 'Change History';

  const isOnlyActiveRecords = useRef();
  const selectedProtocol = useRef();
  const cloneDPWorkflow = useCloneWorkflow();

  const options = [
    {
      label: 'Create Data Product',
      onClick: () => {
        navigate('/product-designer/add-data-product');
      },
      disabled:
        hasNoAccess(protocol.protocolNumber, 'dataProductsAddandClone') ||
        dataProductButtonDisable ||
        addStudyButton ||
        inactiveDataTable,
      className: classes.menuButtonOptions
    },
    {
      label: 'Create SDH Data Product',
      onClick: () => {
        dispatch(resetNonEcrfState());
        dispatch(resetSDTMState());
        navigate('/product-designer/add-non-ecrf-data-product');
      },
      disabled:
        hasNoAccess(protocol.protocolNumber, 'dataProductsAddandClone') ||
        dataProductButtonDisable ||
        addStudyButton ||
        inactiveDataTable,
      className: classes.menuButtonOptions
    }
  ];

  const onRowEdit = (data) => {
    dispatch(setEditDataProduct(data));
    dispatch(
      breadCrumbData([
        {
          title: 'Data Products',
          path: '/product-designer'
        },
        {
          title: 'Edit Global Library',
          path: '/product-designer/edit-data-product'
        }
      ])
    );
    navigate('/product-designer/edit-data-product');
  };

  const navigateToSelectSourcePage = async (rowData) => {
    dispatch(closeBanner());
    const mappingRuleVersionId = rowData.mappingRuleversion ? rowData.mappingRuleversion : '';
    if (mappingRuleVersionId) {
      dispatch(getRowDataForRuleEditor(rowData));
      let isSuccess = false;
      let payload;
      if (rowData.targetDataModelType === 'CTP') {
        payload = TARGET_MODEL_TYPES.IQVIA_CTP;
      } else if (isSDTMType(rowData.targetDataModelType)) {
        payload = TARGET_MODEL_TYPES.CDISC_SDTM;
      } else {
        payload = rowData.targetDataModelType;
      }
      const workflowData = await dispatch(GetWorkflowModel(payload)).then(unwrapResult);
      if (workflowData) {
        if (workflowData.functionData && workflowData.functionData.length > 0) {
          isSuccess = true;
          let workflow = workflowData.functionData;
          workflow = workflow.sort((a, b) => a.order - b.order);
          navigate(
            `/product-designer/rule-editor/${mappingRuleVersionId}/${workflow[0].moduleName}`
          );
        }
      }
      if (!isSuccess) {
        dispatch(showBanner({ variant: 'error', message: 'Error Getting Workflow Model' }));
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: 'MappingRuleVersionID Not Found' }));
    }
  };

  const ActiveCell = ({ row, column: { accessor } }) => {
    const isactive = row[accessor];
    if (isactive === 'Active') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '4px 0 0' }}>
          <span
            style={{
              width: '5px',
              height: '5px',
              borderRadius: '100%',
              background: '#00C221',
              marginRight: '4px'
            }}></span>
          <Typography variant="title1" style={{ fontSize: '14px', margin: '3px 0' }}>
            {isactive}
          </Typography>
        </div>
      );
    } else {
      return <div>{isactive}</div>;
    }
  };

  const ExpandCell = ({ row: { id, handleToggleRow, expanded } }) => {
    return (
      <div style={{ width: 12, marginRight: '16px' }}>
        <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
          <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const ActionCell = ({ row }) => {
    const { onViewMappingVersions } = row;
    const { protocol } = useSelector((state) => state.StudyLibraryData);
    const [displayAuditHistory, setDisplayAuditHistory] = useState(false);
    const [auditData, setAuditData] = useState({});
    const dispatch = useDispatch();
    const getDisplayName = useDisplayName();
    const { getMessage } = useGlobalMessage();
    let menuItems = [];

    const handleDataProductAuditHistory = async (row) => {
      setDisplayAuditHistory(true);

      const payload = {
        protocolNumber: row.protocolName,
        productID: row.productID,
        isAllActive: false
      };
      const resArr = [];
      const auditData = await dispatch(GetDataProductAuditHistory(payload)).then(unwrapResult);

      if (auditData && auditData.data && auditData.data.success) {
        if (auditData.data.success) {
          auditData.data.studyDataProducts.map((el) => {
            resArr.push({
              userID: el.userID,
              auditDate: dateFormatByType(el.auditDate, 'Table'),
              auditType: el.auditType,
              reasonForChange: el.reasonForChange ? el.reasonForChange : '-',
              productVersion: el.productVersion
            });
          });
          setAuditData(resArr);
        }
      } else {
        setDisplayAuditHistory(false);
        dispatch(showBanner({ variant: 'error', message: auditData.data.message }));
      }
    };
    const handleClose = () => {
      setDisplayAuditHistory(false);
    };

    const onDownload = async (row) => {
      const {
        protocolName,
        productID,
        productVersion,
        productNnemonic,
        targetDataModelVersion,
        targetDataModelName,
        targetDataModelType
      } = row;
      let payload = {
        protocolName: protocolName,
        productID: productID,
        productVersion: productVersion
      };
      const getTableData = await dispatch(GetProductDataSources(payload)).then(unwrapResult);

      if (getTableData && getTableData.data && getTableData.data.success) {
        const mappingRuleVersionId = getTableData.data?.mappingRuleVersionID || '';
        if (mappingRuleVersionId) {
          const downloadResult = await dispatch(
            DownloadMappingSpecData({
              mappingRuleVersionID: mappingRuleVersionId
            })
          ).then(unwrapResult);

          if (downloadResult && downloadResult.data && downloadResult.data.success) {
            const params = {
              protocolName: protocolName,
              projectCode: protocol.projectCode,
              sponsor: protocol.sponsor,
              mappingSpecVersionName: '',
              productMnemonic: productNnemonic,
              targetDataModelName: targetDataModelName,
              targetDataModelVersion: targetDataModelVersion,
              targetDataModelType: targetDataModelType,
              getDisplayName,
              getMessage
            };
            downloadFile(downloadResult.data, params, `${protocolName}_${productNnemonic}_DRAFT`, {
              createdBy:
                downloadResult.data.lastUpdatedBy === null
                  ? 'Created By would be populated once user begins the Rule Processing'
                  : downloadResult.data.lastUpdatedBy,
              createdDate:
                downloadResult.data.lastUpdatedBy === null
                  ? 'Created Date would be populated once user begins the Rule Processing'
                  : dateFormatByType(downloadResult.data.lastUpdatedDate, 'Table')
            });
          } else {
            dispatch(showBanner({ variant: 'error', message: 'Something Went Wrong!' }));
          }
        }
      } else {
        dispatch(showBanner({ variant: 'error', message: getTableData.data.message }));
      }
    };

    {
      (protocol.isStudyLibraryConfigured && !protocol.isActive) ||
      row.auditType === 'DELETE' ||
      row.isCurrent === 'Inactive'
        ? (menuItems = [
            {
              text: 'Download Draft',
              disabled: hasNoAccess(row.protocolName, 'mappingSpecDownload'),
              onClick: () => onDownload(row)
            }
          ])
        : (menuItems = [
            {
              text: 'Edit Data Product',
              onClick: () => onRowEdit(row),
              disabled:
                hasNoAccess(row.protocolName, 'dataProductEditDataProduct') || row.isProductDisable
            },
            {
              text: 'Mapping Versions',
              onClick: () => onViewMappingVersions(row),
              disabled: hasNoAccess(row.protocolName, 'mappingSpecViewVersions')
            },
            {
              text: 'Download Draft',
              disabled: hasNoAccess(row.protocolName, 'mappingSpecDownload'),
              onClick: () => onDownload(row)
            },
            {
              text: 'Change History',
              onClick: () => handleDataProductAuditHistory(row),
              disabled: hasNoAccess(row.protocolName, 'dataProductChangeHistory')
            }
          ]);
    }

    return (
      <>
        <Tooltip title="Actions" disableFocusListener>
          <IconMenuButton id="actions" menuItems={menuItems} size="small">
            <EllipsisVerticalIcon />
          </IconMenuButton>
        </Tooltip>

        <CustomModal
          display={displayAuditHistory}
          data={row}
          title={`${CHANGE_HISTORY + ' ' + row.productNnemonic}`}
          subtitle={row.productID}
          handleClose={handleClose}
          type="History"
          handleSecondaryAction={() => setDisplayAuditHistory(false)}
          buttonSecondardyLabel={'Close'}
          body={
            loading ? (
              <div
                style={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}>
                <Loader />
              </div>
            ) : (
              <DataProductAuditHistoryTable rows={auditData} />
            )
          }
          width={classes.modalAudit}
        />
      </>
    );
  };

  const ActiveButtonCell = ({ row }) => {
    return (
      <>
        <Tooltip
          title={
            row.isCurrent === 'Inactive' && 'Cannot create mapping spec for Inactive Data products'
          }>
          <span>
            <Button
              style={{ color: '#0768FD', marginRight: '10px' }}
              onClick={() => navigateToSelectSourcePage(row)}
              disabled={
                hasNoAccess(row.protocolName, 'ruleEditorEditRules') || row.isCurrent === 'Inactive'
              }>
              <Pencil style={{ marginRight: '10px' }} />
              Rule Editor
            </Button>
          </span>
        </Tooltip>
      </>
    );
  };

  const VersionCell = ({ row, column: { accessor } }) => {
    const versionRight = row[accessor];
    return (
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '24px',
          color: '#595959',
          fontSize: '14px'
        }}>
        {row['isSourceUpdateRequired'] ? (
          <>
            <Tooltip title={'Update available in target data model libraries'}>
              <span style={{ display: 'inline-block' }}>
                <Button
                  icon={<StatusExclamation style={{ color: 'orange' }} />}
                  onClick={() => onRowEdit(row)}>
                  Update
                </Button>
              </span>
            </Tooltip>
            <span
              style={{
                paddingTop: '9px'
              }}>
              {versionRight}
            </span>
          </>
        ) : (
          versionRight
        )}
      </Typography>
    );
  };

  const columns = [
    {
      accessor: 'expand',
      customCell: ExpandCell
    },
    {
      header: 'Product Mnemonic',
      accessor: 'productNnemonic',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('productNnemonic'),
      fixedWidth: false
    },
    {
      header: 'Description',
      accessor: 'description',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('description'),
      fixedWidth: false
    },
    {
      header: 'Target Data Model Name',
      accessor: 'targetDataModelName',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('targetDataModelName'),
      fixedWidth: false
    },
    {
      header: 'Target Data Model Version',
      accessor: 'targetDataModelVersion',
      sortFunction: compareNumbers,
      filterComponent: IntegerFilter,
      filterFunction: numberSearchFilter('targetDataModelVersion'),
      customCell: VersionCell,
      width: 150,
      fixedWidth: false
    },
    {
      header: 'Last Updated User',
      accessor: 'userID',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('userID'),
      fixedWidth: false
    },
    {
      header: 'Last updated Date',
      accessor: 'auditDate',
      sortFunction: compareDates,
      filterFunction: dateFilter('auditDate'),
      filterComponent: DateFilter,
      fixedWidth: false
    },
    {
      header: 'Data Product Status',
      customCell: ActiveCell,
      accessor: 'isCurrent',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('isCurrent'),
      fixedWidth: false
    },
    {
      header: '',
      customCell: ActiveButtonCell
    },
    {
      accessor: 'userId',
      customCell: ActionCell
    }
  ];

  const handleStudyGridData = (e) => {
    setStatusFlag(e.target.checked);
  };

  const CustomButtonHeader = ({ toggleFilters }) => {
    return (
      <div>
        <Typography
          style={{ fontSize: '13px', margin: '0px 6px 0px 0px' }}
          variant="subtitle"
          gutterBottom>
          {'View Active Data Products'}
        </Typography>
        <Switch
          checked={statusFlag}
          size="small"
          onChange={(e) => handleStudyGridData(e)}
          style={{ marginRight: 8 }}
        />
        {dataProductButtonDisable || addStudyButton || inactiveDataTable ? (
          <Tooltip title="The selected study is inactive">
            <span style={{ display: 'inline-block' }}>
              <Button variant="secondary" size="small" disabled icon={Plus}>
                Create data product
              </Button>
            </span>
          </Tooltip>
        ) : (
          <>
            <Link
              to={`/product-designer/clone-data-product/${cloneDPWorkflow?.[0]?.moduleName}`}
              style={{
                pointerEvents:
                  hasNoAccess(protocol.protocolNumber, 'dataProductsAddandClone') && 'none'
              }}>
              <Button
                icon={Files}
                variant="secondary"
                size="small"
                disabled={
                  hasNoAccess(protocol.protocolNumber, 'dataProductsAddandClone') ||
                  dataProductButtonDisable ||
                  addStudyButton ||
                  inactiveDataTable
                }
                style={{ marginRight: 8 }}>
                Clone Data Product
              </Button>
            </Link>

            <MenuButton
              className={classes.menuBtn}
              buttonText="Data Product"
              menuItems={options}
              size="small"
            />
          </>
        )}
        <Button size="small" variant="secondary" icon={Filter} onClick={toggleFilters}>
          Filter
        </Button>
      </div>
    );
  };

  const getStudyPermissions = async (protocol) => {
    const permissionsInfo = await dispatch(getProtocolAuth(protocol)).then(unwrapResult);
    let protocolObj = {};
    if (permissionsInfo.data.success) {
      let permissionJson = permissionsInfo.data.protocolAuth;
      protocolObj[protocol] = permissionJson;
      setProtocolPermissions(protocolObj);
      return true;
    } else {
      return false;
    }
  };

  const getStudyLlibraryInfo = async () => {
    setDataProductButtonDisable(false);
    let studyLibData = await dispatch(getStudyLibraryGrid(protocol.protocolNumber)).then(
      unwrapResult
    );
    if (studyLibData && studyLibData.data && studyLibData.data.success) {
      let response = studyLibData.data.studyLibraries;
      let activeArray = [];
      let inactiveArray = [];
      if (response.length > 0) {
        response.map((el) => {
          if (el.isactive) {
            activeArray.push(el);
          } else {
            inactiveArray.push(el);
          }
        });
        if (activeArray.length === 0) {
          setDataProductButtonDisable(true);
        } else {
          setDataProductButtonDisable(false);
        }
      } else {
        setDataProductButtonDisable(true);
      }
    }
  };

  const getDataProducts = async () => {
    dispatch(showLoader());
    try {
      let getDataProductsResp;
      let filteredData = [];
      const permissionsFlag =
        Object.keys(protocol).length > 0 && (await getStudyPermissions(protocol.protocolNumber));
      const payload = {
        protocolNumber: protocol.protocolNumber,
        isAllActive: statusFlag
      };
      if (protocol && Object.entries(protocol).length > 0 && permissionsFlag) {
        if (
          (protocol.isStudyLibraryConfigured || protocol.isDataSouceConfigured) &&
          protocol.isActive
        ) {
          getDataProductsResp = await dispatch(GetDataProductAuditHistory(payload)).then(
            unwrapResult
          );
          if (getDataProductsResp?.data?.success) {
            let studyDataProductsFetched = getDataProductsResp.data.studyDataProducts;
            let transformedStudyDataProducts = [];
            if (studyDataProductsFetched.length > 0) {
              studyDataProductsFetched.map((el) => {
                transformedStudyDataProducts.push({
                  auditDate: dateFormatByType(el.auditDate, 'Table'),
                  auditType: el.auditType,
                  description: el.description,
                  isCurrent: el.isCurrent === true ? 'Active' : 'Inactive',
                  mappingRuleVersionStatus: el.mappingRuleVersionStatus,
                  mappingRuleversion: el.mappingRuleVersion,
                  productID: el.productId,
                  productNnemonic: el.productMnemonic,
                  protocolName: el.protocolNumber,
                  reasonForChange: el.reasonForChange,
                  targetDataModelID:
                    el.targetDataModelID !== '' && el.targetDataModelID !== null
                      ? el.targetDataModelID
                      : 'N/A',
                  targetDataModelName:
                    el.targetDataModel !== '' && el.targetDataModel !== null
                      ? el.targetDataModel
                      : 'N/A',
                  targetDataModelVersion:
                    el.targetDataModelVersion !== '' && el.targetDataModelVersion !== null
                      ? el.targetDataModelVersion
                      : 'N/A',
                  targetDataModelType: el.targetDataModelType,
                  userID: el.userID,
                  productVersion: el.productVersion,
                  latestTargetDataModelVersion: el.latestTargetDataModelVersion,
                  id: el.productId,
                  isSourceUpdateRequired: el.isSourceUpdateRequired,
                  isProductDisable: el.isProductDisable
                });
              });
              setEmptyTableGrid(false);
              setStudyDataProducts(transformedStudyDataProducts);
              setDataTable(true);
            } else {
              setEmptyTableGrid(true);
              setStudyDataProducts([]);
              dispatch(getCheckboxFlag(false));
            }
          } else {
            setEmptyTableGrid(false);
            setStudyDataProducts([]);
            setDataTable(false);
            dispatch(showBanner({ variant: 'error', message: getDataProductsResp.data.message }));
          }
        } else if (!protocol.isStudyLibraryConfigured && !protocol.isActive) {
          setStudyInactive(true);
          setInactiveDataTable(false);
        } else if (protocol.isStudyLibraryConfigured && !protocol.isActive) {
          getDataProductsResp = await dispatch(GetDataProductAuditHistory(payload)).then(
            unwrapResult
          );
          if (getDataProductsResp?.data?.success) {
            let response = getDataProductsResp.data.studyDataProducts;
            let studyDataProducts = [];
            if (response.length > 0) {
              response.map((el) => {
                studyDataProducts.push({
                  auditDate: dateFormatByType(el.auditDate, 'Table'),
                  auditType: el.auditType,
                  description: el.description,
                  isCurrent: el.isCurrent === true ? 'Active' : 'Inactive',
                  mappingRuleVersionStatus: el.mappingRuleVersionStatus,
                  mappingRuleversion: el.mappingRuleVersion,
                  productID: el.productId,
                  productNnemonic: el.productMnemonic,
                  protocolName: el.protocolNumber,
                  reasonForChange: el.reasonForChange,
                  targetDataModelID:
                    el.targetDataModelID !== '' && el.targetDataModelID !== null
                      ? el.targetDataModelID
                      : 'N/A',
                  targetDataModelName:
                    el.targetDataModel !== '' && el.targetDataModel !== null
                      ? el.targetDataModel
                      : 'N/A',
                  targetDataModelVersion:
                    el.targetDataModelVersion !== '' && el.targetDataModelVersion !== null
                      ? el.targetDataModelVersion
                      : 'N/A',
                  targetDataModelType: el.targetDataModelType,
                  userID: el.userID,
                  id: el.productId,
                  isProductDisable: el.isProductDisable
                });
              });
              if (statusFlag) {
                studyDataProducts.forEach((element) => {
                  if (element.isCurrent === 'Active') {
                    filteredData.push(element);
                  }
                });
                setDataTable(true);
                setEmptyTableGrid(false);
                setStudyDataProducts(filteredData);
                setAddStudyButton(true);
              } else {
                setAddStudyButton(true);
                setEmptyTableGrid(true);
                setStudyDataProducts(studyDataProducts);
              }
              setDataTable(true);
              setEmptyTableGrid(false);
            } else {
              setStudyDataProducts([]);
              setInactiveDataTable(true);
              setStudyInactive(false);
            }
          } else {
            setEmptyTableGrid(false);
            setDataTable(false);
            setStudyDataProducts([]);
            dispatch(showBanner({ variant: 'error', message: getDataProductsResp.data.message }));
          }
        } else {
          setDataTable(false);
          setEmptyTableGrid(false);
          setDataConfigTable(true);
        }
      } else {
        setDataTable(false);
        setEmptyTableGrid(false);
        setDataConfigTable(false);
      }
      dispatch(closeLoader());
    } catch (err) {
      dispatch(closeLoader());
      dispatch(showBanner({ variant: 'error', message: err.message }));
    }
  };

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  useEffect(() => {
    // dispatch(getProtocol({}));
    // location?.state === undefined && dispatch(getProtocol({}));
    return () => {
      dispatch(openDataProductPanel(true));
    };
  }, []);

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Data Product Designer',
          path: '/product-desginer'
        }
      ])
    );

    setDataTable(false);
    setEmptyTableGrid(false);
    setDataConfigTable(false);
    setStudyDataProducts([]);
    setStudyInactive(false);
    setInactiveDataTable(false);
    setAddStudyButton(false);
    if (
      protocol &&
      Object.keys(protocol).length !== 0 &&
      (selectedProtocol.current !== JSON.stringify(protocol) ||
        isOnlyActiveRecords.current !== statusFlag)
    ) {
      selectedProtocol.current = JSON.stringify(protocol);
      isOnlyActiveRecords.current = statusFlag;
      getStudyLlibraryInfo();
      getDataProducts();
    }
    dispatch(setEditDataProduct(''));
  }, [statusFlag, protocol]);

  const onViewMappingVersions = (row) => {
    setSelectedDraftMappingSpec(row);
    toggleMappingSpecOverlay(true);
  };

  return (
    <>
      <div data-test-id="red" className={classes.page}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loading ? (
              <DataVizCard>
                <Loader />
              </DataVizCard>
            ) : configTable ? (
              <StudyLibNotConfigured />
            ) : studyInactive ? (
              <StudyInactive />
            ) : inactiveDataTable || emptyTableGrid ? (
              <DataProductsEmpty
                classes={classes}
                menuItems={options}
                disabled={
                  hasNoAccess(protocol.protocolNumber, 'dataProductsAddandClone') ||
                  dataProductButtonDisable ||
                  inactiveDataTable
                }
              />
            ) : dataTable && protocol && Object.keys(protocol).length ? (
              <TableWrapper>
                <Table
                  title={
                    <div style={{ fontSize: '16px' }}>
                      Data Products
                      <Button size="small" onClick={getDataProducts}>
                        <Refresh />
                      </Button>
                    </div>
                  }
                  rows={
                    studyDataProducts &&
                    studyDataProducts.map((row, index) => {
                      const rowData = {
                        ...row,
                        // key: row.id,
                        expanded: expandedRows.includes(row.id) ? true : false,
                        handleToggleRow,
                        key: index,
                        onViewMappingVersions
                      };
                      return rowData;
                    })
                  }
                  ExpandableComponent={DetailRow}
                  columns={columns}
                  initialSortedColumn="auditDate"
                  initialSortOrder="desc"
                  CustomHeader={(props) => <CustomButtonHeader {...props} />}
                  rowsPerPageOptions={[10, 20, 50, 100, 'All']}
                  tablePaginationProps={{
                    labelDisplayedRows: DisplayedRowsLabel,
                    truncate: true
                  }}
                  hasScroll
                  maxHeight={650}
                />
              </TableWrapper>
            ) : (
              <ProtocolNotSelected />
            )}
          </Grid>
        </Grid>
        {displayMappingSpecOverlay ? (
          <MappingSpecDetails
            {...selectedDraftMappingSpec}
            displayMappingSpecOverlay={displayMappingSpecOverlay}
            toggleMappingSpecOverlay={toggleMappingSpecOverlay}
            studyLibraries={studyDataProducts}
            fetchList
            getStudyLibrary={getDataProducts}
          />
        ) : null}
      </div>
    </>
  );
};

export default DataProducts;
