import { createSlice } from '@reduxjs/toolkit';

const nonEcrfInitialState = {
  nonEcrrfDataProduct: {
    selectedTrialName: '',
    productMnemonic: '',
    libraryName: '',
    description: '',
    isRunAllExecuted: false,
    nonEcrfDatasets: [],
    targetLibrary: {},
    nonEcrfConfig: false,
    runAllExecutionWarningMsg: ''
  }
};

export const SDHDataProductSlice = createSlice({
  name: 'SDHDataProduct',
  initialState: {
    workFlowModel: {},
    sdhDataProductConfig: {},
    nextEnabled: false,
    QCTMSDataProduct: {
      QCTMSConfig: false,
      description: '',
      targetList: [],
      productMnemonic: '',
      libraryName: ''
    },
    SDTMDataProduct: {
      sdtmConfig: false,
      description: '',
      targetList: [],
      productMnemonic: '',
      libraryName: ''
    },
    ...nonEcrfInitialState
  },

  reducers: {
    setSDHDataProductWorkflow(state, { payload }) {
      state.workFlowModel = payload;
    },
    setNextEnabled(state, { payload }) {
      state.nextEnabled = payload;
    },
    setSDHDataProductConfig(state, { payload }) {
      state.sdhDataProductConfig = payload;
    },
    resetNonEcrfState(state) {
      return {
        ...state,
        nonEcrrfDataProduct: { 
          ...nonEcrfInitialState?.nonEcrrfDataProduct,
          productMnemonic:state?.nonEcrrfDataProduct?.productMnemonic,
          libraryName: state?.nonEcrrfDataProduct?.libraryName
        },
        workFlowModel: state?.workFlowModel,
        QCTMSDataProduct: state?.QCTMSDataProduct,
        SDTMDataProduct: state?.SDTMDataProduct
      };
    },
    setQCTMSDataProduct(state, { payload }) {
      state.QCTMSDataProduct = {
        ...state.QCTMSDataProduct,
        ...payload
      };
    },
    setNonEcrfDataProduct(state, { payload }) {
      state.nonEcrrfDataProduct = {
        ...state.nonEcrrfDataProduct,
        ...payload
      };
    },
    setSDTMDataProduct(state, { payload }) {
      state.SDTMDataProduct = {
        ...state.SDTMDataProduct,
        ...payload
      };
    },
    resetSDTMState(state) {
      return {
        ...state,
        nonEcrrfDataProduct: state?.nonEcrrfDataProduct,
        workFlowModel: state?.workFlowModel,
        QCTMSDataProduct: state?.QCTMSDataProduct,
        SDTMDataProduct: {
          ...state?.SDTMDataProduct,
          sdtmConfig: false,
          description: '',
          targetList: [], 
        }
      };
    },
  }
});

export const {
  setSDHDataProductWorkflow,
  setNextEnabled,
  resetNonEcrfState,
  setSDHDataProductConfig,
  setNonEcrfDataProduct,
  setQCTMSDataProduct,
  setSDTMDataProduct,
  resetSDTMState
} = SDHDataProductSlice.actions;
