/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Card from './Card';
import Grid from 'apollo-react/components/Grid';
import FileUpload from 'apollo-react/components/FileUpload';
import { jsonToExcel, convertExcelToJson, jsonToFileData, uuidv4, getHeaders } from 'Utils';
import { DeleteReferenceData, getTrialVisit } from 'Redux/Service/ReferenceDataCardsService';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import _, { template } from 'lodash';
import CustomModal from 'Components/Modal';
import { trialVisitValidator } from 'Validators/TrialVisits.Validator';
import CircularProgress from '@mui/material/CircularProgress';
import './Trials.css';
import { useNavigate, useParams } from 'react-router-dom';
import { setReferenceData } from 'service/reference-data.service';
import { checkColumns, checkFileData, checkFileType } from '../Utils/FileValidationUtils';
import useSelectedDatasourcesData from '../../hooks/useSelectedDatasourcesData';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';
import Tooltip from 'apollo-react/components/Tooltip';
import { StatusCheck, Sync } from 'apollo-react-icons';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';

// let initialItems = [
//   { name: 'Trial Visit', module: 'trial-visits', order: 1, required: false, visible: true },
//   { name: 'Time Point', module: 'time-points', order: 2, required: false, visible: true },
//   { name: 'Trial Arms', module: 'trial-arms', order: 6, required: false, visible: false },
//   { name: 'Trial IE', module: 'trial-IE', order: 3, required: false, visible: false },
//   { name: 'Trial Elements', module: 'trial-elements', order: 4, required: false, visible: false },
//   { name: 'Code List', module: 'code-lists', order: 5, required: false, visible: false }
// ];

const trialVisitColumn = [
  {
    VISIT: '',
    VISITNUM: '',
    VISITDY: '',
    ARMCD: '',
    EPOCH: '',
    ARM: '',
    TVPSTTOL: '',
    TVPENTOL: '',
    TVMSTTOL: '',
    TVMENTOL: '',
    TVRFPNT: '',
    TVGRPID: '',
    TVCLRID: '',
    TVBLFLG: '',
    TVSTRL: '',
    TVENRL: '',
    TVUPFLG: ''
  }
];

const columns = [
  'VISIT',
  'VISITNUM',
  'VISITDY',
  'ARMCD',
  'EPOCH',
  'ARM',
  'TVPSTTOL',
  'TVPENTOL',
  'TVMSTTOL',
  'TVMENTOL',
  'TVRFPNT',
  'TVGRPID',
  'TVCLRID',
  'TVBLFLG',
  'TVSTRL',
  'TVENRL',
  'TVUPFLG'
];

const TrialVisits = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const mappingRuleVersionID = params.id;
  const {
    data: selectedDataSourcesData,
    loading,
    error: apiError
  } = useSelectedDatasourcesData(mappingRuleVersionID);
  const [trialVisitData, setTrialVisitData] = useState([]);
  // const [mappingError, setMappingError] = useState('');
  const [trialVisitValidationResult, setTrialVisitValidationResult] = useState({});
  const [cards, setCardArray] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    buttonPrimaryLabel: '',
    buttonSecondaryLabel: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const [trialVisitUploadedFile, setTrialVisitUploadedFile] = useState([]);
  const [trialVisitPreview, setTrialVisitPreview] = useState(true);
  const [error, setError] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [trialVisitMessage, setTrialVisitMessage] = useState('');
  const [isValidData, setIsValidData] = useState(false);
  const [isImportStudyLibrary, setIsImportStudyLibrary] = useState(false);
  const [isQLABDataSourcesOnly, setIsQLABDataSourcesOnly] = useState(true);
  const dispatch = useDispatch();
  const { getMessage } = useGlobalMessage();
  const TRIAL_VISITS = 'trial-visits';

  const errorStyle = {
    color: '#e20000',
    fontSize: '13px',
    fontWeight: '400'
  };

  const isNull = (value) => {
    if (value === null) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!loading) {
      if (
        selectedDataSourcesData &&
        selectedDataSourcesData.data &&
        selectedDataSourcesData.data.success
      ) {
        let _selectedDataSources = selectedDataSourcesData.data.ruleStudyLibrary.filter(
          (row) => row.isMappingRuleConfigured
        );
        let isQLABDataSourcesOnly = _selectedDataSources.every(
          (row) => row.libraryType === LIBRARY_TYPES.Q2LAB
        );
        setIsQLABDataSourcesOnly(isQLABDataSourcesOnly);
      } else {
        apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
      }
    }
  }, [selectedDataSourcesData, loading]);

  useEffect(() => {
    setReferenceData('trial-visits-title', props.title);
    if (Object.keys(props.createMappingObj).length > 0 && props.createMappingObj['TrialVisit']) {
      if (!trialVisitPreview) {
        const element = document.getElementById('anchor-preview');
        element.click();
        setMappingError(props.createMappingObj['message']);
        props.createMappingObj['TrialVisit'] = false;
      }
    }
  }, [trialVisitPreview]);

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionID,
        isImport: false
      };
      const trialVisitGetAPIData = await dispatch(getTrialVisit(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(TRIAL_VISITS, true);
      props.configuration.setModuleValidation(TRIAL_VISITS, true);
      if (trialVisitGetAPIData && trialVisitGetAPIData.data && trialVisitGetAPIData.data.success) {
        if (
          trialVisitGetAPIData.data.trialVisits &&
          trialVisitGetAPIData.data.trialVisits.length > 0
        ) {
          setError('');
          const newData = trialVisitGetAPIData.data.trialVisits.map((item) => {
            return {
              visit: item.visit,
              visitnum: item.visitnum,
              visitdy: isNull(item.visitdy) ? '' : item.visitdy,
              armcd: item.armcd,
              arm: item.arm,
              epoch: item.epoch,
              tvpsttol: isNull(item.tvpsttol) ? '' : item.tvpsttol,
              tvpentol: isNull(item.tvpentol) ? '' : item.tvpentol,
              tvmsttol: isNull(item.tvmsttol) ? '' : item.tvmsttol,
              tvmentol: isNull(item.tvmentol) ? '' : item.tvmentol,
              tvrfpnt: item.tvrfpnt,
              tvgrpid: item.tvgrpid,
              tvclrid: item.tvclrid,
              tvblflg: item.tvblflg,
              tvenrl: item.tvenrl,
              tvstrl: item.tvstrl,
              tvupflg: item.tvupflg,
              id: uuidv4(),
              isImported: item.isImported
            };
          });
          const validationResult = trialVisitValidator(newData);
          const errorCountFlag = validationResult.getErrorCount();
          errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
          setTrialVisitValidationResult(validationResult);
          setTrialVisitData(newData);
          setIsImportStudyLibrary(
            trialVisitGetAPIData.data.trialVisits.some((item) => item.isImported === true)
          );
          let fileObj = jsonToFileData(newData, 'TrialVisit.xlsx');
          setTrialVisitUploadedFile([fileObj]);
          setTrialVisitPreview(false);
          console.log();
          props.configuration.setModuleValidation(TRIAL_VISITS, errorCountFlag > 0 ? false : true);
          props.configuration.setCardVisibility(TRIAL_VISITS, true);
        } else {
          props.required && props.configuration.setModuleValidation(TRIAL_VISITS, false);
          setTrialVisitData([]);
          setIsValidData(false);
        }
      } else {
        setTrialVisitMessage(trialVisitGetAPIData.data.message);
        setTrialVisitData([]);
        setIsValidData(false);
        // dispatch(showBanner({ variant: 'error', message: trialVisitData.data.message }));
      }
    })();
  }, []);

  const downloadSheet = () => {
    jsonToExcel(trialVisitColumn, 'Trial Visits.xlsx');
    // setIsFileUploadDisable(false);
  };

  const fileValidation = (fileData, headers) => {
    const isFileValid = checkFileData(fileData);
    const isColumnsValid = checkColumns(headers, columns);
    let error = '';
    if (!isFileValid && !isColumnsValid) {
      error = 'File is empty and columns are mismatched';
    } else if (isFileValid && !isColumnsValid) {
      error = 'columns are mismatched';
    } else if (!isFileValid && isColumnsValid) {
      error = 'File is empty';
    } else {
      error = '';
    }
    return error;
  };

  const importTrialVisitsData = async () => {
    setUploadProgress(true);
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      isImport: true
    };
    const importTrialVisitsData = await dispatch(getTrialVisit(payload)).then(unwrapResult);
    if (importTrialVisitsData?.data?.success) {
      if (
        importTrialVisitsData.data.trialVisits &&
        importTrialVisitsData.data.trialVisits.length > 0
      ) {
        setError('');
        const newData = importTrialVisitsData.data.trialVisits.map((item) => {
          return {
            visit: item.visit,
            visitnum: item.visitnum,
            visitdy: isNull(item.visitdy) ? '' : item.visitdy,
            armcd: item.armcd,
            arm: item.arm,
            epoch: item.epoch,
            tvpsttol: isNull(item.tvpsttol) ? '' : item.tvpsttol,
            tvpentol: isNull(item.tvpentol) ? '' : item.tvpentol,
            tvmsttol: isNull(item.tvmsttol) ? '' : item.tvmsttol,
            tvmentol: isNull(item.tvmentol) ? '' : item.tvmentol,
            tvrfpnt: item.tvrfpnt,
            tvgrpid: item.tvgrpid,
            tvclrid: item.tvclrid,
            tvblflg: item.tvblflg,
            tvenrl: item.tvenrl,
            tvstrl: item.tvstrl,
            tvupflg: item.tvupflg,
            id: uuidv4(),
            isImported: item.isImported
          };
        });
        const validationResult = trialVisitValidator(newData);
        setTrialVisitValidationResult(validationResult);
        setTrialVisitData(newData);
        setTrialVisitPreview(false);
        setReferenceData('trial-visit', newData);
        setReferenceData('trial-visit-validationResult', validationResult);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/trial-visit`, {
          state: { isImport: true }
        });
      } else {
        dispatch(
          showBanner({
            variant: 'warning',
            message: getMessage(MessageConstants.REFERENCE_DATA_TIMEPOINTS_IMPORT_ERROR_NO_DATA)
          })
        );
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: importTrialVisitsData?.data?.message }));
    }
    setUploadProgress(false);
  };

  const handleImportData = (isRefresh) => {
    if (isRefresh) {
      setConfirmAlert({
        enabled: true,
        title: getMessage(MessageConstants.REFERENCE_DATA_TRIALVISITS_IMPORT_WARNING_TITLE),
        message: getMessage(MessageConstants.REFERENCE_DATA_TIMEPOINTS_IMPORT_WARNING_DESC),
        variant: 'warning',
        buttonPrimaryLabel: 'Ok',
        buttonSecondaryLabel: getMessage(MessageConstants.WARNING_LABLE_CANCEL),
        onConfirm: () => {
          importTrialVisitsData();
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
    } else {
      importTrialVisitsData();
    }
  };

  const handleUpload = async (selectedFiles) => {
    setUploadProgress(true);
    const isFileTypeValid = checkFileType(selectedFiles[0].name, setError);
    if (isFileTypeValid) {
      const fileJSON = await convertExcelToJson(selectedFiles[0]);
      const headers = await getHeaders(selectedFiles[0]);
      const isError = fileValidation(fileJSON, headers);
      if (!isError) {
        let newFileArr = [];
        fileJSON.map((obj) => {
          const newObj = { ...trialVisitColumn[0], ...obj };
          var lowerObj = _.transform(newObj, function (result, val, key) {
            result[key.toLowerCase()] = val;
          });
          lowerObj.id = uuidv4();
          newFileArr.push(lowerObj);
        });
        const validationResult = trialVisitValidator(newFileArr);
        const errorCountFlag = validationResult.getErrorCount();
        errorCountFlag > 0 ? setErrorFlag(true) : setErrorFlag(false);
        setTrialVisitData(newFileArr);
        setTrialVisitValidationResult(validationResult);
        setError('');
        setTrialVisitUploadedFile([selectedFiles[0]]);
        setTrialVisitPreview(false);
        setReferenceData('trial-visit', newFileArr);
        setReferenceData('trial-visit-validationResult', validationResult);
        // handleEditPreview(true);
        navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/trial-visit`);
      } else {
        setError(isError);
        // props.configuration.setModuleValidation('trial-visits', false, isError);
        setTrialVisitUploadedFile([]);
        setTrialVisitPreview(true);
        setTrialVisitData([]);
      }
    }
    setUploadProgress(false);
  };

  const handleEditPreview = (isUploaded) => {
    if (isUploaded || trialVisitData) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/trial-visit`, {
        state: { isImport: true }
      });
    }
  };

  // const handleDelete = () => {
  //   setDeleteFile(false);
  //   setError('');
  //   setTrialVisitUploadedFile([]);
  //   setTrialVisitPreview(true);
  //   setTrialVisitData([]);
  // };

  // const openDeleteModal = () => {
  //   setDeleteFile(true);
  // };

  const confirmOnDelete = () => {
    setDeleteFile(true);
    trialVisitData.length
      ? setConfirmAlert({
          enabled: true,
          title: 'Are you sure, you want to delete the reference data?',
          message: 'Changes cannot be reverted',
          variant: 'error',
          buttonPrimaryLabel: 'Ok',
          buttonSecondaryLabel: 'Cancel',
          onConfirm: () => {
            deleteUploadedFile();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteUploadedFile();
  };

  const deleteUploadedFile = async () => {
    let payload = {
      domainCode: 'TV',
      mappingRuleVersionID: mappingRuleVersionID
    };
    const deletedFile = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deletedFile.data.success) {
      dispatch(showBanner({ variant: 'success', message: deletedFile.data.message }));
      setDeleteFile(false);
      setError('');
      setTrialVisitUploadedFile([]);
      setTrialVisitPreview(true);
      setTrialVisitData([]);
      setIsImportStudyLibrary(false);
      cardItems.push({ name: 'Time Points', module: TRIAL_VISITS });
      setCardItems(cardItems);
      const index = cards.findIndex((el) => el === TRIAL_VISITS);
      props.configuration.setCardVisibility(TRIAL_VISITS, false);
      cards.splice(index, 1);
    } else {
      dispatch(showBanner({ variant: 'error', message: deletedFile.data.message }));
    }
  };

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        refMsg={trialVisitMessage}
        required={props.required}
        setErrorFlag={errorFlag}
        cardTitle={props.title}
        handleImportData={handleImportData}
        isImportRunning={uploadProgress}
        isImportRequired={isQLABDataSourcesOnly}
        handleDownload={downloadSheet}
        isEditDisable={trialVisitPreview}
        handleEditPreview={() => handleEditPreview()}>
        <div style={{ margin: '0px 10px' }}>
          {uploadProgress && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={uploadProgress}
              value={isImportStudyLibrary ? [] : trialVisitUploadedFile}
              onUpload={handleUpload}
              // onFileDelete={openDeleteModal}
              // disabled={isFileUploadDisable}
              label="Upload Reference Data"
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
            />
            <div
              style={{
                display: 'flex',
                justifyContent: `${isImportStudyLibrary ? 'space-between' : 'end'}`
              }}>
              {isImportStudyLibrary && (
                <p
                  style={{
                    height: '24px',
                    color: 'black',
                    fontFamily: 'Proxima Nova',
                    fontSize: '14px',
                    fontWeight: 400,
                    letterSpacing: 0,
                    lineHeight: '24px'
                  }}>
                  {getMessage(MessageConstants.REFERENCE_DATA_TRIALVISITS_IMPORT_LABEL)}
                </p>
              )}
              <div
                style={{
                  marginTop: `${isImportStudyLibrary ? '0.7rem' : '-2rem'}`,
                  marginRight: `${isImportStudyLibrary ? '0' : '2rem'}`
                }}>
                {!trialVisitPreview && isQLABDataSourcesOnly && (
                  <Tooltip
                    variant="dark"
                    title={getMessage(
                      MessageConstants.REFERENCE_DATA_TRIALVISITS_IMPORT_SYNC_TOOLTIP
                    )}
                    placement="top">
                    <Sync
                      style={{
                        color: '#015FF1',
                        cursor: uploadProgress ? 'default' : 'pointer',
                        opacity: uploadProgress ? 0.3 : 1
                      }}
                      onClick={!uploadProgress ? () => handleImportData(true) : null}
                      data-testid="refresh-btn"
                    />
                  </Tooltip>
                )}
                {isImportStudyLibrary && (
                  <StatusCheck
                    style={{ color: '#00C221', width: '20px', height: '20px', marginLeft: '1rem' }}
                    fontSize="small"
                  />
                )}
              </div>
            </div>
          </div>

          <span style={errorStyle}>{error ? error : ''}</span>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={confirmAlert.buttonPrimaryLabel}
        // handlePrimaryAction={() => handleDelete()}
        handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
        buttonSecondardyLabel={confirmAlert.buttonSecondaryLabel}
        handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
      />
    </Grid>
  );
};

export default TrialVisits;
