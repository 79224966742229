import { StatusCheck } from 'apollo-react-icons';
import Box from 'apollo-react/components/Box';
import Divider from 'apollo-react/components/Divider';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import React from 'react';
import Loader from 'Components/Loader';
import StatusFailure from 'Images/status-failure.svg';
import CustomModal from 'Components/Modal/Modal';
import { useNavigate } from 'react-router-dom';

function SaveSDHDataProductModal({
  isSubmitModalShow,
  loading,
  dpStatus,
  classes,
  setIsSubmitModalShow
}) {
  const navigate = useNavigate();
  return (
    <CustomModal
      display={isSubmitModalShow}
      variant={'default'}
      title={
        <Box display={'flex'} flexDirection={'row'}>
          <Typography variant="h3">Data Product Creation Status</Typography>
        </Box>
      }
      message={
        <Typography style={{ whiteSpace: 'pre-line' }}>Data Product Creation Status</Typography>
      }
      handleClose={() => {
        setIsSubmitModalShow(false);
        navigate('/product-designer', { state: { loadData: true } });
      }}
      body={
        loading ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Loader />
          </div>
        ) : (
          <>
            <Box>
              <Divider variant="light" style={{ display: 'block', marginBottom: '2rem' }} />
              {dpStatus
                ?.filter((dp) => dp.status)
                .map((dp) => {
                  return (
                    <Box sx={{ boxShadow: '0px 4px 8px #f8f8f8' }} mt={2} mb={5} key={dp.key}>
                      <Typography
                        variant="h4"
                        style={{ borderBottom: '1px solid #dadada', padding: '0 1rem' }}>
                        {dp.name}
                      </Typography>
                      <Grid container spacing={0} mb={1} padding={2}>
                        <Grid item xs={4}>
                          <Typography variant="body2" className={classes.label}>
                            Status
                          </Typography>
                          <Typography variant="title"style={{display: 'inline-flex'}}>
                            {dp.status === 'Successful' ? (
                              <>
                                {dp.status}
                                <StatusCheck
                                  style={{ color: '#00C221', width: '20px', height: '20px', marginLeft: '2px', marginTop: '-2px' }}
                                />
                              </>
                            ) : (
                              <>
                                {dp.status}
                                <img
                                  src={StatusFailure}
                                  className={classes.statusCheck}
                                  style={{ color: '#E20000', marginLeft: '2px' }}
                                />
                              </>
                            )}
                          </Typography>
                        </Grid>

                        {dp.reasonOfFailure && (
                          <Grid item xs={8}>
                            <Typography variant="body2" className={classes.label}>
                              Reason of Failure
                            </Typography>
                            <Typography variant="title" className={classes.labelDesc}>
                              {dp.reasonOfFailure}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  );
                })}
            </Box>
          </>
        )
      }
      width={classes.successModal}
      hideButtons
      disableBackdropClick></CustomModal>
  );
}

export default SaveSDHDataProductModal;
