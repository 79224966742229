import { ChevronDown, ChevronRight } from 'apollo-react-icons';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import Radio from 'apollo-react/components/Radio';
import Table from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import React, { useState } from 'react';
import Card from 'apollo-react/components/Card';
import Box from 'apollo-react/components/Box';
import Typography from 'apollo-react/components/Typography';
import { neutral7, neutral8 } from 'apollo-react/colors';
import { makeStyles } from '@mui/styles';
import { nonEcrfStyle } from '../NonECRSFStyle';

const DetailRow = ({ row }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      style={{ backgroundColor: '#f8f9fb', width: '100%', padding: '0.5rem 2rem' }}>
      <Box
        style={{
          minWidth: '300px',
          marginRight: '2rem'
        }}>
        <Typography style={{ fontWeight: 500, color: neutral8 }}>{'Library ID'}</Typography>
        <Typography style={{ color: neutral7 }} variant="body2">
          {row.libraryID}
        </Typography>
      </Box>
      <Box
        style={{
          minWidth: '300px',
          marginRight: '1rem'
        }}>
        <Typography style={{ fontWeight: 500, color: neutral8 }}>{'Audit Type'}</Typography>
        <Typography style={{ color: neutral7 }} variant="body2">
          {row.auditType}
        </Typography>
      </Box>
    </Box>
  );
};

function TargetDataTable({ targetLibraryDataTable, targetRowSelected }) {
  const useStyles = makeStyles(nonEcrfStyle.targetTable);
  const classes = useStyles();

  const [expandedRows, setExpandedRows] = useState([]);
  const ActionCell = ({ row }) => {
    return (
      <div style={{ marginBottom: '15px' }}>
        <Radio
          key={row.libraryID}
          value={row.libraryID}
          checked={targetRowSelected}
          onChange={() => row.handleSelectRow(row.libraryID)}
        />
      </div>
    );
  };

  const ExpandCell = ({ row }) => {
    const { libraryID, handleToggleRow, expanded } = row;
    return (
      <div style={{ width: 25, display: 'contents', justifyContent: 'end' }}>
        <Tooltip title={expanded ? 'Collapse' : 'Expand'} disableFocusListener>
          <IconButton libraryID="expand" size="small" onClick={() => handleToggleRow(libraryID)}>
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      accessor: 'action',
      customCell: ActionCell,
      width: 40
    },
    {
      accessor: 'expand',
      customCell: ExpandCell
    },
    {
      header: 'Category',
      accessor: 'category',
      fixedWidth: false
    },
    {
      header: 'Type',
      accessor: 'libraryType',
      fixedWidth: false
    },
    {
      header: 'Name',
      accessor: 'libraryName',
      fixedWidth: false
    },
    {
      header: 'Description',
      accessor: 'description',
      fixedWidth: false
    },
    {
      header: 'Version',
      accessor: 'version',
      fixedWidth: false
    },
    {
      header: 'Updated User',
      accessor: 'createdUserid',
      fixedWidth: false
    },
    {
      header: 'Last updated Date',
      accessor: 'createdDate',
      fixedWidth: false
    }
  ];

  const handleToggleRow = (rowId) => {
    setExpandedRows((expandedRows) =>
      expandedRows.includes(rowId)
        ? expandedRows.filter((id) => id !== rowId)
        : [...expandedRows, rowId]
    );
  };

  const EmptyTableTypographyStyleTitle1 = {
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '22px',
    color: '#595959'
  };

  const EmptyTableComponent = () => (
    <>
      <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
        No Target Data displayed 
      </Typography>
    </>
  );

  const mapRows = () => {
    return targetLibraryDataTable?.length > 0
      ? targetLibraryDataTable?.map((row) => {
          const rowdata = {
            ...row,
            expanded: expandedRows.includes(row.libraryID) ? true : false,
            handleToggleRow
          };

          return rowdata;
        })
      : [];
  };

  return (
    <Grid my={2}>
      <Card>
        <Table
          classes={classes}
          title="Target Data Model"
          columns={columns}
          rows={mapRows()}
          ExpandableComponent={DetailRow}
          hidePagination
          emptyProps={{
            text: <EmptyTableComponent />
          }}
          defaultRowsPerPage={1}
        />
      </Card>
    </Grid>
  );
}

export default TargetDataTable;
