/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// eslint-disable-next-line

const API_URL = process.env.REACT_APP_API_URL;

export const GetProductDataSources = createAsyncThunk(
  'RuleEditor/GetProductDataSources',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetProductDataSources`, payload);
  }
);

export const GetProductDataSourcesByMappingRuleVersionID = createAsyncThunk(
  'RuleEditor/GetProductDataSourcesMasteringRuleVersionID',
  async (mappingRuleVersionID) => {
    return await axios.get(
      `${API_URL}/api/RuleEditor/GetProductDataSourcesByMappingRuleID?MappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const SaveDataSources = createAsyncThunk('RuleEditor/SaveDataSources', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/SaveRuleProductDataSources`, payload);
});

// const responsedataMock = {
//   "functionData": [
//       {
//           "functionId": "562af287-3c04-4554-b7f0-461dc5124f5f",
//           "moduleName": "add-non-ecrf-data-product",
//           "displayName": "Non-eCRF Data Product",
//           "order": 1,
//           "isRequired": false,
//           "leaf": false
//       },
//     {
//       "functionId": "562af287-3c04-4554-b7f0-461dc5124f5f",
//       "moduleName": "sdtm-data-product",
//       "displayName": "SDTM Data Product",
//       "order": 2,
//       "isRequired": false,
//       "leaf": false
//   },
//       {
//           "functionId": "472384ed-33eb-48a5-84d8-8047e6e84e00",
//           "moduleName": "preview-and-create-sdh-dpd",
//           "displayName": "Preview and Create",
//           "order": 3,
//           "isRequired": true,
//           "leaf": false
//       }
//   ],
//   "referenceData": [],
//   "libraryType": "SDHDataProduct",
//   "success": true,
//   "message": "Success",
//   "errorCode": 0
// }

export const GetWorkflowModel = createAsyncThunk('RuleEditor/GetWorkflowModel', async (payload) => {
  const response = await axios.get(
    `${API_URL}/api/RuleEditor/GetWorkFlowModel?LibraryType=${payload}`
  );
  if (response && response.data && response.data.success) {
    // const updatedData = {...responsedataMock}
    // return updatedData;
    return response.data;
  } else {
    return false;
  }
});
