import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from 'apollo-react/components/Grid';
import { useDispatch } from 'react-redux';
import Button from 'apollo-react/components/Button/Button';
import StudyInfo from 'Components/SourceStudyDisplay/StudyDisplayInfo';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Box from 'apollo-react/components/Box';
import TextField from 'apollo-react/components/TextField';
import { saveCloneDataProduct } from 'Redux/Service/CloneDataProductService';
import { unwrapResult } from '@reduxjs/toolkit';
import { Title, StudyInfoWrapper } from '../../Common';
import Cookies from 'js-cookie';

function CustomizeCreateDataProducts() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [description, setDescription] = useState('');
  const [productMnemonic, setProductMnemonic] = useState('');
  const [error, setError] = useState('')
  const [hasDuplicateMnemonic, setHasDuplicateMnemonic] = useState('');
  const productMnemonicPre = location?.state?.selectedSourceStudy?.productMnemonic?.value ? location?.state?.selectedSourceStudy?.productMnemonic?.value : "xyz";
  const format = /^[a-zA-Z0-9\\ \\]+$/;

  const handleDescription = (e) => {
    setDescription(e.target.value);
  }
  const handleProductMnemonic = (e) => {
    setProductMnemonic(e.target.value);
  }
  useEffect(() => {
    if (!productMnemonic) {
      setError('Product Mnemonic required');
    } else if (!format.test(productMnemonic)) {
      setError('Only alphanumeric with spaces are allowed');
    }
    else {
      setError('');
    }
    setHasDuplicateMnemonic(productMnemonic === productMnemonicPre);
  }, [productMnemonic]);
  useEffect(() => {
    if (hasDuplicateMnemonic) {
      setError('Duplicate Product Mnemonic');
    }
    else {
      setError('');
    }
  }, [hasDuplicateMnemonic])
  const saveCloneData = async () => {
    const payload = {
      "productMnemonic": productMnemonic,
      "description": description,
      "mappingRuleVersionID": location?.state?.mappingRuleVersionId,
      "protocolName": location?.state?.protocolNumber,
      "houseKeepColumns": {
        "iqCreateDate": new Date(),
        "iqUpdateDate": new Date(),
        "iqCreatedBy": Cookies.get('user.id'),
        "iqUpdatedBy": Cookies.get('user.id'),
        "iqAuditType": "INSERT",
        "iqAuditDate": new Date(),
        "iqActiveFlag": true
      }
    };
    const response = await dispatch(saveCloneDataProduct(payload)).then(unwrapResult);
    if (response?.data?.success) {
      dispatch(
        showBanner({ variant: 'success', message: response?.data?.message, propagate: true })
      )
      navigate('/product-designer', { state: { loadData: true } });
    }
    else {
      dispatch(
        showBanner({ variant: 'error', message: response?.data?.message })
      )
    }
    console.log("HHHHHH", response);
  }
  const handleSubmit = () => {
    if (productMnemonic && !hasDuplicateMnemonic) {
      saveCloneData();
    }
    else {
      setError('Product Mnemonic required');
    }
  }
  useEffect(() => {
    // const listener = async () => {
    //   alert('callback called on next');
    //   await dispatch(saveCloneDataProduct({
    //     "productMnemonic": "string",
    //     "description": "string",
    //     "sourceMappingRuleVersionID": "string",
    //     "sourceProtocolName": "string",
    //     "targetProtocolName": "string",
    //     "houseKeepColumns": {
    //       "iqCreateDate": "2023-05-30T13:07:17.888Z",
    //       "iqUpdateDate": "2023-05-30T13:07:17.888Z",
    //       "iqCreatedBy": "string",
    //       "iqUpdatedBy": "string",
    //       "iqAuditType": "string",
    //       "iqAuditDate": "2023-05-30T13:07:17.888Z",
    //       "iqActiveFlag": true
    //     }
    //   })).then(unwrapResult)
    // };
    // addEventListener(listener);
    // return () => {
    //   removeEventListener(listener);
    // };
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Title>
            Customize & Create Data Product
          </Title>
        </Grid>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo productInfo={location?.state?.selectedSourceStudy} label={'Source Study Information.'} />
        </StudyInfoWrapper>
        <StudyInfoWrapper item xs={12}>
          <StudyInfo productInfo={location?.state?.selectedDataProduct} label={'Source Data Product & Mapping Spec Information'} />
        </StudyInfoWrapper>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              id="name"
              data-testid="Product-Mnemonic-input"
              required
              label="Product Mnemonic"
              name="name"
              fullWidth
              onChange={handleProductMnemonic}
              value={productMnemonic}
              helperText={error ? <span style={{ color: '#e20000', fontSize: '13px' }}>{error}</span> : null}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <TextField
              onChange={handleDescription}
              value={description}
              label="Description (Optional)"
              placeholder="Placeholder"
              sizeAdjustable
              minHeight={150}
              fullWidth
            />
          </Grid>
        </Grid>
        <Button variant="primary" style={{ marginRight: 10, float: 'right' }} onClick={handleSubmit} size="small">
          Finish
        </Button>
      </Box>
    </>
  );
}

export default CustomizeCreateDataProducts;
