import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import {
  GetRuleExpressionMethodsAndConstants
} from 'Redux/Service/MasteringRuleService';
import { getMethodsList, getRuleList } from '../Utils/DomainUtils';
import { useDispatch } from 'react-redux';
import Box from 'apollo-react/components/Box';
import Select from 'apollo-react/components/Select';
import { GetRuleExpressionTemplates } from 'Redux/Service/DomainRuleService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import MenuItem from 'apollo-react/components/MenuItem';
import { makeStyles } from '@mui/styles';
import { methodsAndConstantsMapping } from 'Pages/ProductDesigner/Components/RuleEditor/MethodandConstantMapping';

const useStyles = makeStyles({
  select: {
    '& div[class*="makeStyles-hint-"] ': {
      fontWeight: '500'
    },
    '& div [class*="MuiSelect-icon"]': {
      fontWeight: '500'
    },
    margin: '8px 5px 0px 5px'
  },
  modal: {
    maxWidth: 450
  }
});

function PreviewVariableSelection({
  variablesList,
  caretPosition,
  setCaretPosition,
  setVariablesList,
  groupedVariableExpressions,
  selectedDomain,
  sourceFormItems
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [methodsList, setMethodsList] = useState([]);
  const [expressionList, setExpressionList] = useState([]);


  const fetchMethodsAndConstants = useCallback(async () => {
    const methodsAndConstantsResponse = await dispatch(GetRuleExpressionMethodsAndConstants()).then(
      unwrapResult
    );
    const method = getMethodsList(methodsAndConstantsResponse);
    method === false
      ? dispatch(
          showBanner({ variant: 'error', message: methodsAndConstantsResponse?.data?.message })
        )
      : setMethodsList(method.methods);  
  }, [dispatch]);

  const fetchRuleExpressionTemplates = useCallback(async () => {
    const expressionTemplatesResponse = await dispatch(GetRuleExpressionTemplates(true)).then(
      unwrapResult
    );
    const ruleExp = getRuleList(expressionTemplatesResponse);
    ruleExp === false
      ? dispatch(
          showBanner({ variant: 'error', message: expressionTemplatesResponse?.data?.message })
        )
      : setExpressionList(ruleExp);
  }, [dispatch]);

  useEffect(() => {
    fetchMethodsAndConstants();
    fetchRuleExpressionTemplates();
  }, [fetchMethodsAndConstants, fetchRuleExpressionTemplates]);

  const handleSelect = (e, type) => {
    const { position, id, rulesetIndex, inputIndex } = caretPosition;
    if (!id || rulesetIndex === -1 || inputIndex === -1) return;

    let lastSelectedValue =
      groupedVariableExpressions[rulesetIndex]?.expressions[inputIndex]?.expression;
    if (lastSelectedValue === undefined) return;

    lastSelectedValue = lastSelectedValue.split('');

    if (type === 'EXPRESSION_CONSTANT') {
      const value = e.target.value.split('');
      lastSelectedValue.splice(position, 0, ...value);
    } else if (type === 'METHOD_CONSTANT') {
      const value = methodsAndConstantsMapping[e.target.value];
      lastSelectedValue.splice(position, 0, ...value);
    } else if(type === 'ADD_SOURCE_ITEM_CONSTANT') {
      const value = e.target.value;
      lastSelectedValue.splice(position, 0, `[${selectedDomain}]`);
      lastSelectedValue.splice(lastSelectedValue.length, 0, `.[${value}]`);
    }else {
      return;
    }

    lastSelectedValue = lastSelectedValue.join('');
    setCaretPosition((prev) => ({ ...prev, position: lastSelectedValue.length }));

    const modifiedExps = [...variablesList];
    modifiedExps.forEach((expression) => {
      if (expression.id === id) {
        expression.expression = lastSelectedValue;
      }
    });
    setVariablesList(modifiedExps);
  };
  return (
    <Box marginTop="1.25rem" borderTop="1px solid #F6F7FB">
      <Box>
        <Box display={'flex'} flexDirection={'row'}>
          <Select data-testid="add-source-item" className={classes.select} placeholder={'Add Source Item'} size={'small'} onChange={(e) => handleSelect(e, 'ADD_SOURCE_ITEM_CONSTANT')}>
            {(sourceFormItems[selectedDomain]?.items || []).map((item, index) => {
              return (
                <MenuItem value={item} key={`SourceField-${index}`}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            data-testid="add-method"
            className={classes.select}
            onChange={(e) => handleSelect(e, 'METHOD_CONSTANT')}
            placeholder={'Add Method'}
            style={{ marginRight: '10px' }}
            size={'small'}>
            {methodsList.map((m) => (
              <MenuItem value={m} key={m}>
                {m}
              </MenuItem>
            ))}
          </Select>
          <Select
            data-testid="expression-constant"
            className={classes.select}
            onChange={(e) => handleSelect(e, 'EXPRESSION_CONSTANT')}
            placeholder={'Add Expression Template'}
            size={'small'}>
            {expressionList
              .filter((item) => item.templateExpressionType.includes('D'))
              .map((item, index) => (
                <MenuItem value={item.templateExpression} key={`Method-${index}`}>
                  {item.templateExpression}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

export default PreviewVariableSelection;
