import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';
import {
  setNextEnabled,
  setNonEcrfDataProduct,
} from 'Redux/Slice/SDHDataProductSlice';
import { useDispatch } from 'react-redux';

function AddNonECRFForm({ edcTrialOptions, formik }) {
  const dispatch = useDispatch();

  const onTrialChangeHandler = (e) => {
    const selectedTrial = edcTrialOptions.find((item) => item.edcTrialName === e.target.value);
    dispatch(setNextEnabled(false));
    dispatch(setNonEcrfDataProduct({selectedTrialName: selectedTrial, isRunAllExecuted: false}))
    return formik?.handleChange(e);
  };

  return (
    <Box mt={2}>
      <Grid xs={5}>
        <Select
          data-testid="trialName"
          name="trialName"
          id="trialName"
          label="Trial Name/Environment Name"
          fullWidth
          canDeselect={false}
          value={formik?.values?.trialName}
          onChange={(e) => onTrialChangeHandler(e)}
          placeholder="Select">
          {edcTrialOptions?.map((item) => (
            <MenuItem key={item.edcTrialName} value={item.edcTrialName}>
              {item.edcTrialName}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={5}>
        <TextField
          data-testid="description"
          id="description"
          label="Description (Optional)"
          name="description"
          multiline
          placeholder="Some Description"
          fullWidth
          value={formik.values.description}
          onChange={(e) => {
            dispatch(setNonEcrfDataProduct({description: e.target.value}))
            return formik.handleChange(e);
          }}
        />
      </Grid>
    </Box>
  );
}

export default AddNonECRFForm;
