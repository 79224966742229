/*eslint-disable*/
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetStudyLibraryType = createAsyncThunk(
  'addStudyLibrary/GetStudyLibraryType',
  async ({ protocolNumber }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/Protocol/GetStudyLibraryTypes?protocolName=${protocolNumber}`
      );
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const GetStudyLibrarySource = createAsyncThunk(
  'addStudyLibrary/GetStudyLibrarySource',
  async ({ protocolNumber, type }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/Protocol/GetStudyLibrarySources?protocolName=${protocolNumber}&libraryType=${type}`
      );
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const saveAddStudyLibrary = createAsyncThunk(
  'addStudyLibrary/saveAddStudyLibrary',
  async (payload) => {
    return await axios.post(`${API_URL}/api/protocol/SaveStudyLibrary`, payload);
  }
);

export const CustomSQLLineageDatasets = createAsyncThunk(
  'addStudyLibrary/CustomSQLLineageDatasets',
  async (payload) => {
    return await axios.post(`${API_URL}/api/Protocol/CustomSQLLineageDatasets`, payload);
  }
);

export const getDataSetTypes = createAsyncThunk('addStudyLibrary/getDataSetTypes', async () => {
  return await axios.get(`${API_URL}/api/Protocol/GetCDRDatasetTypes`);
});

export const GetStudyLibrarySourceByDataSetType = createAsyncThunk(
  'addStudyLibrary/GetStudyLibrarySourceByDataSetType',
  async ({ protocolNumber, type, datasetTypeId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/Protocol/GetStudyLibrarySources?protocolName=${protocolNumber}&libraryType=${type}&DataSetTypeID=${datasetTypeId}`
      );
      return response;
    } catch (error) {
      rejectWithValue([], error);
    }
  }
);

export const importQCODEDataSets = createAsyncThunk(
  'addStudyLibrary/importQCODEDataSets',
  async (protocolNumber) => {
    return await axios.post(
      `${API_URL}/api/Protocol/ImportQCODEDataSets?protocolName=${protocolNumber}`
    );
  }
);
