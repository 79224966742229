import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from 'apollo-react/components/Typography';
import Stepper from 'apollo-react/components/Stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import StepLabel from 'apollo-react/components/StepLabel';
import Step from 'apollo-react/components/Step';
import Button from 'apollo-react/components/Button';
import CustomModal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'apollo-react/components/Tooltip';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';
import { useLeafPathName } from 'Utils';
import { makeStyles } from '@mui/styles';
import { resetNonEcrfState, resetSDTMState, setNextEnabled } from 'Redux/Slice/SDHDataProductSlice';
import { CreateSDTMDataProduct, SaveNonEcrfDataproduct } from 'Redux/Service/DataProductsService';
import { unwrapResult } from '@reduxjs/toolkit';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Cookies from 'js-cookie';
import SaveSDHDataProductModal from './SaveSDHDataProductModal';

const useStyles = makeStyles({
  modal: {
    width: 450
  },
  successModal: {
    minWidth: '60%'
  },
  statusCheck: {
    height: '15px',
    width: '15px',
    color: '#00C221'
  },
  label: { color: '#595959', fontSize: '16px' },
  labelDesc: { color: '#03333', fontSize: '16px' }
});

const initialDataProducts = [
  {
    key: 'nonEcrfDataProduct',
    name: 'Non-eCRF Data Product',
    status: '',
    reasonOfFailure: null,
    loading: false
  },
  {
    key: 'qctmsDataProduct',
    name: 'QCTMS Data Product',
    status: '',
    reasonOfFailure: null,
    loading: false
  },
  {
    key: 'sdtmDataProduct',
    name: 'SDTM Data Product',
    status: '',
    reasonOfFailure: null,
    loading: false
  }
];

export default function SDHDataProductSideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [dpStatus, setDPStatus] = useState(initialDataProducts);
  const [cards, setCards] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [isSubmitModalShow, setIsSubmitModalShow] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const studyLibraryData = useSelector((state) => state.StudyLibraryData);
  const { protocol } = studyLibraryData;
  const userId = Cookies.get('user.id');

  const {
    nonEcrrfDataProduct = {},
    SDTMDataProduct = {},
    workFlowModel = [],
    nextEnabled = ''
  } = useSelector((state) => state.SDHDataProduct) || {};
  
  const {
    selectedTrialName = '',
    nonEcrfDatasets = [],
    productMnemonic: nonEcrfProductMnemonic = '',
    libraryName: nonEcrfLibraryName = '',
    targetLibrary: nonEcrfTargetLibrary = {},
    description: nonEcrfDescription = '',
    nonEcrfConfig = ''
  } = nonEcrrfDataProduct || {};

  const { sdtmConfig = '', description: sdtmDescription = '' } = SDTMDataProduct || {};

  const nextStepper = cards[activeStep + 1];
  const moduleName = useLeafPathName();

  useEffect(() => {
    if (workFlowModel?.length > 0) {
      const index = workFlowModel.findIndex((data) => {
        return data?.moduleName === moduleName;
      });
      setCards(workFlowModel);
      setActiveStep(index);
      if (!workFlowModel[index]?.isRequired) {
        dispatch(setNextEnabled(true));
      }
    }
  }, [workFlowModel, moduleName]);

  const modalButtonProps = [
    {
      label: 'Cancel',
      onClick: () => {}
    },
    {
      label: `Continue to ${nextStepper?.displayName}`,
      onClick: () => {}
    }
  ];

  const openModal = async () => {
    setConfirmAlert(true);
  };
  const handleReset = () => {
    setConfirmAlert(false);
    navigate('/product-designer');
  };

  const handleClose = () => {
    setConfirmAlert(false);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    navigate(`/product-designer/${workFlowModel[activeStep - 1]?.moduleName}`);
  };

  const updateDataProductStatus = (key, status, reasonOfFailure) => {
    setDPStatus((prevState) =>
      prevState.map((product) =>
        product.key === key ? { ...product, status, reasonOfFailure } : product
      )
    );
  };

  const saveNonEcrfLibraryHandler = async () => {
    setLoading(true);
    const nonEcrfDatasetTransformed = nonEcrfDatasets?.map((item) => ({
      datasetname: item.datasetName,
      columns: item.columns,
      query: item.sqlQuery
    }));
    const payload = {
      nonEcrfDataproduct: {
        protocolNumber: protocol?.protocolNumber,
        dataProductName: nonEcrfProductMnemonic,
        studyLibraryName: nonEcrfLibraryName,
        dataSource: LIBRARY_TYPES.CDISC_ODM,
        nonEcrfDatasets: nonEcrfDatasetTransformed,
        targetDataModelId: nonEcrfTargetLibrary.libraryID,
        targetDataModelVersion: nonEcrfTargetLibrary.version,
        targetDataModelType: nonEcrfTargetLibrary.libraryType,
        trailName: selectedTrialName.edcTrialName,
        isAzureEnv: selectedTrialName.isAzureEnv,
        dataBaseName: selectedTrialName.dataBaseName,
        studyId: selectedTrialName.edcStudyID,
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreatedBy: userId,
        iqUpdatedBy: userId,
        iqAuditType: 'INSERT',
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        description: nonEcrfDescription
      }
    };

    try {
      const { data } = await dispatch(SaveNonEcrfDataproduct(payload)).then(unwrapResult);
      if (data.success) {
        updateDataProductStatus('nonEcrfDataProduct', 'Successful', null);
        // dispatch(showBanner({ variant: 'success', message: data?.message }));
        dispatch(resetNonEcrfState());
      } else {
        updateDataProductStatus('nonEcrfDataProduct', 'Failed', data?.message);
        // dispatch(showBanner({ variant: 'error', message: data?.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({ variant: 'error', message: 'Something went wrong in SaveNonEcrfDataproduct' })
      );
    }
    setLoading(false);
  };

  const saveSdtmDataProduct = async () => {
    setLoading(true);
    const payload = {
      protocolNumber: protocol?.protocolNumber,
      description: sdtmDescription,
      iqCreateDate: new Date().toISOString(),
      iqUpdateDate: new Date().toISOString(),
      iqCreatedBy: userId,
      iqUpdatedBy: userId,
      iqAuditType: 'INSERT',
      iqAuditDate: new Date().toISOString(),
      iqActiveFlag: true
    };
    try {
      const { data } = await dispatch(CreateSDTMDataProduct(payload)).then(unwrapResult);
      if (data.success) {
        // dispatch(showBanner({ variant: 'success', message: data?.message }));
        dispatch(resetSDTMState());
        updateDataProductStatus('sdtmDataProduct', 'Successful', null);
      } else {
        updateDataProductStatus('sdtmDataProduct', 'Failed', data?.message);
        // dispatch(showBanner({ variant: 'error', message: data?.message }));
      }
    } catch (error) {
      dispatch(
        showBanner({ variant: 'error', message: 'Something went wrong in CreateSDTMDataProduct' })
      );
    }
    setLoading(false);
  };

  const handleNext = () => {
    const isSubmitPage = pathname.includes(workFlowModel[workFlowModel.length - 1]?.moduleName);
    if (isSubmitPage) {
      setIsSubmitModalShow(true);
      nonEcrfConfig && saveNonEcrfLibraryHandler();
      sdtmConfig && saveSdtmDataProduct();
    } else {
      navigate(`/product-designer/${workFlowModel[activeStep + 1]?.moduleName}`);
    }
  };

  const onStepClick = (moduleName) => {
    const index = cards.findIndex((card) => {
      return card.moduleName === moduleName;
    });
    if (activeStep > index) {
      navigate(`/product-designer/${workFlowModel[0]?.moduleName}`);
      setActiveStep(index);
      dispatch(setNextEnabled(false));
    }
  };

  return (
    <>
      <Box>
        <Box pt={3} pb={3} px={3} style={{ borderBottom: '1px solid #e9e9e9' }}>
          <Typography variant="h3" data-testid="rule">
            Workflow
          </Typography>
        </Box>
        <Box style={{ padding: '30px 20px' }}>
          <div style={{ maxWidth: 400 }}>
            <Stepper box activeStep={activeStep ? activeStep : 0}>
              {cards.map((card) => (
                <Step
                  style={{
                    padding: '1rem',
                    cursor: activeStep ? activeStep : 0 >= card.order && 'pointer'
                  }}
                  key={card.order}
                  onClick={() => onStepClick(card.moduleName)}>
                  <StepLabel>
                    {card.displayName}
                    {card.isRequired ? (
                      <></>
                    ) : (
                      <div style={{ fontWeight: '400', fontSize: '14px', color: '#595959' }}>
                        Optional
                      </div>
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button
                  flex={0.4}
                  variant="text"
                  size="small"
                  label="Back"
                  onClick={openModal}
                  style={{ paddingLeft: 0 }}
                  data-testid="cancel">
                  Cancel
                </Button>
                <Box display="flex" justifyContent="flex-end" flex={0.6}>
                  {activeStep !== 0 && (
                    <Button
                      variant="secondary"
                      size="small"
                      onClick={handleBack}
                      style={{ marginRight: '16px' }}
                      data-testid="back">
                      Back
                    </Button>
                  )}
                  <Tooltip title={!nextEnabled && 'Please select all mandatary'}>
                    <span>
                      <Button
                        variant="primary"
                        size="small"
                        onClick={handleNext}
                        data-testid="next"
                        disabled={!nextEnabled}>
                        {pathname.includes(workFlowModel[workFlowModel.length - 1]?.moduleName)
                          ? 'Submit'
                          : 'Next'}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      <CustomModal
        display={confirmAlert}
        title={'Leave Page?'}
        buttonPrimaryLabel={'Ok'}
        message={
          'Changes could not be saved if you leave this page, Would you still like to leave?'
        }
        handlePrimaryAction={() => handleReset()}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => handleClose()}
      />
      {openWarningModal && (
        <CustomModal
          display={openWarningModal}
          variant={'warning'}
          title={
            <Box display={'flex'} flexDirection={'row'}>
              <StatusExclamationIcon />
              <span style={{ marginLeft: '0.5rem' }}></span>
            </Box>
          }
          message={<Typography style={{ whiteSpace: 'pre-line' }}></Typography>}
          buttonProps={modalButtonProps}
          handleClose={() => {
            setOpenWarningModal(false);
          }}
          width={classes.modal}
        />
      )}

      {isSubmitModalShow && (
        <SaveSDHDataProductModal
          isSubmitModalShow={isSubmitModalShow}
          loading={loading}
          dpStatus={dpStatus}
          classes={classes}
          setIsSubmitModalShow={setIsSubmitModalShow}
        />
      )}
    </>
  );
}
