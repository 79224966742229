import { Box, Grid } from '@mui/material';
import { getAllFormsAndItems, getHtmlString, uuidv4 } from 'Utils';
import CustomeTextField from 'Components/CustomeTextField/CustomeTextField';
import Button from 'apollo-react/components/Button';
import ClipboardSearch from 'apollo-react-icons/ClipboardSearch';
import { makeStyles } from '@mui/styles';
import { CloseCircle, StatusNegative } from 'apollo-react-icons';
import IconButton from 'apollo-react/components/IconButton';
import { useState } from 'react';
import Modal from 'apollo-react/components/Modal';

const useStyles = makeStyles({
  row: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderBottom: '1px solid #4441',
    fontSize: '14px'
  },
  column: {
    padding: '1rem'
  },
  domainVariable: { fontWeight: '600' },
  expressionCol: {
    fontWeight: 'bold',
    fontFamily: 'Courier New',
    '&:not(:last-child)': {
      marginBottom: '10px'
    }
  },
  viewCodelist: {
    width: 'fit-content',
    marginTop: '1rem'
  },
  lineageCol: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    overflowWrap: 'break-word',
    '&>div': { width: '100%', color: '#595959', fontSize: '14px' }
  }
});

const VariableRow = (props) => {
  const {
    variable = '',
    expressions = [],
    isRulesetVariable,
    setCodelistEditorDisplay,
    isEditEnable,
    addExpressionHandler,
    deleteExpressionHandler,
    onExpressionInputHandler,
    handleCaretPositionChange,
    index,
    expressionValidationErrors,
    isDomainRulesEditable,
    lineageColumns
  } = props;
  const classes = useStyles();

  const handleViewCodelist = () => {
    setCodelistEditorDisplay({
      display: true,
      formItems: expressions.map((expRow) => getAllFormsAndItems(expRow.expression)).flat()
    });
  };

  const [showExpressionModal, setShowExpressionModal] = useState(false);
  const [expressionToDelete, setExpressionToDelete] = useState(null);
  const toggleModalHandler = () => {
    setShowExpressionModal(!showExpressionModal);
  };

  const addExpressionClickHandler = (expRow) => {
    const newExpression = {
      id: uuidv4(),
      domain: expRow.domain,
      expression: '',
      variable: expRow.variable,
      isRulesetVariable: false,
      isSaved: false
    };
    addExpressionHandler(newExpression);
  };

  const renderDeleteExpressionIcon = (expRow) => {
    return (
      !isEditEnable && (
        <Box display="flex" justifyContent="center" ml={1}>
          <IconButton
            size="small"
            data-testid="status-btn"
            disabled={expressions?.length <= 1}
            onClick={() => {
              setExpressionToDelete(expRow);
              toggleModalHandler();
            }}>
            <StatusNegative style={{ color: '#BD0100' }} />
          </IconButton>
        </Box>
      )
    );
  };

  const renderAddExpressionIcon = ({ expRow, inputIndex }) => {
    return (
      !isEditEnable &&
      inputIndex === expressions.length - 1 && (
        <Box display="flex" justifyContent="center">
          <IconButton
            size="small"
            data-testid="plus-btn"
            onClick={() => addExpressionClickHandler(expRow)}
            style={{ transform: 'rotate(45deg)', color: '#0768fd' }}>
            <CloseCircle style={{ color: '#0557D5' }} />
          </IconButton>
        </Box>
      )
    );
  };

  return (
    <>
      <Grid container className={classes.row}>
        <Grid xs={3} className={[classes.column, classes.domainVariable]}>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            {variable}
            {isRulesetVariable ? (
              <Button
                variant="secondary"
                onClick={handleViewCodelist}
                size={'small'}
                className={classes.viewCodelist}
                data-testid="save"
                icon={ClipboardSearch}>
                View Codelist
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Grid>

        <Grid xs={9} className={[classes.column]}>
          {expressions?.map((expRow, inputIndex) => (
            <Box key={expRow.id} display="flex" flexDirection="column" justifyContent="center">
              <Grid container>
                <Grid xs={!isDomainRulesEditable ? 6 : 12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    className={classes.expressionCol}
                    mt={inputIndex > 0 ? '10px' : 0}>
                    <Box width="100%">
                      <CustomeTextField
                        id="expression"
                        name="expression"
                        data-testid="inputField"
                        disabled={isEditEnable}
                        customProps={{
                          style: {
                            height: '100%',
                            backgroundColor: !isEditEnable ? 'white' : '#F2F2F2',
                            border: '1px solid #D9D9D9',
                            borderRadius: '4px',
                            whiteSpace: 'pre-line',
                            marginBottom: '0'
                          },
                          placeholder: 'abc'
                        }}
                        onCaretPositionchange={(pos) =>
                          handleCaretPositionChange(pos, expRow?.id, index, inputIndex)
                        }
                        onChange={(value) => onExpressionInputHandler(expRow, value)}
                        value={expRow.expression}
                        error={Boolean(expressionValidationErrors[`${index}-${inputIndex}`])}
                        helperText={expressionValidationErrors[
                          `${index}-${inputIndex}`
                        ]?.message.toUpperCase()}>
                        {getHtmlString(`${expRow.expression}`, [], '', 3)}
                      </CustomeTextField>
                    </Box>
                    {isDomainRulesEditable && renderDeleteExpressionIcon(expRow)}
                  </Box>
                  {isDomainRulesEditable && renderAddExpressionIcon({ expRow, inputIndex })}
                </Grid>
                {!isDomainRulesEditable && (
                  <Grid xs={6} className={classes.lineageCol}>
                    <Box>{lineageColumns?.[expRow.expression]?.replaceAll(',', ', ') || ''}</Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Modal
        open={showExpressionModal}
        variant="warning"
        onClose={toggleModalHandler}
        title="Delete Expression?"
        message="Are you sure you want to delete this expression?"
        buttonProps={[
          { label: 'Cancel', onClick: toggleModalHandler },
          {
            label: 'Delete',
            onClick: () => {
              deleteExpressionHandler(expressionToDelete);
              toggleModalHandler();
            }
          }
        ]}
        id="warning"
      />
    </>
  );
};

export default VariableRow;
