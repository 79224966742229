/* eslint-disable */
import Typography from 'apollo-react/components/Typography';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Download from 'apollo-react-icons/Download';
import Table from 'apollo-react/components/Table';
import TerminalIcon from '@mui/icons-material/Terminal';
import Grid from 'apollo-react/components/Grid';
import DataVizCard from 'apollo-react/components/DataVizCard';
import React from 'react';
import Box from 'apollo-react/components/Box';
import { neutral3, red } from 'apollo-react/colors';
import { jsonToExcel } from 'Utils';
import Divider from 'apollo-react/components/Divider';
import { Notification } from 'Components/Common/Notification';

const useStyles = makeStyles({
  emptyTableHeader: {
    padding: '16px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    border: '1px solid #4442',
    alignItems: 'center',
    backgroundColor: '#ffffff'
  },
  customTableHeader: {
    alignItems: 'center',
    padding: '0px',
    height: '64px'
  },
  root: {
    '& .MuiToolbar-root': {
      height: 'auto',
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
      border: '1px solid #4442',
      '&> div:nth-child(1)': {
        width: '100%'
      }
    }
  },
  dataVizCard: {
    borderRadius: '0px 0px 4px 4px',
    '& .MuiCardHeader-root': {
      height: '0px',
      padding: '0px'
    }
  },
  fw400: { fontWeight: '400' }
});

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959',
  height: 'auto'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '5px',
  height: 'auto'
};

const CustomButtonHeader = ({ error, queryResult }) => {
  const classes = useStyles();
  const handleDownload = () => {
    jsonToExcel(queryResult, 'QueryDetails.xlsx', 'queryDetails');
  };
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      className={queryResult ? classes.customTableHeader : classes.emptyTableHeader}>
      <Grid item xs={6}>
        <Typography variant="title1" style={{ fontSize: '16px' }}>
          Query Output{!error && <span className={classes.fw400}> (20 results max)</span>}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.2rem' }}>
          <Button
            size="small"
            variant="secondary"
            icon={<Download />}
            disabled={error || !queryResult?.length}
            onClick={handleDownload}
            style={{ marginRight: 15 }}>
            Download CSV
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const QueryOutput = ({
  queryColumns,
  queryResult,
  queryError,
  queryHasWarning,
  datasetWithLineageColumnBeingRemoved
}) => {
  const classes = useStyles();

  const handleDownload = () => {
    jsonToExcel(queryResult, 'QueryDetails.xlsx', 'queryDetails');
  };

  if (queryError) {
    return (
      <Box pt={0} pl={2.5} pr={3}>
        <CustomButtonHeader error={queryError} />
        <Box
          component={'div'}
          sx={{
            whiteSpace: 'pre-line',
            fontFamily: 'Menlo, Courier New, Monospace',
            px: '22px',
            py: '10px',
            backgroundColor: neutral3,
            color: red
          }}
          dangerouslySetInnerHTML={{ __html: queryError }}
        />
      </Box>
    );
  } else if (!queryResult) {
    return (
      <Box pt={0} pl={2.5} pr={3}>
        <CustomButtonHeader />
        <DataVizCard className={classes.dataVizCard}>
          <TerminalIcon fontSize="large" style={{ margin: '10px 0px', cursor: 'pointer' }} />
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            Query results will be displayed here
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Click the run button to execute the query
          </Typography>
        </DataVizCard>
      </Box>
    );
  } else {
    return (
      <Box pt={0} pl={2.5} pr={3} flex flexDirection={'column'}>
        <Table
          classes={{ root: classes.root }}
          title={
            <Grid
              container
              xs={12}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Grid xs={6}>
                <span>
                  Query Output
                  {!queryError && <span className={classes.fw400}> (20 results max)</span>}
                </span>
              </Grid>
              <Grid
                xs={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                  height: '2rem'
                }}>
                <Button
                  size="small"
                  variant="secondary"
                  icon={<Download />}
                  disabled={queryError || !queryResult?.length}
                  onClick={handleDownload}
                  style={{ marginRight: 15 }}>
                  Download CSV
                </Button>
              </Grid>
            </Grid>
          }
          columns={queryColumns?.split(',').map((column) => ({
            header: column,
            accessor: column
          }))}
          subtitle={
            <>
              {queryHasWarning?.length > 0 || !!datasetWithLineageColumnBeingRemoved ? (
                <Grid style={{ width: '100%' }}>
                  <Divider type="dark" style={{ marginBottom: '10px', marginTop: '15px' }} />
                </Grid>
              ) : null}
              <Notification
                hide={!queryHasWarning?.length}
                variant={'warning'}
                header={'Discrepancies Detected'}
                message={queryHasWarning}
                style={{
                  marginBottom: !datasetWithLineageColumnBeingRemoved ? '0px' : '10px',
                  marginTop: '5px'
                }}
              />
              <Notification
                hide={!datasetWithLineageColumnBeingRemoved}
                variant={'warning'}
                message={`The data set ${datasetWithLineageColumnBeingRemoved} is no longer part of source selection, but it's columns which were referenced in the lineage would be also removed. Please check for potential impacts.`}
                style={{ margin: '0px' }}
              />
            </>
          }
          rows={queryResult?.slice(0, 20)}
          hasScroll
          maxHeight={650}
          hidePagination
        />
      </Box>
    );
  }
};

export default QueryOutput;
