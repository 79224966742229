/* eslint-disable*/
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Table, {
  createStringSearchFilter,
  createSelectFilterComponent,
  compareStrings
} from 'apollo-react/components/Table';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomSQLLineageDatasetColumns } from 'Redux/Service/PreSQLEditorService';
import { unwrapResult } from '@reduxjs/toolkit';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { CustomAutoComplete } from 'Components/Common/CustomAutoComplete';

const useStyles = makeStyles({
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  }
});

const createSearchFilter = (accessor) => {
  return function (row, filters) {
    if (!filters[accessor]) {
      return true;
    }
    if (!row[accessor]) {
      return false;
    }
    const rowVal = row[accessor]
      ?.map((item) => `${item.name}.${item.label}`)
      ?.join(' ')
      ?.toUpperCase();
    const filterVal = filters[accessor]?.toUpperCase();
    if (!rowVal && 'NO LINEAGE'.includes(filterVal)) {
      return true;
    }
    return rowVal.includes(filterVal);
  };
};

const createSelectDropdownFilter = (accessor, list) => {
  return function (row, filters) {
    if (!filters[accessor].length || filters[accessor].length === list.length) {
      return true;
    } else {
      let rowVal = filters[accessor].includes(row[accessor]);
      if (rowVal) return true;
    }
  };
};

const selectLineageField = ({ row }) => {
  return (
    <CustomAutoComplete
      entries={row?.lineageColumn}
      selectedValues={row?.lineageValue}
      handleChange={row?.handleLineageColumnChange}
      isCountRequired
      placeholder={row?.lineageValue.length ? '' : 'No Lineage'}
      isChipRequireNameAndLabel
    />
  );
};

const DatasetColumnsLineage = (props) => {
  const { isTestFlow, selectedLineageValues, setSelectedLineageValues } = props;
  const { studyObj, dataSourceTrailValues } = useSelector((state) => state.AddStudyLibrary);
  const selectedProtocol = useSelector((state) => state.StudyLibraryData.protocol);
  const [rowData, setRowData] = useState([]);
  const [selectedDataSet, setSelecteDataSet] = useState([]);
  const [distinctDatasetNames, setDistinctDatasetNames] = useState([]); // for filtering Data Set Name Column
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dataSet } = studyObj;

  const columns = [
    {
      header: 'Data Set Name',
      accessor: 'datasetName',
      sortFunction: compareStrings,
      filterFunction: createSelectDropdownFilter('datasetName', distinctDatasetNames),
      filterComponent: createSelectFilterComponent(distinctDatasetNames, {
        size: 'small',
        multiple: true
      }),
      fixedWidth: false
    },
    {
      header: 'Columns',
      accessor: 'queryColumns',
      sortFunction: compareStrings,
      filterComponent: TextFieldFilter,
      filterFunction: createStringSearchFilter('queryColumns'),
      fixedWidth: false
    },
    {
      header: 'Lineage',
      accessor: 'lineageValue',
      fixedWidth: false,
      customCell: selectLineageField,
      filterComponent: TextFieldFilter,
      filterFunction: createSearchFilter('lineageValue'),
      width: '70%'
    }
  ];

  // for filtering it the column lineage value on the basis of dataset name
  const handleDataSetFilter = (datasetColumns, dataSetCollection) => {
    const columnsMap = datasetColumns?.reduce((map, dataset) => {
      map[dataset.dataSetName] = dataset.dataSetColumns;
      return map;
    }, {});

    const filteredValue = dataSetCollection?.map((collection) => {
      const value = collection.dataSetLineage?.map((lineage) => {
        return {
          dataSetName: lineage,
          dataSetColumns: columnsMap[lineage] || []
        };
      });

      const treeEntry = value?.flatMap((item) => {
        return item.dataSetColumns?.length > 0
          ? item.dataSetColumns?.map((column) => ({ name: item.dataSetName, label: column }))
          : [{ name: item.dataSetName, label: '' }];
      });

      return {
        datasetName: collection?.datasetName,
        value: value,
        treeEntry: treeEntry
      };
    });

    return filteredValue;
  };

  const handleLineageColumnChange = (datasetName, queryColumn) => (event, newValue) => {
    setSelectedLineageValues((prevState) => ({
      ...prevState,
      [`${datasetName}-${queryColumn}`]: newValue
    }));
  };

  const getCustomSQLLineageDatasetColumns = async () => {
    const selectedDatasetLineage = [
      ...new Set(
        dataSet
          ?.filter((item) => item.dataSetLineage?.length)
          .flatMap((item) => item.dataSetLineage)
      )
    ];
    const payload = {
      protocolNumber: selectedProtocol?.protocolNumber,
      dataSource: dataSourceTrailValues?.selectedDataSource,
      isTestFlow: isTestFlow,
      selectedDatasets: selectedDatasetLineage
    };
    const response = await dispatch(GetCustomSQLLineageDatasetColumns(payload)).then(unwrapResult);
    if (response?.data?.success) {
      const { datasetColumns } = response.data;
      setSelecteDataSet(datasetColumns);
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
  };

  useEffect(() => {
    getCustomSQLLineageDatasetColumns();
  }, []);

  useEffect(() => {
    let dataSetRecord = [];
    let dataSetNames = [];
    const filteredValue = handleDataSetFilter(selectedDataSet, dataSet);

    dataSet?.forEach((data) => {
      const { datasetName, queryColumns, isQueryActive } = data;
      if (isQueryActive) {
        const columns = (queryColumns || '').split(',').map((item) => item.trim());
        const lineageColumn =
          filteredValue
            .filter((entry) =>
              entry.value?.some((item) => item.dataSetColumns && item.dataSetColumns.length > 0)
            )
            .find((fv) => fv.datasetName === datasetName)?.treeEntry || [];
        columns.forEach((column) => {
          dataSetRecord.push({
            datasetName: datasetName.toString(),
            queryColumns: column.toString(),
            lineageColumn: lineageColumn,
            lineageValue: selectedLineageValues[`${datasetName}-${column}`] || []
          });
        });
        if (!dataSetNames.includes(datasetName.toString())) {
          dataSetNames.push(datasetName.toString());
        }
      }
    });
    setRowData(dataSetRecord);
    setDistinctDatasetNames(dataSetNames);
  }, [studyObj?.dataSet, selectedDataSet?.length, selectedLineageValues]);

  const rows = useMemo(() => {
    return rowData.map((row) => {
      return {
        ...row,
        lineageValue: selectedLineageValues[`${row.datasetName}-${row.queryColumns}`] || [],
        handleLineageColumnChange: handleLineageColumnChange(row.datasetName, row.queryColumns)
      };
    });
  }, [rowData, selectedLineageValues]);

  return (
    <Box mt={2} className={classes.tableHorizontalOverflow}>
      <Table
        classes={classes.tableHorizontalOverflow}
        title={'Dataset Column Settings'}
        subtitle={`${rows?.length} Variables`}
        columns={columns}
        rows={rows}
        initialSortedColumn="datasetName"
        initialSortOrder="asc"
        hasScroll
        maxHeight={650}
        rowsPerPageOptions={[10, 20, 50, 100, 'All']}
        tablePaginationProps={{
          truncate: true
        }}
        defaultRowsPerPage={10}
      />
    </Box>
  );
};

export default DatasetColumnsLineage;
