import { makeStyles } from '@mui/styles';
import React from 'react';
import { nonEcrfStyle } from './NonECRSFStyle';
import { useSelector } from 'react-redux';
import useDisplayName from 'Utils/useDisplayName';
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Switch from 'apollo-react/components/Switch';
import { ADD_NON_ECRF_DATA_PRODUCT } from 'Utils/Constants';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';

function NonECRFDetailsStrip({ nonEcrfConfigEnabled, configureDPToggleHandler }) {
  const useStyles = makeStyles(nonEcrfStyle.nonEcrfForm);
  const classes = useStyles();

  const getDisplayName = useDisplayName();
  const { nonEcrrfDataProduct: { productMnemonic, libraryName } = {}, sdhDataProductConfig = {} } =
    useSelector((state) => state.SDHDataProduct);

  return (
    <Box mt={2}>
      <Grid container spacing={0} mt={1}>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.label}>
            Configure Data Product
          </Typography>
          <Typography variant="title" className={classes.labelDesc}>
            <Switch
              disabled={sdhDataProductConfig?.[ADD_NON_ECRF_DATA_PRODUCT]}
              checked={nonEcrfConfigEnabled}
              onChange={configureDPToggleHandler}
            />
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" className={classes.label}>
            Product Mnemonic
          </Typography>
          <Typography variant="title" className={classes.labelDesc}>
            {productMnemonic}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.label}>
            Library Name
          </Typography>
          <Typography variant="title" className={classes.labelDesc}>
            {libraryName}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" className={classes.label}>
            Data Source
          </Typography>
          <Typography variant="title" className={classes.labelDesc}>
            {getDisplayName(LIBRARY_TYPES.CDISC_ODM)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NonECRFDetailsStrip;
