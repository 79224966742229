import Footer from 'apollo-react/components/Footer';

const fixedFooter = {
  position: 'relative',
  bottom: '0px',
  padding: '10px 24px'
};

export const Foot = ({ width }) => {
  return (
    <Footer
      buttonProps={[
        {
          label: 'Terms of Use',
          href: 'https://www.iqvia.com/about-us/terms-of-use',
          target: '_blank'
        },
        {
          label: `Privacy Policy`,
          href: 'https://www.iqvia.com/about-us/privacy/privacy-policy',
          target: '_blank'
        }
      ]}
      languagePickerProps={{
        noDeselect: true,
        defaultValue: 'en',
        placeholder: 'Language',
        menuItems: [
          {
            text: 'English',
            value: 'en'
          },
          {
            text: 'Spanish',
            value: 'sp'
          },
          {
            text: 'Portuguese',
            value: 'pt'
          }
        ]
      }}
      maxWidth={width ? width : 1600}
      style={fixedFooter}>
      <span
        style={{
          color: '#595959',
          fontFamily: 'Proxima Nova',
          fontSize: '14px',
          textAlign: 'center',
          marginLeft: '150px',
          marginTop: '6px'
        }}>
        v3939 Powered By IQVIA
      </span>
    </Footer>
  );
};
