import React from 'react';
import Autocomplete from 'apollo-react/components/Autocomplete';
import Chip from 'apollo-react/components/Chip';
import Box from 'apollo-react/components/Box';
import Checkbox from 'apollo-react/components/Checkbox';
import IconButton from 'apollo-react/components/IconButton';
import { ArrowDown } from 'apollo-react-icons';

const isOptionEqualToValue = (option, value) =>
  option.name === value.name && option.label === value.label;

const filterOptions = (options, filterConfig) =>
  options.filter(
    (option) =>
      option.name.toLowerCase().includes(filterConfig.inputValue.toLowerCase()) ||
      option.label.toLowerCase().includes(filterConfig.inputValue.toLowerCase())
  );

const renderTags = (tags, getTagProps, isChipRequireNameAndLabel) => (
  <>
    {tags.slice(0, 3)?.map((tag, index) => (
      <Chip
        {...getTagProps({ index })}
        label={isChipRequireNameAndLabel ? `${tag.name}.${tag.label}` : tag.label}
        key={isChipRequireNameAndLabel ? `${tag.name}.${tag.label}` : tag.label}
        color="white"
      />
    ))}
    {tags?.length > 3 ? <>&nbsp;+{tags?.length - 3}</> : null}
  </>
);

const renderGroup = (
  params,
  collapsedGroups,
  setCollapsedGroups,
  selectedValues,
  setSelectedValues,
  canGroupHeaderBeSelected,
  isCountRequired
) => {
  const isGroupSelected = params.children.every((child) =>
    selectedValues.some((selected) => selected.label === child.props.option.label)
  );

  const isGroupPartiallySelected =
    params.children.some((child) =>
      selectedValues.some((selected) => selected.label === child.props.option.label)
    ) && !isGroupSelected;

  const handleGroupSelection = () => {
    const newSelection = isGroupSelected
      ? selectedValues.filter(
          (selected) =>
            !params.children.some((child) => child.props.option.label === selected.label)
        )
      : [...selectedValues, ...params.children.map((child) => child.props.option)];

    setSelectedValues(newSelection);
  };

  const selectedCount = params.children.filter((child) => child.props.selected).length;

  return (
    <li key={params.key}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          padding: '0.2rem 0.2rem 0.2rem 0.5rem',
          zIndex: 1,
          color: 'neutral8',
          fontSize: '14px',
          '&:hover': {
            backgroundColor: canGroupHeaderBeSelected && '#ECF3FF'
          },
          cursor: canGroupHeaderBeSelected ? 'pointer' : null
        }}>
        {canGroupHeaderBeSelected ? (
          <Box>
            <Checkbox
              checked={isGroupSelected}
              indeterminate={isGroupPartiallySelected}
              onChange={handleGroupSelection}
            />
            <span style={{ marginLeft: '0px' }}>{params.group}</span>
          </Box>
        ) : (
          <span>{params.group}</span>
        )}
        <span>
          {isCountRequired ? (
            <span
              style={{
                background: '#C2DBFF',
                borderRadius: '10px',
                padding: '0px 8px',
                marginTop: '5px',
                fontSize: '14px'
              }}>
              {selectedCount}/{params.children.length}
            </span>
          ) : null}
          <IconButton
            size="small"
            style={{
              justifyContent: 'centre',
              marginRight: '5px'
            }}
            onClick={() => {
              setCollapsedGroups({
                ...collapsedGroups,
                [params.group]: !collapsedGroups[params.group]
              });
            }}>
            <ArrowDown
              style={{ transform: collapsedGroups[params.group] ? 'rotate(180deg)' : '' }}
            />
          </IconButton>
        </span>
      </Box>
      {!collapsedGroups[params.group] && (
        <Box
          sx={{
            '&>li': { paddingLeft: '2rem !important' },
            paddingLeft: canGroupHeaderBeSelected && '15px'
          }}>
          {params.children}
        </Box>
      )}
    </li>
  );
};

/**
 * AutoComplete component
 * @prop {array} entries // structure: [{ name: string, label: string}]
 * @prop {object} selectedValues
 * @prop {function} handleChange
 * @prop {function} setSelectedValues
 * @prop {boolean} canGroupHeaderBeSelected
 * @prop {boolean} isCountRequired
 * @prop {string} placeholder
 * @prop {boolean} isChipRequireNameAndLabel
 * @returns {React.JSXElement}
 */

export const CustomAutoComplete = ({
  entries,
  selectedValues,
  handleChange,
  setSelectedValues,
  canGroupHeaderBeSelected,
  isCountRequired,
  placeholder,
  isChipRequireNameAndLabel
}) => {
  const [collapsedGroups, setCollapsedGroups] = React.useState({});

  return (
    <Autocomplete
      sx={{ maxWidth: '100%' }}
      source={entries}
      fullWidth
      placeholder={placeholder}
      value={selectedValues}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      multiple
      groupBy={(option) => option?.name}
      size="small"
      noWrap
      TooltipProps={{ placement: 'right' }}
      isOptionEqualToValue={isOptionEqualToValue}
      filterSelectedOptions={false}
      filterOptions={filterOptions}
      TextFieldProps={{ onChange: () => setCollapsedGroups({}) }}
      onClose={() => setCollapsedGroups({})}
      ListboxProps={{ sx: { padding: 0 } }}
      renderTags={(tags, getTagProps) => renderTags(tags, getTagProps, isChipRequireNameAndLabel)}
      renderGroup={(params) =>
        renderGroup(
          params,
          collapsedGroups,
          setCollapsedGroups,
          selectedValues,
          setSelectedValues,
          canGroupHeaderBeSelected,
          isCountRequired
        )
      }
    />
  );
};
