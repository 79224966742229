import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Button from 'apollo-react/components/Button';
import Grid from 'apollo-react/components/Grid';
import Divider from 'apollo-react/components/Divider';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExportType from './ExportType';
import ExportFrequency from './ExportFrequency';
import DomainColumns from './DomainColumns';
import { makeStyles } from '@mui/styles';
import Modal from 'apollo-react/components/Modal';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Radio from 'apollo-react/components/Radio';
import RadioGroup from 'apollo-react/components/RadioGroup';
import useExportSettings from './useExportSettings';
import { setStepperStatus } from 'Redux/Slice/PublishMappingSpecSlice';
import { GetExportPreferencesList } from 'Redux/Service/DataExportService';
import { unwrapResult } from '@reduxjs/toolkit';
import { dateFormatByType } from 'Utils';
import SelectPreference from '../Components/SelectPreference';
import CircularProgress from 'apollo-react/components/CircularProgress';
import md5 from 'md5';
import { getDomainInfoFormatted } from '../DataExportUtils';

const EXPORT_SETTINGS_TYPE = {
  SYSTEM_DEFINED: 'System Defined',
  USER_DEFINED: 'User Defined'
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem'
  },
  content: {
    minWidth: '32%'
  },
  header: {
    fontSize: '14px',
    lineHeight: '21px'
  },
  value: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  btnContent: {
    marginLeft: '16px'
  },
  divider: {
    marginBottom: '16px'
  },
  radioTitle: {
    fontWeight: 600,
    fontSize: '16px',
    marginTop: '5px',
    color: '#444444'
  },
  circularProgress: {
    '&>div': {
      paddingTop: '7px'
    }
  }
});

const PreferenceHeader = ({ destination, location, userName }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.container}>
      <Grid item className={classes.content}>
        <Typography className={classes.header}>Destination</Typography>
        <Typography className={classes.value}>{destination}</Typography>
      </Grid>
      <Grid item className={classes.content}>
        <Typography className={classes.header}>Database</Typography>
        <Typography className={classes.value}>{location}</Typography>
      </Grid>
      <Grid item className={classes.content}>
        <Typography className={classes.header}>Username</Typography>
        <Typography className={classes.value}>{userName}</Typography>
      </Grid>
    </Grid>
  );
};

const ExportRadioLabel = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.radioTitle}>Data Export Preferences:</Typography>
    </>
  );
};

const ExportSettings = (props) => {
  const classes = useStyles();
  const {
    handleBack,
    exportDetails,
    handleReturn,
    setActiveStep,
    setDataExport,
    setIsApplyPreferenceConfig,
    setAppliedExportPreference
  } = props;
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const {
    mappingSpecDetails = {},
    stepperStatus,
    selectedPreferences
  } = useSelector((state) => state.PublishMappingSpecData);
  const [validationResult, setValidationResult] = useState({});

  const [exportSettingsType, setExportSettingsType] = useState(
    exportDetails?.exportPreferences
      ? EXPORT_SETTINGS_TYPE.USER_DEFINED
      : EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED
  );
  const [showSelectPreferenceModal, setShowSelectPreferenceModal] = useState(false);
  const [loadpreferenceList, setLoadPreferenceList] = useState({
    isLoading: false,
    preferenceList: []
  });

  const {
    domainAndColumns,
    setDomainAndColumns,
    domainAndColumnsLoading,

    exportTypeMetaData,
    selectedExportType,
    setSelectedExportType,
    exportTypeTooltipMessage,
    exportTypeMetaDataLoading,

    scheduleFrequencies,
    selectedScheduleExportFrequency,
    setSelectedScheduleExportFrequency,
    scheduleFrequenciesLoading,

    selectedSuppQualPreference,
    setSelectedSuppQualPreference,

    includeSystemFields,
    setIncludeSystemFields,

    handleReset,

    loadSelectedPreference,

    overwriteDataFileName,
    setOverwriteDataFileName
  } = useExportSettings(exportDetails);

  const dispatch = useDispatch();
  const handleModalClose = () => {
    setShowLeavePageModal(false);
    if (stepperStatus?.newStep > -1) {
      dispatch(setStepperStatus({ ...stepperStatus, newStep: -1 }));
    }
  };

  const handleModalConfirm = () => {
    setShowLeavePageModal(false);
    dispatch(setStepperStatus({}));
    if (stepperStatus?.newStep > -1) {
      setActiveStep(stepperStatus.newStep);
    } else {
      handleBack();
    }
  };

  const handleLeavePage = () => {
    if (exportSettingsType === EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED) {
      handleModalConfirm();
    } else {
      setShowLeavePageModal(true);
    }
  };

  useEffect(() => {
    dispatch(
      breadCrumbData([
        {
          isHome: true,
          handleClick: () => {
            handleReturn('DASHBOARD');
          }
        },
        {
          title:
            location.pathname === '/manage-data-pipeline/manage-data-pipeline-version'
              ? 'Manage Data Pipelines'
              : 'Data Products',
          path: '',
          handleClick: () => {
            handleReturn();
          }
        },
        {
          title: 'Publish',
          path: '',
          handleClick: handleLeavePage
        },
        {
          title: 'Data Export',
          path: '',
          handleClick: handleLeavePage
        },
        {
          title: 'Preference'
        }
      ])
    );
  }, [exportSettingsType]);

  const isAnyDomainChecked = () => {
    let flag = false;
    Object.keys(domainAndColumns).forEach(function (key) {
      if (domainAndColumns[key].checked === true) {
        flag = true;
      }
    });
    return flag;
  };

  const validateDomainNames = () => {
    const updatedDomainMap = new Map();
    const originalDomainSet = new Set();
    const domainsWithError = {};
    Object.keys(domainAndColumns).forEach(function (key) {
      if (domainAndColumns[key].checked === true) {
        const domainName = domainAndColumns[key].domainCode;
        const updatedDomainName = domainAndColumns[key].dataFileName?.toUpperCase();
        if (updatedDomainName) {
          updatedDomainMap.has(updatedDomainName)
            ? updatedDomainMap.set(updatedDomainName, [
                ...updatedDomainMap.get(updatedDomainName),
                domainName
              ])
            : updatedDomainMap.set(updatedDomainName, [domainName]);
        } else {
          originalDomainSet.add(domainName?.toUpperCase());
        }
      }
    });
    updatedDomainMap.forEach((domainNames, updatedDomainName) => {
      if (domainNames?.length > 1 || originalDomainSet.has(updatedDomainName)) {
        domainNames.forEach((_domainName) => (domainsWithError[_domainName] = updatedDomainName));
      }
    });
    return domainsWithError;
  };

  const validatedColumnNames = () => {
    const domainWithError = {};

    Object.keys(domainAndColumns).forEach(function (domainCode) {
      if (domainAndColumns[domainCode].checked === true) {
        const columnWithError = {};
        const updatedColumnMap = new Map();
        const originalColumnSet = new Set();
        const columns = domainAndColumns[domainCode].exportVariables;

        columns.forEach((column) => {
          if (column.checked) {
            const originalName = column.originalColumnName?.toUpperCase();
            const updatedName = column.updatedColumnName?.toUpperCase();
            if (updatedName) {
              if (updatedColumnMap.has(updatedName)) {
                columnWithError[updatedName] = true;
              }
              updatedColumnMap.set(updatedName, originalName);
            } else if (originalName) {
              originalColumnSet.add(originalName);
            }
          }
        });
        updatedColumnMap.forEach((originalName, updatedName) => {
          if (originalColumnSet.has(updatedName)) {
            columnWithError[updatedName] = true;
          }
        });
        if (Object.keys(columnWithError).length > 0) {
          domainWithError[domainCode] = columnWithError;
        }
      }
    });
    return domainWithError;
  };

  const checkValidation = () => {
    let validationResult = {
      atleastOneDomainSelected: true,
      errorFields: {},
      isValid: true
    };
    const isDomainSelected = isAnyDomainChecked();
    const inValidDataFileNames = validateDomainNames();
    const invalidColumns = validatedColumnNames();
    if (!isDomainSelected) {
      validationResult.atleastOneDomainSelected = false;
      validationResult.isValid = false;
    }
    if (Object.keys(inValidDataFileNames).length) {
      validationResult.isValid = false;
      Object.keys(inValidDataFileNames).forEach((domain) => {
        validationResult.errorFields[domain] = {
          dataFileNameError: inValidDataFileNames[domain]
        };
      });
    }
    if (Object.keys(invalidColumns).length) {
      validationResult.isValid = false;
      Object.keys(invalidColumns).forEach((domain) => {
        validationResult.errorFields[domain] = validationResult.errorFields[domain]
          ? { ...validationResult.errorFields[domain], columnNameError: invalidColumns[domain] }
          : { columnNameError: invalidColumns[domain] };
      });
    }
    setValidationResult(validationResult);
    return validationResult;
  };

  const isPreferenceChanged = () => {
    let isChanged = false;
    const lastSelectedPreference = selectedPreferences?.[exportDetails.destination] || {};
    if (lastSelectedPreference) {
      // Transfrom Selected Preference Data
      let domainInformationSel = getDomainInfoFormatted(
        Object.values(lastSelectedPreference.domainInformation || {})
      );
      const selectedPreferenceFormated = {
        exportType: lastSelectedPreference.exportType,
        scheduleType: lastSelectedPreference.scheduleType,
        suppQualPreferenceKey: lastSelectedPreference.suppQualPreferenceKey,
        domainInformation: domainInformationSel
      };

      //Transorm Local Preference Data
      let domainInformation = getDomainInfoFormatted(Object.values(domainAndColumns || {}));
      const localPreferenceFormated = {
        exportType: selectedExportType,
        scheduleType: selectedScheduleExportFrequency,
        suppQualPreferenceKey: selectedSuppQualPreference,
        domainInformation
      };

      isChanged =
        md5(JSON.stringify(selectedPreferenceFormated)) !==
        md5(JSON.stringify(localPreferenceFormated));
    }
    return isChanged;
  };

  const handleSave = () => {
    const finalValidationResult = checkValidation();
    if (finalValidationResult.isValid) {
      setDataExport((_dataExport) => {
        const modifiedDataExportData = JSON.parse(JSON.stringify(_dataExport));
        for (let row of modifiedDataExportData) {
          if (row?.destination === exportDetails?.destination) {
            row.exportPreferences = {
              suppQualPreferenceKey: selectedSuppQualPreference,
              domainAndColumns,
              exportType: selectedExportType,
              scheduleType: selectedScheduleExportFrequency
            };
          }
        }
        return modifiedDataExportData;
      });
      if (isPreferenceChanged()) {
        setAppliedExportPreference({
          destination: exportDetails.destination,
          exportType: selectedExportType,
          scheduleType: selectedScheduleExportFrequency,
          suppQualPreferenceKey: selectedSuppQualPreference,
          domainAndColumns: JSON.parse(JSON.stringify(domainAndColumns))
        });
        setIsApplyPreferenceConfig(true);
      } else {
        dispatch(
          showBanner({
            variant: 'info',
            message: `Preferences for ${exportDetails.destination} has been applied.`
          })
        );
      }

      dispatch(setStepperStatus({}));
      handleBack();
    }
  };

  const handleResetToSystemDefined = () => {
    setExportSettingsType(EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED);
    handleReset();
    setDataExport((_dataExport) => {
      const modifiedDataExportData = JSON.parse(JSON.stringify(_dataExport));
      for (let row of modifiedDataExportData) {
        if (row?.destination === exportDetails?.destination) {
          row.exportPreferences = undefined;
        }
      }
      return modifiedDataExportData;
    });
    setValidationResult({});

    dispatch(
      showBanner({
        variant: 'info',
        message: `Preferences for ${exportDetails.destination} has been reset to System Defined.`
      })
    );
    dispatch(setStepperStatus({ ...stepperStatus, isCurrentPageEdit: false }));
    setShowWarningModal(false);
  };

  const handleChange = (e) => {
    if (e.target.value === EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED) {
      setShowWarningModal(true);
    } else {
      setExportSettingsType(e.target.value);
      dispatch(setStepperStatus({ ...stepperStatus, isCurrentPageEdit: true }));
    }
  };

  const handleLoadPreference = async () => {
    setLoadPreferenceList({ isLoading: true, preferenceList: [] });
    let _preferenceList = [];
    const resp = await dispatch(GetExportPreferencesList(mappingSpecDetails?.productID)).then(
      unwrapResult
    );
    if (resp?.data?.success) {
      _preferenceList = resp.data.exportPreferences?.map((item) => {
        return {
          ...item,
          iqAuditDate: dateFormatByType(item.iqAuditDate, 'Table'),
          selected: false
        };
      });
      setLoadPreferenceList({
        isLoading: false,
        preferenceList: _preferenceList?.length > 0 ? _preferenceList : []
      });
      if (_preferenceList?.length > 0) {
        setShowSelectPreferenceModal(true);
      } else {
        dispatch(showBanner({ variant: 'info', message: 'No Saved Preferences available.' }));
      }
    } else {
      setLoadPreferenceList({
        isLoading: false,
        preferenceList: []
      });
      dispatch(showBanner({ variant: 'error', message: resp?.data?.message }));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <PreferenceHeader {...exportDetails} />
        </Grid>
        <Grid item xs={4} className={classes.btnContainer}>
          <Grid>
            <Button
              variant="secondary"
              size="small"
              className={classes.btnContent}
              onClick={handleLeavePage}>
              Close
            </Button>
            <Button
              variant="primary"
              size="small"
              className={classes.btnContent}
              disabled={exportSettingsType === EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED}
              onClick={handleSave}>
              Apply Preference
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider type="dark" className={classes.divider} />
      <Grid
        container
        spacing={0}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '100px'
        }}>
        <Grid item>
          <RadioGroup
            data-testid="radio-btn"
            label={<ExportRadioLabel />}
            aria-label="label"
            name="Data Export Parameters :"
            value={exportSettingsType}
            style={{
              padding: '15px 0 0 10px'
            }}
            onChange={handleChange}
            row
            disabled={
              domainAndColumnsLoading || exportTypeMetaDataLoading || scheduleFrequenciesLoading
            }>
            <Radio
              value={EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED}
              label={EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED}
            />
            <Radio
              value={EXPORT_SETTINGS_TYPE.USER_DEFINED}
              label={EXPORT_SETTINGS_TYPE.USER_DEFINED}
            />
          </RadioGroup>
        </Grid>
        {exportSettingsType !== EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED ? (
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="secondary"
              size="small"
              style={{ marginLeft: '16px' }}
              onClick={handleLoadPreference}
              className={classes.circularProgress}
              disabled={loadpreferenceList.isLoading}
              icon={
                loadpreferenceList.isLoading && (
                  <CircularProgress variant="indeterminate" size={16} color="inherit" />
                )
              }>
              Load Preferences
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <DomainColumns
          readOnly={exportSettingsType === EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED}
          domainAndColumns={domainAndColumns}
          setDomainAndColumns={setDomainAndColumns}
          domainAndColumnsLoading={domainAndColumnsLoading}
          includeSystemFields={includeSystemFields}
          setIncludeSystemFields={setIncludeSystemFields}
          overwriteDataFileName={overwriteDataFileName}
          setOverwriteDataFileName={setOverwriteDataFileName}
          selectedSuppQualPreference={selectedSuppQualPreference}
          setSelectedSuppQualPreference={setSelectedSuppQualPreference}
          validationResult={validationResult}
          exportSettingsType={exportSettingsType}
        />
        <ExportType
          readOnly={exportSettingsType === EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED}
          exportTypeMetaData={exportTypeMetaData}
          selectedExportType={selectedExportType}
          setSelectedExportType={setSelectedExportType}
          exportTypeTooltipMessage={exportTypeTooltipMessage}
          exportTypeMetaDataLoading={exportTypeMetaDataLoading}
        />
        <ExportFrequency
          readOnly={exportSettingsType === EXPORT_SETTINGS_TYPE.SYSTEM_DEFINED}
          scheduleFrequencies={scheduleFrequencies}
          selectedScheduleExportFrequency={selectedScheduleExportFrequency}
          setSelectedScheduleExportFrequency={setSelectedScheduleExportFrequency}
          scheduleFrequenciesLoading={scheduleFrequenciesLoading}
        />
      </Grid>
      <Modal
        open={stepperStatus?.newStep > -1 || showLeavePageModal}
        variant="warning"
        onClose={handleModalClose}
        title="Are you sure you want to leave this page?"
        message="Your changes will not be saved, Would you still like to leave?"
        buttonProps={[
          {
            label: 'Cancel',
            onClick: handleModalClose
          },
          {
            label: 'Leave',
            onClick: handleModalConfirm
          }
        ]}
        id="warning"
      />
      <Modal
        open={showWarningModal}
        variant="warning"
        onClose={() => setShowWarningModal(false)}
        title="Switching Data Export Preferences?"
        message="Are you sure you want to switch to system defined preference? All your current user defined changes will be lost."
        buttonProps={[
          {
            label: 'Cancel',
            onClick: () => setShowWarningModal(false)
          },
          {
            label: 'Proceed',
            onClick: handleResetToSystemDefined
          }
        ]}
        id="system-defined-warning"
      />
      {showSelectPreferenceModal && (
        <SelectPreference
          preferences={loadpreferenceList.preferenceList}
          onSelect={(selectedPreference) => {
            setShowSelectPreferenceModal(false);
            dispatch(
              showBanner({
                variant: 'info',
                message: `Export Preference from ${selectedPreference?.preferenceName} has been imported. Please save to apply.`
              })
            );
            loadSelectedPreference(selectedPreference.preferenceId);
            setDataExport((_dataExport) => {
              const modifiedDataExportData = JSON.parse(JSON.stringify(_dataExport));
              for (let row of modifiedDataExportData) {
                if (row?.destination === exportDetails?.destination) {
                  row.exportPreferences = undefined;
                }
              }
              return modifiedDataExportData;
            });
          }}
          onCancel={() => setShowSelectPreferenceModal(false)}
        />
      )}
    </>
  );
};

export default ExportSettings;
